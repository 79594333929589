import { ErrorAnnounce } from '@e-seikatsu/design-system';
import { Container } from '@mui/material';
import { FC } from 'react';

import { networkOtherErrorMessageHeader, networkOtherErrorMessageText } from '@/Consts/ErrorMessages';

export const GeneralServerErrorPage: FC = () => (
  <Container
    sx={{
      paddingTop: '10vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '16px',
    }}
  >
    <ErrorAnnounce title={networkOtherErrorMessageHeader} comment={networkOtherErrorMessageText} />
  </Container>
);
