import { FC } from 'react';
import { Navigate, useParams, generatePath } from 'react-router';

import { RouteDefinitions } from '@/Pages/RouteDefinitions';

/**
 * *エリア/沿線検索の詳細画面のルーティング変更により一時的に旧URLでのアクセスを新URLへリダイレクトしている
 * @see https://stackoverflow.com/questions/68645594/is-it-possible-to-pass-parameters-from-react-route-tag-to-navigate-tag
 *
 */
export const OldDetailPathRedirect: FC = () => {
  const { guid } = useParams();
  return <Navigate replace to={generatePath(RouteDefinitions.chintaiBukkenDetail.path, { guid: guid })} />;
};
