import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-use';

import { NewsData } from '../../../../models/src/News/NewsData';

import { useDependency } from '@/Hooks/DependencyHook';
import { ReadDetail } from '@/Services/IReadEntityManager';

type AnnouncementHook = {
  isLoading: boolean;
  isUnreadNewsExists: boolean;
  newsData: NewsData[] | undefined;
  markAsRead: () => void;
};
export const useAnnouncement = (): AnnouncementHook => {
  const staticContentsService = useDependency('staticContentsService');
  const readEntityManager = useDependency('readEntityManager');
  const [readNewsMap, setReadNewsMap] = useState<ReadonlyMap<string, ReadDetail>>();

  const { value: newsData, loading: newsDataLoading } = useAsync(() => staticContentsService.getNews());

  useEffect(
    () => readEntityManager.subscribeReadEntityChanged('Announcement', current => setReadNewsMap(current)),
    [readEntityManager]
  );
  const isUnreadNewsExists = useMemo(() => {
    if (readNewsMap === undefined || newsData === undefined) {
      return false;
    }
    return newsData.some(nd => !readNewsMap.has(nd.id));
  }, [newsData, readNewsMap]);

  const markAsRead = useCallback(() => {
    if (readNewsMap === undefined || newsData === undefined) {
      return;
    }
    newsData.forEach(nd => {
      if (!readNewsMap.has(nd.id)) {
        readEntityManager.markAsRead('Announcement', nd.id);
      }
    });
  }, [newsData, readEntityManager, readNewsMap]);

  return {
    isLoading: newsDataLoading,
    isUnreadNewsExists: isUnreadNewsExists,
    newsData,
    markAsRead,
  };
};
