import { getViewportHeight } from '@app/utils';
import { SerializedStyles, css } from '@emotion/react';
import { ErrorMessage, Loading } from '@lib/components';
import { Box, DialogContent, TablePagination, Typography } from '@mui/material';
import { SelectionSearch } from 'mdi-material-ui';
import { ChangeEvent, FC, memo, useCallback, useMemo, useState } from 'react';

import { SearchOrganizationQuery } from './AddPartnerOrganizationDialog';

import { PartnerOrganizationItem } from '@/Components/Partner/PartnerOrganizationItem';
import { OrganizationSearchErrorMessage } from '@/Consts/ErrorMessages';
import { searchResultExceedThreshold, searchResultHeaderZIndex } from '@/Consts/Values';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { PartnerApplicantsQuery, usePartnerApplicants } from '@/Hooks/Esa/usePartnerApplicants';
import { usePartners } from '@/Hooks/Esa/usePartners';
import {
  PUBLIC_DEFAULT_PER_PAGE,
  PUBLIC_DEFAULT_START_INDEX,
  useEsaPublicDomain,
  useEsaPublicOrganization,
} from '@/Hooks/Esa/usePublic';
import { EsaPublicDomain } from '@/Models/Esa/EsaDomain';
import { EsaPublicOrganization } from '@/Models/Esa/EsaOrganization';
import { Partner } from '@/Models/Esa/Partner';
import { PartnerApplicants } from '@/Models/Esa/PartnerApplicants';
import { searchResultSxProps } from '@/Pages/bukken/chintai/SearchPartial/SearchResultStyles';
import { theme } from '@/Theme';
import { attachInfoToPublicOrganizationList, getPartnerStatus } from '@/Utils/PartnerUtils';

type AddPartnerOrganizationResultContainerProps = {
  searchOrganizationQuery: SearchOrganizationQuery;
};

const AddPartnerOrganizationResultContainer: FC<AddPartnerOrganizationResultContainerProps> = memo(
  ({ searchOrganizationQuery }) => {
    // 組織検索
    const [startIndex, setStartIndex] = useState(PUBLIC_DEFAULT_START_INDEX);
    const searchOrganizationParams = useMemo(() => {
      const SearchOrganizationParamsTemp = new URLSearchParams();
      SearchOrganizationParamsTemp.append('startIndex', `${startIndex}`);
      SearchOrganizationParamsTemp.append('excludeDomainUids', searchOrganizationQuery.excludeDomainUids);
      SearchOrganizationParamsTemp.append('expandPartnerCount', `${searchOrganizationQuery.expandPartnerCount}`);
      if (searchOrganizationQuery.businessName) {
        SearchOrganizationParamsTemp.append('businessName', searchOrganizationQuery.businessName);
      }
      if (searchOrganizationQuery.denwaNumber) {
        SearchOrganizationParamsTemp.append('denwaNumber', searchOrganizationQuery.denwaNumber);
      }
      if (searchOrganizationQuery.area.pref) {
        SearchOrganizationParamsTemp.append(
          'jushoText',
          searchOrganizationQuery.area.city
            ? searchOrganizationQuery.area.pref.name + searchOrganizationQuery.area.city.city_name
            : searchOrganizationQuery.area.pref.name
        );
      }
      return SearchOrganizationParamsTemp;
    }, [
      searchOrganizationQuery.area.city,
      searchOrganizationQuery.area.pref,
      searchOrganizationQuery.businessName,
      searchOrganizationQuery.denwaNumber,
      searchOrganizationQuery.excludeDomainUids,
      searchOrganizationQuery.expandPartnerCount,
      startIndex,
    ]);

    const {
      model: publicOrganizationModel,
      error: publicOrganizationError,
      isLoading: isOrganizationLoading,
      isValidating: isOrganizationValidating,
    } = useEsaPublicOrganization(searchOrganizationParams);
    const publicOrganizationList = publicOrganizationModel?.items ?? [];
    const publicOrganizationUidList = publicOrganizationList.map((organization: EsaPublicOrganization) => {
      return organization.organizationUid;
    });
    const totalCount = publicOrganizationModel?.totalCounts ?? 0;

    // 法人検索
    const getDomainParams =
      isOrganizationLoading || publicOrganizationList.length === 0
        ? new URLSearchParams()
        : new URLSearchParams({
            domainUids: publicOrganizationList
              .map((publicOrganization: EsaPublicOrganization) => {
                return publicOrganization.domainUid;
              })
              .join(','),
          });
    const { model: publicDomainModel, isLoading: isDomainLoading } = useEsaPublicDomain(getDomainParams);
    const publicDomainList = publicDomainModel?.items ?? [];

    const user = useCurrentUser();

    // 取引先検索
    const searchPartnerQuery = {
      startIndex: PUBLIC_DEFAULT_START_INDEX,
      itemsPerPage: PUBLIC_DEFAULT_PER_PAGE,
      organizationUids: user.defaultOrganizationUid ?? '',
      partnerOrganizationUids: publicOrganizationUidList.join(','),
    };
    const { model: partnerModel, isLoading: isPartnerLoading } = usePartners(searchPartnerQuery);
    const partnerOrganizationList = partnerModel?.items ?? [];
    const partnerOrganizationUidList = partnerOrganizationList.map((organization: Partner) => {
      return organization.partnerOrganizationUid;
    });

    // 送信した申請の取得
    const searchPartnerApplicantsSentQuery: PartnerApplicantsQuery = {
      startIndex: 1,
      itemsPerPage: 100,
      type: 'sent',
      organizationUids: user.defaultOrganizationUid ?? '',
      partnerOrganizationUids: publicOrganizationUidList.join(','),
      expandDomain: true,
      expandOrganization: true,
    };
    const { model: partnerApplicantsSentModel, isLoading: isPartnerApplicantsSentLoading } = usePartnerApplicants(
      searchPartnerApplicantsSentQuery
    );
    const partnerApplicantsSentList = partnerApplicantsSentModel?.items ?? [];
    const partnerApplicantsSentStandbyList = partnerApplicantsSentList.filter((applicant: PartnerApplicants) => {
      return applicant.state === 'standby';
    });
    const partnerApplicantsSentStandbyUidList = partnerApplicantsSentStandbyList.map((applicant: PartnerApplicants) => {
      return applicant.partnerOrganizationUid;
    });

    // 受信した申請の取得
    const searchPartnerApplicantsReceivedQuery: PartnerApplicantsQuery = {
      startIndex: 1,
      itemsPerPage: 100,
      type: 'received',
      organizationUids: user.defaultOrganizationUid ?? '',
      partnerOrganizationUids: publicOrganizationUidList.join(','),
      expandDomain: true,
      expandOrganization: true,
    };
    const { model: partnerApplicantsReceivedModel, isLoading: isPartnerApplicantsReceivedLoading } =
      usePartnerApplicants(searchPartnerApplicantsReceivedQuery);
    const partnerApplicantsReceivedList = partnerApplicantsReceivedModel?.items ?? [];
    const partnerApplicantsReceivedStandbyList = partnerApplicantsReceivedList.filter(
      (applicant: PartnerApplicants) => {
        return applicant.state === 'standby';
      }
    );
    const partnerApplicantsReceivedStandbyUidList = partnerApplicantsReceivedStandbyList.map(
      (applicant: PartnerApplicants) => {
        return applicant.organizationUid;
      }
    );

    const publicOrganizationDomainList = attachInfoToPublicOrganizationList(
      publicOrganizationList,
      publicDomainList,
      partnerApplicantsSentStandbyList,
      partnerApplicantsReceivedStandbyList
    );

    const handleChangePage = useCallback((_event: ChangeEvent<unknown> | null, newPage: number) => {
      if (newPage === null) {
        return;
      }
      const targetStartIndex = newPage * PUBLIC_DEFAULT_PER_PAGE + 1;
      setStartIndex(targetStartIndex);
    }, []);
    const paginationStartIndex = useMemo(() => {
      return Math.floor(startIndex / PUBLIC_DEFAULT_PER_PAGE + 1);
    }, [startIndex]);

    if (
      isDomainLoading ||
      isOrganizationLoading ||
      isOrganizationValidating ||
      isPartnerLoading ||
      isPartnerApplicantsSentLoading ||
      isPartnerApplicantsReceivedLoading
    ) {
      return (
        <Box height={getViewportHeight(100)} width="100%" overflow="hidden">
          <Loading />
        </Box>
      );
    }

    if (publicOrganizationError) {
      return (
        <Box py={2} bgcolor="background.paper" display="flex" alignItems="center" justifyContent="center">
          <ErrorMessage
            header={OrganizationSearchErrorMessage.default.messageHeader}
            supportMessage={OrganizationSearchErrorMessage.default.messageText}
            small
          />
        </Box>
      );
    }

    if (totalCount > searchResultExceedThreshold) {
      return (
        <Box py={2} bgcolor="background.paper" display="flex" alignItems="center" justifyContent="center">
          <ErrorMessage
            header={OrganizationSearchErrorMessage.tooManySearchResults.messageHeader}
            supportMessage={OrganizationSearchErrorMessage.tooManySearchResults.messageText}
            small
          />
        </Box>
      );
    }

    if (publicOrganizationList.length === 0) {
      return (
        <Box sx={searchResultSxProps.emptyStateBox} css={dialogContentStyle}>
          <SelectionSearch sx={searchResultSxProps.emptyStateIcon} />
          <Typography>検索結果がありません。条件を変更して再度お試しください</Typography>
        </Box>
      );
    }

    return (
      <AddPartnerOrganizationResultPresenter
        publicOrganizationDomainList={publicOrganizationDomainList}
        partnerOrganizationUidList={partnerOrganizationUidList}
        partnerApplicantsSentStandbyUidList={partnerApplicantsSentStandbyUidList}
        partnerApplicantsReceivedStandbyUidList={partnerApplicantsReceivedStandbyUidList}
        paginationStartIndex={paginationStartIndex}
        totalCount={totalCount}
        handleChangePage={handleChangePage}
      />
    );
  }
);

export { AddPartnerOrganizationResultContainer as AddPartnerOrganizationResult };

type AddPartnerOrganizationResultPresenterProps = {
  publicOrganizationDomainList: (EsaPublicOrganization & {
    domain: EsaPublicDomain;
    sentApplicants: PartnerApplicants;
    receivedApplicants: PartnerApplicants;
  })[];
  partnerOrganizationUidList: string[];
  partnerApplicantsSentStandbyUidList: string[];
  partnerApplicantsReceivedStandbyUidList: string[];
  paginationStartIndex: number;
  totalCount: number;
  handleChangePage: (_event: ChangeEvent<unknown> | null, newPage: number) => void;
};

const dialogContentStyle = css({
  background: theme.palette.background.default,
  paddingLeft: '4px',
  paddingRight: '4px',
});

// NOTE: MUI のコンポーネントの都合上、CSS の記述が冗長になっている
const paginationStyle = (): SerializedStyles =>
  css({
    '& .MuiTablePagination-toolbar': {
      flexDirection: 'row-reverse',
      position: 'relative',
      padding: '0px',
    },
    '& .MuiTablePagination-select': {
      display: 'none',
    },
    '& .MuiTablePagination-selectIcon': {
      display: 'none',
    },
    '& .MuiTablePagination-displayedRows': {
      position: 'absolute',
      left: 8,
    },
    '& .MuiTablePagination-actions': {
      position: 'absolute',
      right: 0,
    },
  });

export const AddPartnerOrganizationResultPresenter: FC<AddPartnerOrganizationResultPresenterProps> = memo(
  ({
    publicOrganizationDomainList,
    partnerOrganizationUidList,
    partnerApplicantsSentStandbyUidList,
    partnerApplicantsReceivedStandbyUidList,
    paginationStartIndex,
    totalCount,
    handleChangePage,
  }) => (
    <DialogContent css={dialogContentStyle}>
      <Box mb={2} mx={2}>
        組織が見つかりました。
      </Box>
      <Box zIndex={searchResultHeaderZIndex} position="sticky" bgcolor="grey.100" top={-20} px={1}>
        <TablePagination
          component="div"
          css={paginationStyle}
          page={paginationStartIndex - 1}
          count={totalCount}
          rowsPerPage={PUBLIC_DEFAULT_PER_PAGE}
          rowsPerPageOptions={undefined}
          labelRowsPerPage=""
          labelDisplayedRows={({ from, to, count }) => {
            return (
              <Box component="span" display="flex" flexDirection="row" alignItems="center" mr={1.5}>
                <Box component="span" fontWeight="bold" fontSize="18px" mr={0.5}>
                  {`${from}-${to}件`}
                </Box>
                <Box component="span">{`/ 全${count}件`}</Box>
              </Box>
            );
          }}
          onPageChange={handleChangePage}
        />
      </Box>
      {publicOrganizationDomainList.map(organization => {
        return (
          <PartnerOrganizationItem
            key={organization.organizationUid}
            domain={organization.domain}
            organization={organization}
            sentApplicants={organization.sentApplicants}
            receivedApplicants={organization.receivedApplicants}
            partnerStatus={getPartnerStatus(
              organization.organizationUid,
              partnerOrganizationUidList,
              partnerApplicantsSentStandbyUidList,
              partnerApplicantsReceivedStandbyUidList
            )}
          />
        );
      })}
    </DialogContent>
  )
);
