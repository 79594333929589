export const makeTakkenLicenseNumber = (
  licensor: string,
  updateCount: string | number,
  licenseNumber: string | number
): string => {
  let updateCountStr = '';
  if (typeof updateCount === 'number') {
    updateCountStr = updateCount.toFixed(0);
  } else {
    updateCountStr = updateCount;
  }

  let licenseNumberStr = '';
  if (typeof licenseNumber === 'number') {
    licenseNumberStr = licenseNumber.toFixed(0);
  } else {
    licenseNumberStr = licenseNumber;
  }

  return `${licensor}（${updateCountStr}）第${licenseNumberStr}号`;
};
