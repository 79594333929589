import { css } from '@emotion/react';
import { Loading, useIsExtraSmallDevice } from '@lib/components';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Close } from 'mdi-material-ui';
import { ChangeEvent, FC, Fragment, memo, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { PartnerApplicantsReceivedMessage } from './PartnerApplicantsReceivedMessage';

import { DisplayItem, applicantsItemStyle } from '@/Components/Forms/DisplayItem';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import {
  useApprovePartnerApplicants,
  usePartnerApplicant,
  useRejectPartnerApplicants,
} from '@/Hooks/Esa/usePartnerApplicants';
import { PartnerApplicants, PartnerApplicantsStatusCollection } from '@/Models/Esa/PartnerApplicants';
import { RouteDefinitions } from '@/Pages/RouteDefinitions';
import { theme } from '@/Theme';
import { makeTakkenLicenseNumber } from '@/Utils/DisplayText/Takken';
import { getKameiDantai } from '@/Utils/EsaUtils';
import { getPartnerApplicantDomainOrganizationName } from '@/Utils/PartnerApplicantsUtils';
import { getDenwaNumber, getJusho } from '@/Utils/PartnerUtils';

type PartnerApplicantsReceivedDialogContainerProps = {
  isOpenViaDialog?: boolean;
  closeViaDialog?: () => void;
  viaPartnerApplicant?: PartnerApplicants;
};

export const JudgeStatusCollection = {
  approval: 'approval',
  rejection: 'rejection',
} as const;

type JudgeStatusCollectionType = typeof JudgeStatusCollection[keyof typeof JudgeStatusCollection];

const initialRejectionComment =
  'このたびは、取引先申請を送付いただき、誠にありがとうございました。\n厳正なる審査の結果、誠に残念ではございますが今回は承認を見送らせて頂くこととなりました。\nご期待に沿えず大変恐縮ではございますが、ご了承くださいますようお願い申し上げます。';
const MAX_REJECTION_COMMENT = 300;
const PartnerApplicantsReceivedDialogContainer: FC<PartnerApplicantsReceivedDialogContainerProps> = memo(
  ({ isOpenViaDialog, closeViaDialog, viaPartnerApplicant }) => {
    const isExtraSmallDevice = useIsExtraSmallDevice();
    const user = useCurrentUser();
    const isAdminUser = useMemo(() => {
      return user.role.isAdmin;
    }, [user.role]);

    const [judgeStatus, setJudgeStatus] = useState<JudgeStatusCollectionType>(JudgeStatusCollection.approval);
    const handleChangeJudgeStatus = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setJudgeStatus(e.target.value as JudgeStatusCollectionType);
      setRejectionComment(initialRejectionComment);
    }, []);
    const [rejectionComment, setRejectionComment] = useState(initialRejectionComment);
    const isExceedsCharacterLimit = rejectionComment.length > MAX_REJECTION_COMMENT;
    const handleChangeRejectionComment = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setRejectionComment(e.target.value);
    }, []);

    const { approvePartnerApplicants } = useApprovePartnerApplicants();
    const { rejectPartnerApplicants } = useRejectPartnerApplicants();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { partnerApplicantUid } = useParams();
    const { model: directPartnerApplicant, isLoading: isPartnerApplicantLoading } = usePartnerApplicant(
      partnerApplicantUid ?? ''
    );

    const navigate = useNavigate();
    const [isOpenDirectDialog, setIsOpenDirectDialog] = useState(true);
    const closeDirectDialog = useCallback(() => {
      setIsOpenDirectDialog(false);
      navigate(`${RouteDefinitions.partnerApplicantsReceived.path}?states=${directPartnerApplicant?.state}`);
    }, [directPartnerApplicant?.state, navigate]);

    // NOTE: 組織検索経由の場合、ダイアログを閉じたときに元の検索結果に戻れないので、 via の方を使用し、それ以外は direct の方を使用している
    const isOpenDialog = isOpenViaDialog ? isOpenViaDialog : isOpenDirectDialog;
    const closeDialog = closeViaDialog ? closeViaDialog : closeDirectDialog;
    const partnerApplicant = viaPartnerApplicant ? viaPartnerApplicant : directPartnerApplicant;

    const judgePartnerApplicantsStatus = (judgeStatus: JudgeStatusCollectionType) => {
      switch (judgeStatus) {
        case JudgeStatusCollection.approval:
          return approvePartnerApplicants(partnerApplicant?.partnerApplicantUid ?? '', { approvalComment: '' });
        case JudgeStatusCollection.rejection:
          return rejectPartnerApplicants(partnerApplicant?.partnerApplicantUid ?? '', {
            rejectionComment: rejectionComment,
          });
      }
    };

    const judgePartnerApplicantsMutate = (judgeStatus: JudgeStatusCollectionType) => {
      switch (judgeStatus) {
        case JudgeStatusCollection.approval:
          // GraphQL を考慮すると、key に string 以外の型も入るので型チェックを行う
          return mutate(key => typeof key === 'string' && key.startsWith('/partner'));
        case JudgeStatusCollection.rejection:
          return mutate(key => typeof key === 'string' && key.startsWith('/partner_applicants'));
      }
    };

    const sendPartnerApplicantsResult = async () => {
      setIsLoading(true);
      await judgePartnerApplicantsStatus(judgeStatus);
      setIsLoading(false);
      closeDialog();
      await judgePartnerApplicantsMutate(judgeStatus);
    };

    const applicantsSource: { label: string; value?: string }[] = useMemo(() => {
      if (!partnerApplicant) {
        return [];
      }
      return [
        ...getPartnerApplicantDomainOrganizationName(partnerApplicant.domain, partnerApplicant.organization),
        {
          label: '住所',
          value: getJusho(partnerApplicant.organization.jusho),
        },
        {
          label: '電話',
          value: getDenwaNumber(partnerApplicant.organization.denwaList),
        },
        {
          label: '免許番号',
          value: makeTakkenLicenseNumber(
            partnerApplicant.domain.takkenLicense?.licensor ?? '',
            partnerApplicant.domain.takkenLicense?.updateCount ?? '',
            partnerApplicant.domain.takkenLicense?.number ?? ''
          ),
        },
        {
          label: '加盟団体',
          value: getKameiDantai(partnerApplicant.organization.kameiDantaiList),
        },
        {
          label: '申請送付者',
          value: partnerApplicant.userName,
        },
        {
          label: '申請コメント',
          value: partnerApplicant.comment,
        },
      ];
    }, [partnerApplicant]);

    const organizationSource: { label: string; value?: string }[] = useMemo(() => {
      if (!partnerApplicant) {
        return [];
      }
      return [
        {
          label: '会社名',
          value: partnerApplicant.partnerDomain.name,
        },
        {
          label: '組織名（店舗名）',
          value: partnerApplicant.partnerOrganization.name,
        },
      ];
    }, [partnerApplicant]);

    if (isPartnerApplicantLoading) {
      return <Loading />;
    }

    return (
      <PartnerApplicantsReceivedDialogPresenter
        isExtraSmallDevice={isExtraSmallDevice}
        isOpenDialog={isOpenDialog}
        closeDialog={closeDialog}
        partnerApplicant={partnerApplicant}
        applicantsSource={applicantsSource}
        organizationSource={organizationSource}
        isAdminUser={isAdminUser}
        judgeStatus={judgeStatus}
        handleChangeJudgeStatus={handleChangeJudgeStatus}
        rejectionComment={rejectionComment}
        handleChangeRejectionComment={handleChangeRejectionComment}
        isLoading={isLoading}
        sendPartnerApplicantsResult={sendPartnerApplicantsResult}
        isExceedsCharacterLimit={isExceedsCharacterLimit}
      />
    );
  }
);

export { PartnerApplicantsReceivedDialogContainer as PartnerApplicantsReceivedDialog };

type PartnerApplicantsReceivedDialogPresenterProps = {
  isExtraSmallDevice: boolean;
  isOpenDialog: boolean;
  closeDialog: () => void;
  partnerApplicant: PartnerApplicants | null;
  applicantsSource: {
    label: string;
    value?: string;
  }[];
  organizationSource: {
    label: string;
    value?: string | undefined;
  }[];
  isAdminUser: boolean;
  judgeStatus: JudgeStatusCollectionType;
  handleChangeJudgeStatus: (e: ChangeEvent<HTMLInputElement>) => void;
  rejectionComment: string;
  handleChangeRejectionComment: (e: ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  sendPartnerApplicantsResult: () => Promise<void>;
  isExceedsCharacterLimit: boolean;
};

const dialogTitleStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '50px',
});

const dialogActionStyle = css({
  textAlign: 'right',
});

const commentLengthStyle = css({ color: theme.palette.text.secondary });
const commentLengthAlertStyle = css({ color: theme.palette.error.main });

export const PartnerApplicantsReceivedDialogPresenter: FC<PartnerApplicantsReceivedDialogPresenterProps> = memo(
  ({
    isExtraSmallDevice,
    isOpenDialog,
    closeDialog,
    partnerApplicant,
    organizationSource,
    applicantsSource,
    isAdminUser,
    judgeStatus,
    handleChangeJudgeStatus,
    rejectionComment,
    handleChangeRejectionComment,
    isLoading,
    sendPartnerApplicantsResult,
    isExceedsCharacterLimit,
  }) => (
    <Dialog open={isOpenDialog} onClose={closeDialog} maxWidth="md" fullWidth fullScreen={isExtraSmallDevice}>
      <DialogTitle css={dialogTitleStyle}>
        <Box display="flex" alignItems="center" fontWeight="bold">
          申請詳細
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton onClick={closeDialog} size="large">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent
        css={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <PartnerApplicantsReceivedMessage
            modifiedAt={partnerApplicant?.modifiedAt ?? ''}
            partnerApplicantStatus={partnerApplicant?.state}
          />
          {partnerApplicant?.state === PartnerApplicantsStatusCollection.standby && (
            <Fragment>
              <Box fontWeight="bold" fontSize="18px" borderLeft="8px solid #52A0D6" pl={1} mt={2} mb={1}>
                審査
              </Box>
              <RadioGroup row value={judgeStatus} onChange={handleChangeJudgeStatus}>
                <FormControlLabel value="approval" control={<Radio />} label="承認する" />
                <FormControlLabel value="rejection" control={<Radio />} label="却下する" />
              </RadioGroup>
              {judgeStatus === JudgeStatusCollection.rejection && (
                <Grid item xs={12} container>
                  <Grid container>
                    <Grid
                      item
                      css={[applicantsItemStyle, { backgroundColor: theme.palette.secondary.light }]}
                      xs={12}
                      md={4}
                    >
                      <Typography fontWeight="bold">コメント</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} css={applicantsItemStyle}>
                      <TextField
                        multiline
                        minRows={2}
                        fullWidth
                        value={rejectionComment}
                        onChange={handleChangeRejectionComment}
                        inputRef={input => input && !isExtraSmallDevice && input.focus()}
                        onFocus={e =>
                          e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
                        }
                        error={isExceedsCharacterLimit}
                        helperText={isExceedsCharacterLimit && '300文字以内で入力してください'}
                      />
                      <Box pt={1} ml={1} css={isExceedsCharacterLimit ? commentLengthAlertStyle : commentLengthStyle}>
                        {rejectionComment.length}/{MAX_REJECTION_COMMENT}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Fragment>
          )}
          <Box fontWeight="bold" fontSize="18px" borderLeft="8px solid #52A0D6" pl={1} mt={2} mb={1}>
            申請元
          </Box>
          <DisplayItem itemList={applicantsSource} />
          <Box fontWeight="bold" fontSize="18px" borderLeft="8px solid #52A0D6" pl={1} mt={2} mb={1}>
            申請先
          </Box>
          <DisplayItem itemList={organizationSource} />
        </Box>
        <Box>
          {partnerApplicant?.state === PartnerApplicantsStatusCollection.rejected && (
            <Fragment>
              <Box fontWeight="bold" fontSize="18px" borderLeft="8px solid #52A0D6" pl={1} mt={2} mb={1}>
                審査コメント
              </Box>
              <TextField
                variant="standard"
                multiline
                minRows={2}
                fullWidth
                value={partnerApplicant.rejectionComment}
                inputProps={{ readOnly: true }}
              />
            </Fragment>
          )}
        </Box>
      </DialogContent>
      <Divider />
      {partnerApplicant?.state === PartnerApplicantsStatusCollection.standby && (
        <DialogActions css={dialogActionStyle}>
          <Tooltip title={!isAdminUser ? '管理者のみ承認/却下できます' : ''} placement="top-start">
            <div>
              <LoadingButton
                variant="contained"
                css={{ marginRight: '8px' }}
                disabled={!isAdminUser || isExceedsCharacterLimit}
                loading={isLoading}
                onClick={() => sendPartnerApplicantsResult()}
              >
                送信する
              </LoadingButton>
            </div>
          </Tooltip>
        </DialogActions>
      )}
    </Dialog>
  )
);
