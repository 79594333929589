import { useMemo } from 'react';

import { useCurrentUser } from './Esa/RequireCurrentUser';
import { useEsaDomain } from './Esa/useEsaDomain';

export const useIsValidTakkenLicenseNumber = (): boolean => {
  const user = useCurrentUser();
  const { domain } = useEsaDomain(user.domainUid);
  const isValidTakkenLicenseNumber = useMemo(() => {
    return (
      domain?.takkenLicense?.number !== undefined &&
      Number(domain.takkenLicense.number) > 0 &&
      domain.takkenLicense.number.length === 6
    );
  }, [domain?.takkenLicense?.number]);
  return isValidTakkenLicenseNumber;
};
