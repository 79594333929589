import { DependencyIsNotSetupError } from '@app/errors';
import { FC, createContext, useContext, ReactNode } from 'react';

import type { Services } from '@/Services/SetupDependencies';

const DependencyContext = createContext<Services | null>(null);

type DependencyProviderProps = {
  services: Services | null;
  children: ReactNode;
};

export function useDependency<K extends keyof Services>(key: K): Services[K] {
  const services = useContext(DependencyContext);
  if (services === null) {
    throw new DependencyIsNotSetupError();
  }

  return services[key];
}

export const DependencyProvider: FC<DependencyProviderProps> = props => {
  return <DependencyContext.Provider value={props.services}>{props.children}</DependencyContext.Provider>;
};
