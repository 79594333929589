var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import { Close } from 'mdi-material-ui';
import { useCallback } from 'react';
import { textSxProps } from '../Styles';
const SubmitDialogContainer = ({ isOpen, title, children, submitText, submitProps, onSubmit, cancelText, onClose, persistent, dialogProps, }) => {
    const onSubmitButtonClick = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield (onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit());
            onClose();
        }
        catch (err) {
            return;
        }
    }), [onSubmit, onClose]);
    return (_jsx(SubmitDialogPresenter, { isOpen: isOpen, title: title, children: children, submitText: submitText, submitProps: submitProps, onSubmitButtonClick: onSubmitButtonClick, cancelText: cancelText, onClose: onClose, persistent: persistent, dialogProps: dialogProps }));
};
export { SubmitDialogContainer as SubmitDialog };
export const SubmitDialogPresenter = ({ isOpen, title, children, submitText, submitProps, onSubmitButtonClick, cancelText, onClose, persistent, dialogProps, }) => {
    return (_jsxs(Dialog, Object.assign({ open: isOpen, onClose: (_event, reason) => {
            // NOTE: ダイアログ画面外のクリックのみ制御、ESC キー押下はハンドルしていない
            // https://mui.com/api/dialog/   onClose を参照
            if (persistent && reason === 'backdropClick') {
                return;
            }
            onClose();
        } }, dialogProps, { children: [_jsxs(DialogTitle, Object.assign({ sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }, { children: [_jsx(Box, Object.assign({ sx: Object.assign({}, textSxProps.bold) }, { children: title })), _jsx(Box, Object.assign({ display: "flex", alignItems: "center", justifyContent: "flex-end" }, { children: _jsx(IconButton, Object.assign({ onClick: onClose, size: "large" }, { children: _jsx(Close, {}) })) }))] })), _jsx(Divider, {}), _jsx(DialogContent, { children: children }), _jsx(Divider, {}), _jsx(DialogActions, { children: _jsxs(Box, Object.assign({ display: "flex" }, { children: [_jsx(Button, Object.assign({ onClick: onClose, sx: Object.assign({ marginRight: '8px' }, textSxProps.blackText) }, { children: cancelText !== null && cancelText !== void 0 ? cancelText : 'キャンセル' })), _jsx(Button, Object.assign({ onClick: onSubmitButtonClick }, submitProps, { children: submitText !== null && submitText !== void 0 ? submitText : '送信' }))] })) })] })));
};
