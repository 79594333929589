import { EsaPublicDomain } from './EsaDomain';
import { EsaPublicOrganization } from './EsaOrganization';

export const PartnerStatusCollection = {
  notPartner: 0,
  partner: 1,
  sentStandby: 2,
  receivedStandby: 3,
} as const;

export type PartnerStatusCollectionType = typeof PartnerStatusCollection[keyof typeof PartnerStatusCollection];

export type Partner = {
  createdAt: string;
  domainUid: string;
  modifiedAt: string;
  organizationUid: string;
  partnerDomain: EsaPublicDomain;
  partnerDomainUid: string;
  partnerOrganization: EsaPublicOrganization;
  partnerOrganizationUid: string;
  partnerUid: string;
};
