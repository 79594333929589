import { isValid, differenceInDays, differenceInHours, differenceInMinutes, format } from 'date-fns';

export const makeDateDiffTextFromNow = (date: Date, isCustomerView: boolean): string => {
  if (!isValid(date)) {
    return '';
  }

  const nowDate = new Date();
  const dateDiff = differenceInDays(nowDate, date);
  const hourDiff = differenceInHours(nowDate, date);
  const minuteDiff = differenceInMinutes(nowDate, date);
  return dateDiff > 30
    ? `${!isCustomerView ? '30日以上前' : ''}`
    : dateDiff > 0
    ? `${dateDiff}日前`
    : hourDiff > 0
    ? `${hourDiff}時間前`
    : `${minuteDiff}分前`;
};

/**
 * 日付を yyyy/MM/dd に変換するメソッド
 * @param date 日付（例：Tue Apr 11 2023 00:00:00 GMT+0900 (日本標準時)）
 * @returns yyyy/MM/dd の日付（例：2023/04/11）
 */
export const formatDate = (date: Date | undefined): string => {
  if (date === undefined) {
    return '';
  }
  return format(date, 'yyyy/MM/dd');
};

// TODO: formatDate と共通化する
/**
 * 日付を yyyy/MM/dd HH:mm に変換するメソッド
 * @param date 日付（例：Tue Apr 11 2023 00:00:00 GMT+0900 (日本標準時)）
 * @returns yyyy/MM/dd HH:mm の日付（例：2023/04/11）
 */
export const formatDateTime = (date: Date | undefined): string => {
  if (date === undefined) {
    return '';
  }
  return format(date, 'yyyy/MM/dd HH:mm');
};

/**
 * 未来日以降の日付を与えた時にtrueを返す
 * @param date 日付
 * @returns boolean
 */
export const isFutureNowDate = (date: string | null | undefined): boolean => {
  if (!date) {
    return false;
  }

  const specifiedDate = new Date(date);
  const now = new Date();
  const dateDiff = differenceInDays(specifiedDate, now);
  return dateDiff > 0;
};

/**
 * 今日の日付を与えた時にtrueを返す
 * @param date 日付
 * @returns boolean
 */
export const isNowDate = (date: string | null | undefined): boolean => {
  if (!date) {
    return false;
  }

  const specifiedDate = new Date(date);
  const now = new Date();
  const dateDiff = differenceInDays(specifiedDate, now);
  return dateDiff === 0;
};
