import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { IconButton } from '@mui/material';
import { useState, useCallback, Fragment } from 'react';
import { useOnOffState } from '../Hooks';
import { MenuWithoutBlock } from './MenuWithoutBlock';
export const IconButtonMenu = props => {
    const [isMenuOpen, { toggle: toggleMenuOpen, setFalse: setMenuClose }] = useOnOffState(false);
    const [ref, setRef] = useState(null);
    const onButtonClick = useCallback(e => {
        e.stopPropagation();
        toggleMenuOpen();
    }, [toggleMenuOpen]);
    return (_jsxs(Fragment, { children: [_jsx("div", Object.assign({ ref: setRef, "data-testid": "icon-button-menu" }, { children: _jsx(IconButton, Object.assign({ onClick: onButtonClick, size: "large", sx: { padding: props.padding } }, { children: props.icon })) })), _jsx(MenuWithoutBlock, { isMenuOpen: isMenuOpen, setMenuClose: setMenuClose, menuActions: props.menuActions, anchorEl: ref })] }));
};
