import { ListItemButton, ListItemIcon, ListItemText, listItemTextClasses, styled } from '@mui/material';
import { FC, ReactNode, memo } from 'react';

import { textSxProps } from '@/Hooks/Styles/TextStyle';

const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
  minHeight: '44px',
  [theme.breakpoints.down('md')]: {
    minHeight: '48px',
  },
}));

type MenuListItemProps = {
  children: ReactNode;
  onClick: () => void;
  text: string;
  endIcon?: ReactNode;
  newLabel?: ReactNode;
};

export const MenuListItem: FC<MenuListItemProps> = memo(({ children, onClick, text, endIcon, newLabel }) => {
  return (
    <CustomListItemButton onClick={onClick}>
      <ListItemIcon
        sx={{
          minWidth: '30px',
        }}
      >
        {children}
      </ListItemIcon>
      <ListItemText sx={{ [`& .${listItemTextClasses.primary}`]: textSxProps.md }}>{text}</ListItemText>
      {newLabel}
      {endIcon}
    </CustomListItemButton>
  );
});
