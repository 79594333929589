import { getViewportHeight } from '@app/utils';
import { Loading, useOnOffState } from '@lib/components';
import { Box, Pagination, TablePagination } from '@mui/material';
import {
  ChangeEvent,
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocalStorage } from 'react-use';

import { PartnerApplicantsList } from './PartnerApplicantsList';
import { PartnerApplicantsStatusSwitchButton } from './PartnerApplicantsStatusSwitchButton';

import { MyOrganizationsSelector } from '@/Components/Partner/MyOrganizationSelector';
import { paginationStyle } from '@/Components/Partner/PartnerOrganizationList';
import { allOrganization } from '@/Components/Partner/PartnerOrganizationPage';
import { AddPartnerOrganizationDialog } from '@/Components/Partner/Search/AddPartnerOrganizationDialog';
import { AppState } from '@/Consts/AppState';
import { searchResultFooterZIndex, searchResultHeaderZIndex } from '@/Consts/Values';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { useEsaDomainOrganizations } from '@/Hooks/Esa/useEsaDomainOrganizations';
import { usePartnerApplicants, usePartnerApplicantsQuery } from '@/Hooks/Esa/usePartnerApplicants';
import { EsaOrganization } from '@/Models/Esa/EsaOrganization';
import {
  PartnerApplicants,
  PartnerApplicantsStatusCollection,
  PartnerApplicantsStatusCollectionType,
  PartnerApplicantsTypeCollectionType,
  isPartnerApplicantsStatus,
} from '@/Models/Esa/PartnerApplicants';
import {
  ItemPerPageKey,
  ItemsPerPageCollection,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { GeneralServerErrorPage } from '@/Pages/error/GeneralServerErrorPage';
import { getTotalPageCount } from '@/Utils/SearchResultUtils';

type PartnerApplicantsPageContainerProps = {
  partnerApplicantsType: PartnerApplicantsTypeCollectionType;
};

const PartnerApplicantsPageContainer: FC<PartnerApplicantsPageContainerProps> = memo(({ partnerApplicantsType }) => {
  const { partnerApplicantsQuery, setPartnerApplicantsQuery } = usePartnerApplicantsQuery({});

  const [itemsPerPage, setItemsPerPage] = useLocalStorage(
    AppState.partnerApplicantsListItemsPerPage,
    partnerApplicantsQuery.itemsPerPage
  ) as [number, React.Dispatch<React.SetStateAction<number>>, () => void];

  const startIndex = useMemo(() => {
    if (!partnerApplicantsQuery.startIndex) {
      return 1;
    }
    return Math.floor(partnerApplicantsQuery.startIndex / itemsPerPage + 1);
  }, [itemsPerPage, partnerApplicantsQuery.startIndex]);

  const [partnerApplicantsStatus, setPartnerApplicantsStatus] = useState<PartnerApplicantsStatusCollectionType>(
    isPartnerApplicantsStatus(partnerApplicantsQuery.states)
      ? partnerApplicantsQuery.states
      : PartnerApplicantsStatusCollection.standby
  );

  const [currentOrganizationUid, setCurrentOrganizationUid] = useState<string | undefined>(undefined);
  const handleCurrentOrganizationUid = (uid: string | undefined): void => {
    setPartnerApplicantsQuery({
      startIndex: 1,
      itemsPerPage: partnerApplicantsQuery.itemsPerPage,
      states: partnerApplicantsStatus,
    });
    setCurrentOrganizationUid(uid);
  };

  const {
    model: partnerApplicantsModel,
    isLoading,
    isValidating,
    error,
  } = usePartnerApplicants(
    {
      ...partnerApplicantsQuery,
      type: partnerApplicantsType,
      organizationUids: currentOrganizationUid,
      expandDomain: true,
      expandOrganization: true,
    },
    30 * 1000
  );
  const partnerApplicantsList = partnerApplicantsModel?.items ?? [];
  const totalCount = partnerApplicantsModel?.totalCounts || 0;
  const totalPageCount = getTotalPageCount(totalCount, itemsPerPage) ?? 0;

  const rowsPerPageOptions = Object.values(ItemsPerPageCollection).map(item => {
    return { value: item, label: `${item}件` };
  });

  useEffect(() => {
    setPartnerApplicantsQuery({
      startIndex: (startIndex - 1) * itemsPerPage + 1,
      itemsPerPage: itemsPerPage,
      states: partnerApplicantsStatus,
    });
  }, [startIndex, itemsPerPage, setPartnerApplicantsQuery, partnerApplicantsStatus]);

  const handleChangePage = useCallback(
    (_event: ChangeEvent<unknown> | null, newPage: number) => {
      if (newPage === null) {
        return;
      }
      const targetStartIndex = (newPage - 1) * itemsPerPage + 1;
      setPartnerApplicantsQuery({
        startIndex: targetStartIndex,
        itemsPerPage: partnerApplicantsQuery.itemsPerPage,
        states: partnerApplicantsStatus,
      });
    },
    [itemsPerPage, partnerApplicantsQuery.itemsPerPage, partnerApplicantsStatus, setPartnerApplicantsQuery]
  );

  const handleChangeItemsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const targetItemsPerPage = parseInt(event.target.value);
      setPartnerApplicantsQuery({
        startIndex: 1,
        itemsPerPage: targetItemsPerPage,
      });
      setItemsPerPage(targetItemsPerPage);
    },
    [setItemsPerPage, setPartnerApplicantsQuery]
  );

  const user = useCurrentUser();

  const { organizations, isLoading: isDomainLoading } = useEsaDomainOrganizations({ domainUid: user.domainUid });
  const hasMasterRole = useMemo(() => {
    return user.role.isAdmin;
  }, [user.role.isAdmin]);

  const userOrganizations: EsaOrganization[] = useMemo(() => {
    if (!organizations) {
      return [];
    }

    if (hasMasterRole) {
      return organizations;
    }

    return organizations?.filter((o: EsaOrganization) => user.organizationUids.includes(o.organizationUid));
  }, [organizations, user.organizationUids, hasMasterRole]);

  const organizationList = useMemo(() => {
    return [allOrganization, ...userOrganizations];
  }, [userOrganizations]);

  const isMultipleOrganization = useMemo(() => {
    return userOrganizations ? userOrganizations.length > 1 : false;
  }, [userOrganizations]);

  const [isOpenDialog, { setTrue: openDialog, setFalse: closeDialog }] = useOnOffState(false);

  if (isDomainLoading) {
    return (
      <Box height={getViewportHeight(75)} width="100%" overflow="hidden">
        <Loading />
      </Box>
    );
  }

  if (error) {
    return <GeneralServerErrorPage />;
  }

  return (
    <PartnerApplicantsPagePresenter
      partnerApplicantsList={partnerApplicantsList}
      isLoading={isLoading}
      isValidating={isValidating}
      partnerApplicantsType={partnerApplicantsType}
      startIndex={startIndex}
      itemsPerPage={itemsPerPage}
      totalCount={totalCount}
      totalPageCount={totalPageCount}
      rowsPerPageOptions={rowsPerPageOptions}
      handleChangePage={handleChangePage}
      handleChangeItemsPerPage={handleChangeItemsPerPage}
      partnerApplicantsStatus={partnerApplicantsStatus}
      setPartnerApplicantsStatus={setPartnerApplicantsStatus}
      organizationList={organizationList}
      currentOrganizationUid={currentOrganizationUid}
      handleCurrentOrganizationUid={handleCurrentOrganizationUid}
      isMultipleOrganization={isMultipleOrganization}
      isOpenDialog={isOpenDialog}
      openDialog={openDialog}
      closeDialog={closeDialog}
    />
  );
});

export { PartnerApplicantsPageContainer as PartnerApplicantsPage };

type PartnerApplicantsPagePresenterProps = {
  partnerApplicantsList: PartnerApplicants[];
  isLoading: boolean;
  isValidating: boolean;
  partnerApplicantsType: PartnerApplicantsTypeCollectionType;
  startIndex: number;
  itemsPerPage: number;
  totalCount: number;
  totalPageCount: number;
  rowsPerPageOptions: {
    value: ItemPerPageKey;
    label: string;
  }[];
  handleChangePage: (_event: ChangeEvent<unknown> | null, newPage: number) => void;
  handleChangeItemsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
  partnerApplicantsStatus: PartnerApplicantsStatusCollectionType;
  setPartnerApplicantsStatus: Dispatch<SetStateAction<PartnerApplicantsStatusCollectionType>>;
  organizationList: (
    | EsaOrganization
    | {
        organizationUid: undefined;
        name: string;
      }
  )[];
  currentOrganizationUid: string | undefined;
  handleCurrentOrganizationUid: (uid: string | undefined) => void;
  isMultipleOrganization: boolean;
  isOpenDialog: boolean;
  openDialog: () => void;
  closeDialog: () => void;
};

export const PartnerApplicantsPagePresenter: FC<PartnerApplicantsPagePresenterProps> = ({
  partnerApplicantsList,
  isLoading,
  isValidating,
  partnerApplicantsType,
  startIndex,
  itemsPerPage,
  totalCount,
  totalPageCount,
  rowsPerPageOptions,
  handleChangePage,
  handleChangeItemsPerPage,
  partnerApplicantsStatus,
  setPartnerApplicantsStatus,
  organizationList,
  currentOrganizationUid,
  handleCurrentOrganizationUid,
  isMultipleOrganization,
  isOpenDialog,
  openDialog,
  closeDialog,
}) => (
  <Fragment>
    <Box
      zIndex={searchResultHeaderZIndex}
      position="sticky"
      bgcolor="grey.100"
      top={0}
      pt={1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
      flexWrap="wrap"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="row" flexWrap="wrap">
        <TablePagination
          component="div"
          css={paginationStyle}
          page={startIndex - 1}
          count={totalCount}
          rowsPerPage={itemsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          labelRowsPerPage=""
          labelDisplayedRows={({ from, to, count }) => {
            return (
              <Box component="span" display="flex" flexDirection="row" alignItems="center" ml={1}>
                <Box component="span" fontWeight="bold" fontSize="18px" mr={0.5}>
                  {totalCount === 0 ? '0件' : `${from}-${to}件`}
                </Box>
                <Box component="span">{`/ 全${count}件`}</Box>
              </Box>
            );
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeItemsPerPage}
          ActionsComponent={() => <div />}
        />
        <Box ml={1}>
          <PartnerApplicantsStatusSwitchButton
            partnerApplicantsType={partnerApplicantsType}
            partnerApplicantsStatus={partnerApplicantsStatus}
            setPartnerApplicantsStatus={setPartnerApplicantsStatus}
          />
        </Box>
      </Box>
      {isMultipleOrganization && (
        <Box display="flex" justifyContent="flex-end" py={1} ml="auto" mr={2}>
          <MyOrganizationsSelector
            organizationList={organizationList}
            currentOrganizationUid={currentOrganizationUid}
            handleCurrentOrganizationUid={handleCurrentOrganizationUid}
          />
        </Box>
      )}
    </Box>
    <PartnerApplicantsList
      partnerApplicantsList={partnerApplicantsList}
      partnerApplicantsType={partnerApplicantsType}
      isLoading={isLoading}
      isValidating={isValidating}
      totalCount={totalCount}
      isMultipleOrganization={isMultipleOrganization}
      openDialog={openDialog}
    />
    {isOpenDialog && <AddPartnerOrganizationDialog isOpenDialog={isOpenDialog} closeDialog={closeDialog} />}
    {totalCount !== 0 && (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        py={1}
        position="sticky"
        bottom={0}
        zIndex={searchResultFooterZIndex}
        bgcolor="grey.100"
      >
        <Pagination
          count={totalPageCount}
          onChange={handleChangePage}
          page={startIndex}
          shape="rounded"
          size="medium"
        />
      </Box>
    )}
  </Fragment>
);
