const defultDebounceTime = 500;

export const debounce = <T extends (...args: never[]) => unknown>(
  callback: T,
  delay = defultDebounceTime
): ((...args: Parameters<T>) => void) => {
  let timeoutId: NodeJS.Timeout;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => callback(...args), delay);
  };
};
