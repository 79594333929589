import { css } from '@emotion/react';
import { textSxProps, useOnOffState } from '@lib/components';
import { Box, Button, Typography } from '@mui/material';
import { AccountPlus } from 'mdi-material-ui';
import { FC, memo } from 'react';

import { PartnerOrganizationPage } from '@/Components/Partner/PartnerOrganizationPage';
import { AddPartnerOrganizationDialog } from '@/Components/Partner/Search/AddPartnerOrganizationDialog';
import { analyticsPlaceNameBusinessPartnerManagement } from '@/Consts/Values';
import { useUserActionTracker } from '@/Hooks/Analytics';
import { textColorSxProps } from '@/Hooks/Styles/ColorStyle';

const PartnerPageContainer: FC = memo(() => {
  const [isOpenDialog, { setTrue: openDialog, setFalse: closeDialog }] = useOnOffState(false);
  const tracker = useUserActionTracker(analyticsPlaceNameBusinessPartnerManagement);
  const onOpenDialog = () => {
    tracker('取引先追加ボタン押下');
    openDialog();
  };
  return <PartnerPagePresenter isOpenDialog={isOpenDialog} openDialog={onOpenDialog} closeDialog={closeDialog} />;
});

export { PartnerPageContainer as PartnerPage };

type PartnerPagePresenterProps = {
  isOpenDialog: boolean;
  openDialog: () => void;
  closeDialog: () => void;
};

const addPartnerButtonStyle = css({
  width: '100px',
  height: '28px',
  marginLeft: '8px',
  background: '#E3B309',
  '&:hover': {
    background: '#D4A708',
  },
});

export const PartnerPagePresenter: FC<PartnerPagePresenterProps> = memo(({ isOpenDialog, openDialog, closeDialog }) => {
  return (
    <Box display="flex" flexDirection="column" sx={{ minHeight: '100%' }}>
      <Box width="100%" pt={3}>
        <Box display="flex">
          <Typography mx={2} mb={3} sx={{ ...textColorSxProps.title, ...textSxProps.bold, ...textSxProps.xl }}>
            取引先管理 ＞ 取引先
          </Typography>
          <Button
            onClick={openDialog}
            startIcon={<AccountPlus />}
            variant="contained"
            color="warning"
            css={addPartnerButtonStyle}
          >
            取引先追加
          </Button>
        </Box>
        <PartnerOrganizationPage />
      </Box>
      {isOpenDialog && <AddPartnerOrganizationDialog isOpenDialog={isOpenDialog} closeDialog={closeDialog} />}
    </Box>
  );
});
