import { css } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import { AccountPlus } from 'mdi-material-ui';
import { FC, memo } from 'react';

import { searchResultSxProps } from '@/Pages/bukken/chintai/SearchPartial/SearchResultStyles';

type EmptyPartnerApplicantsContainerProps = {
  openDialog: () => void;
};

const EmptyPartnerApplicantsContainer: FC<EmptyPartnerApplicantsContainerProps> = memo(({ openDialog }) => {
  return <EmptyPartnerApplicantsPresenter openDialog={openDialog} />;
});

export { EmptyPartnerApplicantsContainer as EmptyPartnerApplicants };

type EmptyPartnerApplicantsPresenterProps = {
  openDialog: () => void;
};

const addPartnerButtonStyle = css({
  width: '100px',
  height: '28px',
  marginLeft: '8px',
  background: '#E3B309',
  '&:hover': {
    background: '#D4A708',
  },
});

export const EmptyPartnerApplicantsPresenter: FC<EmptyPartnerApplicantsPresenterProps> = memo(({ openDialog }) => (
  <Box sx={searchResultSxProps.emptyStateBox}>
    <AccountPlus sx={searchResultSxProps.emptyStateIcon} />
    <Typography>申請はありません</Typography>
    <Typography mb={2}>「取引先追加」ボタンから不動産会社組織へ申請が送付できます</Typography>
    <Button
      onClick={openDialog}
      startIcon={<AccountPlus />}
      variant="contained"
      color="warning"
      css={addPartnerButtonStyle}
    >
      取引先追加
    </Button>
  </Box>
));
