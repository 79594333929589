import * as Sentry from '@sentry/browser';

type ErrorLoggerFunction = (error: Error | string, place: string, fatal?: boolean) => void;

const reportErrorToConsole: ErrorLoggerFunction = error => {
  // eslint-disable-next-line no-console
  console.error(error);
};

const reportErrorToGoogleAnalytics: ErrorLoggerFunction = (error, place, fatal) => {
  if (window?.gtag === null || window?.gtag === undefined) {
    return;
  }

  const description = error instanceof Error ? `${error.name}(${place}): ${error.message}` : `${place}: ${error}`;
  // https://developers.google.com/analytics/devguides/collection/ga4/exceptions?hl=ja
  window.gtag('event', 'exception', {
    description,
    fatal,
  });
};

const reportErrorToSentry: ErrorLoggerFunction = (error, place, fatal) => {
  Sentry.captureException(error);
};

const reportErrorFunctionsRaw: (ErrorLoggerFunction | undefined)[] = [
  import.meta.env.MODE !== 'production' ? reportErrorToConsole : undefined,
  import.meta.env.VITE_DONT_SEND_ERROR?.toLowerCase() !== 'true' ? reportErrorToGoogleAnalytics : undefined,
  reportErrorToSentry,
];

const reportErrorFunctions = Object.freeze(
  reportErrorFunctionsRaw.filter((f): f is ErrorLoggerFunction => f !== undefined)
);

/**
 * GoogleAnalyticsへエラーを送信する
 * @param error エラーメッセージ
 * @param place エラーの発生箇所
 * @param fatal ユーザが操作不能に陥るエラーであるかどうか
 */
export const reportError: ErrorLoggerFunction = (error, place, fatal) =>
  reportErrorFunctions.forEach(f => f(error, place, fatal));

/**
 * GoogleAnalyticsへエラーを送信する関数を生成する
 * @param placePrefix エラーメッセージへ付与する発生箇所のprefix
 */
export const makeReportErrorFunc =
  (placePrefix: string): ErrorLoggerFunction =>
  (error, subPlace, fatal) =>
    reportError(error, `${placePrefix}.${subPlace}`, fatal);
