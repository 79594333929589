import { Alert } from '@e-seikatsu/design-system';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Box, Button } from '@mui/material';
import { parseISO } from 'date-fns';
import { MessageTextOutline } from 'mdi-material-ui';
import { FC, Fragment, memo, useCallback, useMemo } from 'react';

import { messengerWindowFeatures } from '@/Components/Partner/PartnerStatusButton';
import {
  PartnerApplicantsStatusCollection,
  PartnerApplicantsStatusCollectionType,
} from '@/Models/Esa/PartnerApplicants';
import { ServiceConfigure } from '@/Services/Configure';
import { formatDateTime } from '@/Utils/DisplayText/Date';

type PartnerApplicantsReceivedMessageContainerProps = {
  modifiedAt: string;
  partnerApplicantStatus: PartnerApplicantsStatusCollectionType | undefined;
};

const PartnerApplicantsReceivedMessageContainer: FC<PartnerApplicantsReceivedMessageContainerProps> = memo(
  ({ modifiedAt, partnerApplicantStatus }) => {
    const onOpenMessenger = useCallback(() => {
      window.open(ServiceConfigure.messengerUrl, '_blank', messengerWindowFeatures);
    }, []);
    const message = useMemo(() => {
      switch (partnerApplicantStatus) {
        case PartnerApplicantsStatusCollection.standby:
          return (
            <Fragment>
              <Box>下記組織から取引先申請を受信しました。</Box>
              <Box color="GrayText">{formatDateTime(parseISO(modifiedAt))}</Box>
            </Fragment>
          );
        case PartnerApplicantsStatusCollection.approved:
          return (
            <Box textAlign="center">
              <Alert color="success">
                <Alert.Content>
                  <Box textAlign="left">
                    <Box>下記組織への取引先申請を承認しました。メッセージボタンから連絡を始めましょう。</Box>
                    <Box>{formatDateTime(parseISO(modifiedAt))}</Box>
                  </Box>
                </Alert.Content>
              </Alert>
              <Button
                variant="outlined"
                startIcon={<MessageTextOutline fontSize="small" sx={{ marginRight: '4px' }} />}
                css={{ marginTop: '16px' }}
                onClick={onOpenMessenger}
              >
                メッセージ
              </Button>
            </Box>
          );
        case PartnerApplicantsStatusCollection.rejected:
          return (
            <Fragment>
              <Box>下記組織からの取引先申請を却下しました。</Box>
              <Box color="GrayText">{formatDateTime(parseISO(modifiedAt))}</Box>
            </Fragment>
          );
        default:
          return <Fragment></Fragment>;
      }
    }, [modifiedAt, onOpenMessenger, partnerApplicantStatus]);

    return <PartnerApplicantsReceivedMessagePresenter message={message} />;
  }
);

export { PartnerApplicantsReceivedMessageContainer as PartnerApplicantsReceivedMessage };

type PartnerApplicantsReceivedMessagePresenterProps = {
  message: EmotionJSX.Element;
};

export const PartnerApplicantsReceivedMessagePresenter: FC<PartnerApplicantsReceivedMessagePresenterProps> = memo(
  ({ message }) => <Box width="100%">{message}</Box>
);
