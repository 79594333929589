import { getViewportHeight } from '@app/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { useCallback, FC, ReactElement } from 'react';

import { useAnnouncement } from './Announcement/AnnouncementHook';
import { TopBar } from './Topbar';

import { supportSiteUrl } from '@/Consts/ExternalSiteUrls';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { useLoginContext } from '@/Hooks/Esa/RequireLoginContext';
import { useEsaDomain } from '@/Hooks/Esa/useEsaDomain';
import { SideMenuContainer } from '@/Pages/bukken/chintai/SideMenu';
import { ServiceConfigure } from '@/Services/Configure';

type DefaultLayoutProps = {
  children: ReactElement;
};

export const ReducedLayout: FC<DefaultLayoutProps> = ({ children }) => {
  const { logout } = useAuth0();
  const loginContext = useLoginContext();
  const userInfo = useCurrentUser();
  const { domain } = useEsaDomain(userInfo.domainUid);
  const announcement = useAnnouncement();
  const onLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: ServiceConfigure.auth0RedirectUrl } });
  }, [logout]);

  return (
    <Box display="flex" flexDirection="column" height={getViewportHeight(100)}>
      <TopBar
        isSideMenuOpen={false}
        isMainFeatureAvailable={false}
        accountUrl={new URL('profile/edit', ServiceConfigure.esAccountUrl).toString()}
        supportSiteUrl={supportSiteUrl}
        sumaiEntryNaikenUrl={ServiceConfigure.esSumaiEntryNaikenUrl}
        sumaiEntryMoshikomiUrl={ServiceConfigure.esSumaiEntryMoshikomiUrl}
        onLogout={onLogout}
        loginContextEmail={loginContext.email}
        userInfo={userInfo}
        domainName={domain?.name ?? ''}
        sideMenu={<SideMenuContainer />}
        useAnnouncement={announcement}
        isCustomerView={false}
      />
      <Box flexGrow="1" overflow="hidden auto">
        <Box display="flex" minHeight="100%">
          <Box flexGrow={1}>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};
