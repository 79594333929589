import { Alert } from '@mui/material';
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar, VariantType } from 'notistack';
import { useCallback, ComponentProps, ReactNode, FC } from 'react';

type EsSnackbarOptions = {
  /**
   * アラートの左側にseverityに応じたアイコンを表示しない場合はtrue
   */
  hideIcon?: boolean;
  /**
   * アラートの右側に×ボタンを表示しない場合はtrue
   */
  hideCloseButton?: boolean;
};
type EsEnqueueSnackbarContext = (
  message: SnackbarMessage,
  options?: OptionsObject,
  extraOptions?: EsSnackbarOptions
) => SnackbarKey;

export const useEnqueueSnackbar: () => EsEnqueueSnackbarContext = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return useCallback(
    (message, rawOptions, extraOptions) => {
      const id = Date.now();
      const options = rawOptions ?? {};
      options.key = id;
      return enqueueSnackbar(
        <AlertInsideSnackbar
          variant={options?.variant}
          children={message}
          onClose={extraOptions?.hideCloseButton ? undefined : () => closeSnackbar(id)}
          icon={extraOptions?.hideIcon ? false : undefined}
        />,
        options
      );
    },
    [closeSnackbar, enqueueSnackbar]
  );
};

type AlertInsideSnackbarProps = {
  variant?: VariantType;
  children?: ReactNode;
  onClose: (() => void) | undefined;
  icon?: ReactNode;
};
const AlertInsideSnackbar: FC<AlertInsideSnackbarProps> = ({ variant, children, onClose, icon }) => {
  const severity: ComponentProps<typeof Alert>['severity'] = variant === 'default' ? 'info' : variant;
  return (
    <Alert
      variant="filled"
      elevation={6}
      onClose={onClose}
      severity={severity}
      children={children}
      icon={icon}
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    />
  );
};
