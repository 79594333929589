import { Box, Typography } from '@mui/material';
import { AccountPlus } from 'mdi-material-ui';
import { FC, memo } from 'react';

import { searchResultSxProps } from '@/Pages/bukken/chintai/SearchPartial/SearchResultStyles';

export const EmptyPartnerOrganization: FC = memo(() => {
  return (
    <Box sx={searchResultSxProps.emptyStateBox}>
      <AccountPlus sx={searchResultSxProps.emptyStateIcon} />
      <Typography fontSize="16px" fontWeight="bold" color="black" mt={3}>
        取引先が登録されていません
      </Typography>
      <Typography my={3}>上の「取引先追加」ボタンから不動産会社組織へ取引先申請を送付してください</Typography>
    </Box>
  );
});
