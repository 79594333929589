import { isNotNullOrEmptyString, isNullOrEmptyString } from '@app/utils';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ListItemResponse, EsaModelState, useEsaModel } from './useEsaModel';

import { useEnqueueSnackbar } from '@/Hooks/Snackbar';
import {
  PartnerApplicants,
  PartnerApplicantsPostPayload,
  PartnerApplicantsStatusCollection,
  PartnerApplicantsStatusCollectionType,
  PartnerApplicantsTypeCollectionType,
  isPartnerApplicantsStatus,
} from '@/Models/Esa/PartnerApplicants';
import { ServiceConfigure } from '@/Services/Configure';
import { useEsaApiClient } from '@/Services/EsaApiService';

export type PartnerApplicantsListItemResponse = ListItemResponse<PartnerApplicants>;

export type PartnerApplicantsState = EsaModelState<PartnerApplicantsListItemResponse | null>;
export type PartnerApplicantState = EsaModelState<PartnerApplicants | null>;

export type PartnerApplicantsQuery = {
  startIndex: number;
  itemsPerPage: number;
  type?: PartnerApplicantsTypeCollectionType;
  states?: PartnerApplicantsStatusCollectionType;
  organizationUids?: string;
  partnerOrganizationUids?: string;
  expandDomain?: boolean;
  expandOrganization?: boolean;
};

export type PartnerApplicantQuery = {
  partnerApplicantUid: string | null;
  expandDomain?: boolean;
  expandOrganization?: boolean;
};

const DEFAULT_START_INDEX = 1;
const DEFAULT_PER_PAGE = 10;

const getPartnerQueryParams = (partnerApplicantsQuery: PartnerApplicantsQuery): URLSearchParams => {
  const params = new URLSearchParams();
  const startIndex = partnerApplicantsQuery.startIndex ?? DEFAULT_START_INDEX;
  params.append('startIndex', startIndex.toString());
  const itemsPerPage = partnerApplicantsQuery.itemsPerPage ?? DEFAULT_PER_PAGE;
  params.append('itemsPerPage', itemsPerPage.toString());
  params.append(
    'states',
    isPartnerApplicantsStatus(partnerApplicantsQuery.states)
      ? partnerApplicantsQuery.states
      : PartnerApplicantsStatusCollection.standby
  );
  if (partnerApplicantsQuery.type) {
    params.append('type', partnerApplicantsQuery.type);
  }
  if (partnerApplicantsQuery.organizationUids) {
    params.append('organizationUids', partnerApplicantsQuery.organizationUids);
  }
  if (partnerApplicantsQuery.partnerOrganizationUids) {
    params.append('partnerOrganizationUids', partnerApplicantsQuery.partnerOrganizationUids);
  }
  if (partnerApplicantsQuery?.expandDomain) {
    params.append('expandDomain', partnerApplicantsQuery.expandDomain ? 'true' : 'false');
  }
  if (partnerApplicantsQuery?.expandOrganization) {
    params.append('expandOrganization', partnerApplicantsQuery.expandOrganization ? 'true' : 'false');
  }
  return params;
};

export const usePartnerApplicantsQuery = (query: {
  organizationUids?: string;
  partnerOrganizationUids?: string;
}): {
  partnerApplicantsQuery: PartnerApplicantsQuery;
  setPartnerApplicantsQuery: (params: PartnerApplicantsQuery) => void;
} => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getPartnerApplicantsQuery = (): PartnerApplicantsQuery => {
    const startIndex = searchParams.get('startIndex');
    const itemsPerPage = searchParams.get('itemsPerPage');
    const states = searchParams.get('states') as PartnerApplicantsStatusCollectionType;
    return {
      startIndex: startIndex ? parseInt(startIndex) || 0 : DEFAULT_START_INDEX,
      itemsPerPage: itemsPerPage ? parseInt(itemsPerPage) || 0 : DEFAULT_PER_PAGE,
      states: states ?? PartnerApplicantsStatusCollection.standby,
      ...query,
    };
  };

  const [partnerApplicantsQuery, setPartnerApplicantsQuery] = useState<PartnerApplicantsQuery>(
    getPartnerApplicantsQuery()
  );

  useEffect(() => {
    const params = new URLSearchParams();

    searchParams.forEach((key, value) => params.set(value, key));
    getPartnerQueryParams(partnerApplicantsQuery).forEach((key, value) => params.set(value, key));

    setSearchParams(params);
  }, [setSearchParams, partnerApplicantsQuery, searchParams]);

  return {
    partnerApplicantsQuery,
    setPartnerApplicantsQuery,
  };
};

export const usePartnerApplicants = (
  partnerApplicantsQuery: PartnerApplicantsQuery,
  refreshInterval?: number
): PartnerApplicantsState => {
  const partnerApplicantsParams = getPartnerQueryParams(partnerApplicantsQuery);
  const queryString = partnerApplicantsParams.toString();

  const { isLoading, error, model, mutate, isValidating } = useEsaModel<PartnerApplicantsListItemResponse | null>({
    api: {
      audience: ServiceConfigure.auth0Audience,
      baseUrl: ServiceConfigure.esAccountApi,
      path: isNotNullOrEmptyString(queryString) ? `/partner_applicants?${queryString}` : null,
    },
    refreshInterval: refreshInterval,
  });

  return {
    isLoading,
    error,
    model,
    mutate,
    isValidating,
  };
};

export const usePartnerApplicant = (partnerApplicantUid: string): PartnerApplicantState => {
  const params = new URLSearchParams({ expandDomain: 'true', expandOrganization: 'true' });
  const queryString = params.toString();

  const path = useMemo(() => {
    if (!partnerApplicantUid) {
      return null;
    }
    return `/partner_applicants/${partnerApplicantUid}?${queryString}`;
  }, [partnerApplicantUid, queryString]);

  const { isLoading, error, model, mutate, isValidating } = useEsaModel<PartnerApplicants | null>({
    api: {
      audience: ServiceConfigure.auth0Audience,
      baseUrl: ServiceConfigure.esAccountApi,
      path: path,
    },
  });

  if (isLoading) {
    return {
      isLoading,
      error: null,
      model: null,
      mutate,
      isValidating,
    };
  }

  if (error) {
    return {
      isLoading: false,
      error,
      model: null,
      mutate,
      isValidating,
    };
  }

  return {
    isLoading: false,
    error: null,
    model: model,
    mutate,
    isValidating,
  };
};

export const usePostPartnerApplicants = (): {
  postPartnerApplicants: (body: PartnerApplicantsPostPayload) => Promise<void>;
} => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const { apiClient } = useEsaApiClient({
    audience: ServiceConfigure.auth0Audience,
    baseUrl: ServiceConfigure.esAccountApi,
  });
  const postPartnerApplicants = async (body: PartnerApplicantsPostPayload) => {
    try {
      await apiClient?.post<PartnerApplicants>({ path: '/partner_applicants', body: JSON.stringify(body) });
      enqueueSnackbar('取引先申請を送信しました', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('取引先申請の送信に失敗しました', { variant: 'error' });
    }
  };
  return { postPartnerApplicants };
};

export const useCancelPartnerApplicants = (): {
  cancelPartnerApplicants: (partnerApplicantUid: string, body: { cancellationComment: string }) => Promise<void>;
} => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const { apiClient } = useEsaApiClient({
    audience: ServiceConfigure.auth0Audience,
    baseUrl: ServiceConfigure.esAccountApi,
  });
  const cancelPartnerApplicants = async (partnerApplicantUid: string, body: { cancellationComment: string }) => {
    try {
      if (isNullOrEmptyString(partnerApplicantUid)) {
        throw new Error('対象となる申請が見つかりません');
      }
      await apiClient?.post<PartnerApplicants>({
        path: `/partner_applicants/${partnerApplicantUid}/cancellations`,
        body: JSON.stringify(body),
      });
      enqueueSnackbar('申請を取り消しました', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('申請を取り消しに失敗しました', { variant: 'error' });
    }
  };
  return { cancelPartnerApplicants };
};

export const useApprovePartnerApplicants = (): {
  approvePartnerApplicants: (partnerApplicantUid: string, body: { approvalComment: string }) => Promise<void>;
} => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const { apiClient } = useEsaApiClient({
    audience: ServiceConfigure.auth0Audience,
    baseUrl: ServiceConfigure.esAccountApi,
  });
  const approvePartnerApplicants = async (partnerApplicantUid: string, body: { approvalComment: string }) => {
    try {
      if (isNullOrEmptyString(partnerApplicantUid)) {
        throw new Error('対象となる申請が見つかりません');
      }
      await apiClient?.post<PartnerApplicants>({
        path: `/partner_applicants/${partnerApplicantUid}/approvals`,
        body: JSON.stringify(body),
      });
      enqueueSnackbar('申請を承認しました。取引先情報の反映まで時間がかかる場合があります。', {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (e) {
      enqueueSnackbar('申請の承認に失敗しました', { variant: 'error' });
    }
  };
  return { approvePartnerApplicants };
};

export const useRejectPartnerApplicants = (): {
  rejectPartnerApplicants: (partnerApplicantUid: string, body: { rejectionComment: string }) => Promise<void>;
} => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const { apiClient } = useEsaApiClient({
    audience: ServiceConfigure.auth0Audience,
    baseUrl: ServiceConfigure.esAccountApi,
  });
  const rejectPartnerApplicants = async (partnerApplicantUid: string, body: { rejectionComment: string }) => {
    try {
      if (isNullOrEmptyString(partnerApplicantUid)) {
        throw new Error('対象となる申請が見つかりません');
      }
      await apiClient?.post<PartnerApplicants>({
        path: `/partner_applicants/${partnerApplicantUid}/rejections`,
        body: JSON.stringify(body),
      });
      enqueueSnackbar('申請を却下しました', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('申請の却下に失敗しました', { variant: 'error' });
    }
  };
  return { rejectPartnerApplicants };
};
