import { isNotNullOrUndefined } from '@app/utils';

export const getTotalPageCount = (
  totalCount: number | undefined,
  itemsPerPage: number | undefined
): number | undefined => {
  return totalCount !== 0 && isNotNullOrUndefined(totalCount) && isNotNullOrUndefined(itemsPerPage)
    ? Math.ceil(totalCount / itemsPerPage)
    : undefined;
};
