import { FC, createContext, useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useLoginContext } from './RequireLoginContext';

import { emptyCurrentUser } from '@/Consts/EsaDummyData';
import { EsaUser } from '@/Models/Esa/EsaUser';

const Context = createContext<EsaUser | undefined>(undefined);

export const RequireCurrentUser: FC = () => {
  const loginContext = useLoginContext();
  const navigate = useNavigate();

  // ユーザが存在して currentUser が要求される場合、一度 logout して currentUser を選択させる
  useEffect(() => {
    if (loginContext.users.length > 0 && loginContext.currentUserUid === null) {
      navigate('/login');
    }
  }, [loginContext.currentUserUid, loginContext.users.length, navigate]);

  const currentUser = loginContext.users.find(user => user.userUid === loginContext.currentUserUid);

  return (
    <Context.Provider value={currentUser}>
      <Outlet />
    </Context.Provider>
  );
};

export const useCurrentUser = (): EsaUser => {
  const currentUser = useContext(Context);

  if (!currentUser) {
    return emptyCurrentUser;
  }

  return currentUser;
};
