import { css } from '@emotion/react';
import { Box, Grid } from '@mui/material';
import { ComponentProps, FC } from 'react';

import { theme } from '@/Theme';

type DisplayItemProps = {
  itemList: { label: string; value?: string | null }[];
  gridProps?: Partial<ComponentProps<typeof Grid>>;
  gridLabelProps?: Partial<ComponentProps<typeof Grid>>;
  gridValueProps?: Partial<ComponentProps<typeof Grid>>;
};

export const applicantsItemStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderBottom: 'solid 1px var(--color-semantic-border-divider)',
  padding: '8px',
  minHeight: '40px',
  wordBreak: 'break-all',
});

export const DisplayItem: FC<DisplayItemProps> = ({ itemList, gridProps, gridLabelProps, gridValueProps }) => {
  return (
    <Box borderTop="solid 1px var(--color-semantic-border-divider)">
      {itemList.map(item => {
        return (
          <Grid item xs={12} key={item.label} container {...gridProps}>
            <Grid container>
              <Grid
                item
                bgcolor={theme.palette.secondary.light}
                css={applicantsItemStyle}
                xs={12}
                md={2}
                {...gridLabelProps}
              >
                {item.label}
              </Grid>
              <Grid item xs={12} md={10} css={applicantsItemStyle} {...gridValueProps}>
                {item.value}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};
