import useSWR from 'swr';

import { reportError } from '@/ErrorLogger';
import { ServiceConfigure } from '@/Services/Configure';

export type MaintenanceModelState<T> = {
  data?: T;
  error?: Error;
  isLoading?: boolean;
};

export const useMaintenanceInfoHook = <T>(url: string): MaintenanceModelState<T> => {
  const fetcher = (url: RequestInfo) =>
    fetch(url)
      .then(res => res.json())
      .catch(error => reportError(`メンテナンス情報の取得に失敗しました。${error}`, 'useMaintenanceInfoHook'));
  return useSWR<T>(url, fetcher, {
    refreshInterval: 5 * 60 * 1000,
    revalidateOnFocus: false,
  });
};

/**
 * OneAPI の メンテナンス状況を取得する
 * @returns OneAPI のステータス
 */

export type OneApiMaintenance = {
  prd: {
    isMaintenance: boolean;
  };
  inside: {
    isMaintenance: boolean;
  };
};

export const useIsOneApiMaintenance = () => {
  return useMaintenanceInfoHook<OneApiMaintenance>(ServiceConfigure.eboneStatusSource.url);
};

export type MaintenanceSchedule = {
  status: 'MAINTENANCE';
  from: string;
  to: string;
};

/**
 * OneAPI の メンテナンス日時を取得する
 * @returns OneAPI のメンテナンス日時
 */

export type OneApiMaintenanceSchedule = {
  prd: MaintenanceSchedule;
  inside: MaintenanceSchedule;
};

export const useOneApiMaintenanceInfo = () => {
  return useMaintenanceInfoHook<OneApiMaintenanceSchedule>(ServiceConfigure.eboneScheduleSource.url);
};

/**
 * 物件検索 の メンテナンス状況を取得する
 * @returns 物件検索 のステータス
 */

export type RentMaintenance = {
  inMaintenance: boolean;
};

export const useIsRentMaintenance = () => {
  return useMaintenanceInfoHook<RentMaintenance>(ServiceConfigure.rentStatusSource);
};

/**
 * 物件検索 の メンテナンス日時を取得する
 * @returns 物件検索 のメンテナンス日時
 */

export const useRentMaintenanceInfo = () => {
  return useMaintenanceInfoHook<MaintenanceSchedule>(ServiceConfigure.rentScheduleSource);
};
