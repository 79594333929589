import { Denwa } from '@/Models/Esa/EsaCommon';
import { Jusho } from '@/Models/Esa/EsaOrganization';
import { EsaUser } from '@/Models/Esa/EsaUser';

export const getDenwaNumber = (denwaAttribute: Denwa[] | undefined): string | undefined => {
  return denwaAttribute?.find(denwa => denwa.denwaNumberShubetsu === '電話')?.denwaNumber;
};

export const getFaxNumber = (denwaAttribute: Denwa[] | undefined): string | undefined => {
  return denwaAttribute?.find(denwa => denwa.denwaNumberShubetsu === 'FAX')?.denwaNumber;
};

export const getJusho = (jusho: Jusho | null | undefined): string => {
  if (!jusho || !jusho.jushoText) {
    return '-';
  }
  if (jusho.nokoriJusho) {
    if (jusho.gaikuGoto) {
      return jusho.jushoText + jusho.nokoriJusho + jusho.gaikuGoto;
    }
    return jusho.jushoText + jusho.nokoriJusho;
  }
  return jusho.jushoText;
};

export const getKameiDantai = (
  kameiDantaiList:
    | {
        name: string | null;
      }[]
    | undefined
): string | undefined => {
  const kameiDantaiListArray = kameiDantaiList?.map(kameiDantai => kameiDantai.name);
  return kameiDantaiListArray?.join('、');
};

export const getAuth0Id = (user: EsaUser | null): string | undefined => {
  // providerUserUid = auth0|${auth0ID}
  return user?.providerUserUid?.replace('auth0|', '');
};
