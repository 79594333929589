import { Denwa, Ebone } from './EsaCommon';

export const PartnerSearchPublicStatusCollection = {
  public: '公開',
  private: '非公開',
} as const;

export type PartnerSearchPublicStatusCollectionType =
  typeof PartnerSearchPublicStatusCollection[keyof typeof PartnerSearchPublicStatusCollection];

export const PartnerAutoApprovalStatusCollection = {
  partnerAutoApproval: '自動承認',
  partnerManualApproval: '手動承認',
} as const;

export type PartnerAutoApprovalStatusCollectionType =
  typeof PartnerAutoApprovalStatusCollection[keyof typeof PartnerAutoApprovalStatusCollection];

export type Jusho = {
  azaChoume: string | null;
  cityName: string | null;
  gaikuGoto: string | null;
  isDirectInput: boolean;
  jushoCode: string;
  jushoText: string;
  nokoriJusho: string | null;
  ooazaTsuushou: string | null;
  prefectureName: string | null;
  yubinNumber: string;
};

export type EsaOrganization = {
  businessName: string | null;
  createdAt: string;
  denwaList: Denwa[];
  domainUid: string;
  ebone: Ebone | null;
  isPublic: boolean;
  jusho: Jusho | null;
  kameiDantaiList: {
    name: string | null;
  }[];
  modifiedAt: string;
  name: string;
  organizationUid: string;
  partnerAutoApproval: {
    createdAt: string;
    enabled: boolean;
    modifiedAt: string;
  };
};

export type EsaPublicOrganization = {
  businessName: string | null;
  denwaList: Denwa[];
  domainUid: string;
  jusho: Jusho | null;
  kameiDantaiList: {
    name: string | null;
  }[];
  name: string;
  organizationUid: string;
  partnerCount?: number;
  ebone: Ebone | null;
};
