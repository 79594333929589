import { useIsExtraSmallDevice } from '@lib/components';
import { Autocomplete, TextField } from '@mui/material';
import { FC, memo } from 'react';
import { useAsync } from 'react-use';

import { AreaItem } from './AddPartnerOrganizationDialog';

import { useDependency } from '@/Hooks/DependencyHook';
import { Prefecture } from '@/Services/IMasterApiService';

type PartnerPrefectureSelectorContainerProps = {
  currentPref: Prefecture | null;
  onChange: (value: AreaItem) => void;
  handleIsInputEdit: (bool: boolean) => void;
};

const PartnerPrefectureSelectorContainer: FC<PartnerPrefectureSelectorContainerProps> = memo(
  ({ currentPref, onChange, handleIsInputEdit }) => {
    const isExtraSmallDevice = useIsExtraSmallDevice();
    const masterApiService = useDependency('masterApiService');
    const prefectures = useAsync(() => masterApiService.getPrefectures(), [masterApiService]).value ?? [];

    return (
      <PartnerPrefectureSelectorPresenter
        isExtraSmallDevice={isExtraSmallDevice}
        prefectures={prefectures}
        pref={currentPref}
        onChange={onChange}
        handleIsInputEdit={handleIsInputEdit}
      />
    );
  }
);

export { PartnerPrefectureSelectorContainer as PartnerPrefectureSelector };

type PartnerPrefectureSelectorPresenterProps = {
  isExtraSmallDevice: boolean;
  prefectures: readonly Prefecture[];
  pref: Prefecture | null;
  onChange: (value: AreaItem) => void;
  handleIsInputEdit: (bool: boolean) => void;
};

export const PartnerPrefectureSelectorPresenter: FC<PartnerPrefectureSelectorPresenterProps> = memo(
  ({ isExtraSmallDevice, prefectures, pref, onChange, handleIsInputEdit }) => {
    return (
      <Autocomplete
        closeText="閉じる"
        noOptionsText="候補なし"
        size="small"
        value={pref}
        options={prefectures}
        onChange={(_, newValue) => onChange({ pref: newValue, city: null })}
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option, value) => {
          return option.name === value.name;
        }}
        onFocus={() => handleIsInputEdit(true)}
        onBlur={() => handleIsInputEdit(false)}
        renderInput={params => <TextField {...params} variant="outlined" placeholder="都道府県を入力" />}
        css={isExtraSmallDevice ? { paddingBottom: '16px' } : { width: '200px' }}
        fullWidth={isExtraSmallDevice}
      />
    );
  }
);
