export const textSxProps = {
    xs: {
        fontSize: theme => theme.typography.pxToRem(10),
    },
    sm: {
        fontSize: theme => theme.typography.pxToRem(12),
    },
    md: {
        fontSize: theme => theme.typography.pxToRem(14),
    },
    lg: {
        fontSize: theme => theme.typography.pxToRem(18),
    },
    xl: {
        fontSize: theme => theme.typography.pxToRem(20),
    },
    bold: {
        fontWeight: 'bold',
    },
    nonDecoration: {
        textDecoration: 'none',
    },
    underlineOnHover: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    primaryText: {
        color: theme => theme.palette.primary.main,
    },
    attentionText: {
        color: theme => theme.palette.attention.main,
    },
    whiteText: {
        color: theme => theme.palette.background.paper,
    },
    blackText: {
        color: theme => theme.palette.text.primary,
    },
};
