import { createContext } from 'react';

import {
  BukkenDateRangeRadioState,
  BukkenNyukyoKanoDateRangeRadioState,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { DetailSearchConditionHooks } from '@/Pages/bukken/chintai/SearchPartial/DetailConditionForms';

export const isInvalidDate = (date: Date | undefined): boolean => {
  if (date === undefined) return true;
  return Number.isNaN(date.getTime());
};

export const isValidDateOrUndefined = (date: Date | undefined): boolean => {
  if (date === undefined) return true;
  return !Number.isNaN(date.getTime());
};

export const isDateConditionInvalid = (fromDate: Date | undefined, toDate: Date | undefined): boolean => {
  if (fromDate === undefined && toDate === undefined) return true;
  if (!isValidDateOrUndefined(fromDate) || !isValidDateOrUndefined(toDate)) return true;
  return false;
};

export const ValidationContext = createContext(true);

export const hasSearchConditionInvalid = (hooks: DetailSearchConditionHooks): boolean => {
  const koshinValue = hooks.koshinDateCondition.value;
  const kokaiValue = hooks.kokaiCondition.value;
  const nyukyoKanoDateValue = hooks.nyukyoKanoDateCondition.value;

  const isKoshinDateConditionInvalid =
    isDateConditionInvalid(koshinValue?.koshinDateFrom, koshinValue?.koshinDateTo) &&
    koshinValue?.koshinRadioState === BukkenDateRangeRadioState.select;

  const isKokaiDateConditionInvalid =
    isDateConditionInvalid(kokaiValue?.kokaiDateFrom, kokaiValue?.kokaiDateTo) &&
    kokaiValue?.kokaiRadioState === BukkenDateRangeRadioState.select;

  const isNyukyoKanoDateConditionInvalid =
    isDateConditionInvalid(nyukyoKanoDateValue?.nyukyoKanoDateFrom, nyukyoKanoDateValue?.nyukyoKanoDateTo) &&
    nyukyoKanoDateValue?.nyukyoKanoRadioState.state === BukkenNyukyoKanoDateRangeRadioState.select;

  const isSearchConditionInvalid =
    isKoshinDateConditionInvalid || isKokaiDateConditionInvalid || isNyukyoKanoDateConditionInvalid;

  return isSearchConditionInvalid;
};

export const createInvalidErrorMessage = (fromDate: Date | undefined, toDate: Date | undefined): string => {
  if (
    (fromDate === undefined && toDate === undefined) ||
    !isValidDateOrUndefined(fromDate) ||
    !isValidDateOrUndefined(toDate)
  ) {
    return '日付を入力してください';
  } else {
    return '';
  }
};
