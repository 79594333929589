import { Autocomplete, TextField } from '@mui/material';
import { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { EsaOrganization } from '@/Models/Esa/EsaOrganization';
import { theme } from '@/Theme';

type EsaOrganizationListContainerProps = {
  organizationList: EsaOrganization[];
  settingOrganizationUid: string;
};

const EsaOrganizationListContainer: FC<EsaOrganizationListContainerProps> = memo(
  ({ organizationList, settingOrganizationUid }) => {
    const navigate = useNavigate();
    const handleChangeOrganization = useCallback(
      (newOrganization: EsaOrganization | null) => {
        navigate(`/setting/${newOrganization?.organizationUid}`);
      },
      [navigate]
    );
    return (
      <EsaOrganizationListPresenter
        organizationList={organizationList}
        settingOrganizationUid={settingOrganizationUid}
        handleChangeOrganization={handleChangeOrganization}
      />
    );
  }
);

export { EsaOrganizationListContainer as EsaOrganizationList };

type EsaOrganizationListPresenterProps = {
  readonly organizationList: EsaOrganization[];
  settingOrganizationUid: string;
  handleChangeOrganization: (newOrganization: EsaOrganization | null) => void;
};

export const EsaOrganizationListPresenter: FC<EsaOrganizationListPresenterProps> = memo(
  ({ organizationList, settingOrganizationUid, handleChangeOrganization }) => (
    <Autocomplete
      autoComplete
      autoHighlight
      disableClearable
      closeText="閉じる"
      noOptionsText="候補なし"
      size="small"
      value={organizationList.find(o => o.organizationUid === settingOrganizationUid)}
      renderInput={params => <TextField {...params} variant="outlined" placeholder="組織名を入力" />}
      options={organizationList}
      getOptionLabel={option => option.name}
      onChange={(_, newValue) => handleChangeOrganization(newValue)}
      css={{ width: '200px', background: theme.palette.background.paper }}
    />
  )
);
