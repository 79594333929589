import { extractUTMParams } from '@app/utils';
import { FC } from 'react';
import { Navigate, useLocation, Location } from 'react-router';
import { To, URLSearchParamsInit } from 'react-router-dom';

/**
 * リダイレクトに用いる引数
 *
 * useLocationを用いて分岐することを考慮し、
 */
export type RedirectPageProps = {
  url: (location: Location) => string;
  search?: URLSearchParamsInit;
};

type State = {
  from: Location;
};

/**
 * ログインページにリダイレクトするページ
 *
 */
export const RedirectPage: FC<RedirectPageProps> = props => {
  const location: Location = useLocation();
  const to: To = {
    pathname: props.url(location),
    search: extractUTMParams(location.search),
  };
  const state: State = {
    from: location,
  };
  return <Navigate replace to={to} state={state} />;
};
