import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useDependency } from './DependencyHook';

import { reportError } from '@/ErrorLogger';
import { RouteDefinitions } from '@/Pages/RouteDefinitions';

const PageTitleBaseName = 'いい生活Square';

const ReportPlace = '@/Hooks/Analytics';

export const usePageView = (): void => {
  const location = useLocation();
  const analyticsService = useDependency('analyticsService');
  useEffect(() => {
    const routeDef = Object.values(RouteDefinitions).find(rd => matchPath(rd.path, location.pathname) !== null);

    if (routeDef === undefined) {
      reportError(`未定義のパスがあります: ${location.pathname}`, ReportPlace);
    }

    const title =
      routeDef !== undefined
        ? routeDef.title.length > 0
          ? `${PageTitleBaseName} - ${routeDef.title}`
          : PageTitleBaseName
        : '(不明なページ)';
    (async () => {
      await analyticsService.sendPageView(location.pathname + location.search, title);
    })();
  }, [analyticsService, location.pathname, location.search]);
};

export const useUserLogInTracking = (): void => {
  const analyticsService = useDependency('analyticsService');
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      analyticsService.sendUserAction('', 'ログイン完了');
    }
  }, [analyticsService, isAuthenticated]);
};

export type UserActionTrackFunction = (name: string, description?: string, value?: number | string) => void;
export const useUserActionTracker = (place: string): UserActionTrackFunction => {
  const analyticsService = useDependency('analyticsService');
  return useCallback(
    (name: string, description?: string, value?: number | string) =>
      analyticsService.sendUserAction(place, name, description, value),
    [analyticsService, place]
  );
};
