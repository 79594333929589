import { isNullOrUndefined } from '@app/utils';

export const ServiceConfigure = {
  appVersion: import.meta.env.APP_VERSION,
  isMockAuth: import.meta.env.VITE_USE_MOCK_AUTH?.toLowerCase() === 'true',
  proxyBase: import.meta.env.VITE_PROXY_BASE,
  proxyStage: import.meta.env.VITE_PROXY_STAGE,
  auth0Domain: import.meta.env.VITE_AUTH0_DOMAIN,
  auth0ClientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  auth0Audience: import.meta.env.VITE_AUTH0_AUDIENCE ?? '',
  host: import.meta.env.VITE_HOST,
  auth0RedirectUrl:
    import.meta.env.VITE_HOST === 'localhost' ? 'http://localhost:3000' : `https://${import.meta.env.VITE_HOST}`,
  pathBase: import.meta.env.VITE_PROXY_STAGE + 'proxy',
  sumaiEntryFunctions: import.meta.env.VITE_SUMAI_ENTRY_FUNCTIONS,
  chirashiBase: import.meta.env.VITE_CHIRASHI_BASE ?? import.meta.env.VITE_PROXY_BASE,
  chirashiPath: import.meta.env.VITE_CHIRASHI_PATH ?? '/chirashi/reins',
  oauthDomain: import.meta.env.VITE_OAUTH_DOMAIN,
  oauthRedirect: `${import.meta.env.VITE_FRONTEND_PROTO ?? 'https'}://${import.meta.env.VITE_HOST}${
    isNullOrUndefined(import.meta.env.VITE_FRONTEND_PORT) ? '' : `:${import.meta.env.VITE_FRONTEND_PORT}`
  }/login`,
  eboneApiBaseUrl: import.meta.env.VITE_EBONE_BASE_URL,
  esAccountUrl: import.meta.env.VITE_ES_ACCOUNT,
  esAccountApi: import.meta.env.VITE_ES_ACCOUNT_API ?? '',
  esAuthUrl: import.meta.env.VITE_ES_AUTH_BASE,
  // 内見/申込管理の内見一覧を開くURL (.../signIn?redirect=/reserved?isIDProviderAccount=true)
  esSumaiEntryNaikenUrl: import.meta.env.VITE_SUMAI_ENTRY + '/signIn?redirect=%2Freserved%3FisIDProviderAccount%3Dtrue',
  // 内見/申込管理の申込一覧を開くURL (.../signIn?redirect=/?isIDProviderAccount=true)
  esSumaiEntryMoshikomiUrl: import.meta.env.VITE_SUMAI_ENTRY + '/signIn?redirect=%2F%3FisIDProviderAccount%3Dtrue',
  ebChintaiUrl: import.meta.env.VITE_EB_CHINTAI,
  eboneStatusSource: {
    url: import.meta.env.VITE_EBONE_STATUS_SOURCE ?? '',
    key: import.meta.env.VITE_EBONE_STATUS_SOURCE_KEY ?? '',
  },
  eboneScheduleSource: {
    url: import.meta.env.VITE_EBONE_SCHEDULE_SOURCE ?? '',
    key: import.meta.env.VITE_EBONE_SCHEDULE_SOURCE_KEY ?? '',
  },
  rentStatusSource: import.meta.env.VITE_RENT_STATUS_SOURCE,
  rentScheduleSource: import.meta.env.VITE_RENT_SCHEDULE_SOURCE,
  fullstarUUID: import.meta.env.VITE_FULLSTAR_UUID,
  messengerUrl: import.meta.env.VITE_SQUARE_MESSENGER,
  messengerGraphqlEndpoint: import.meta.env.VITE_GRAPHQL_ENDPOINT,
  devFeatureFlag: import.meta.env.VITE_DEV_FEATURE_FLAG?.toLowerCase() === 'true',
};
