/* eslint-disable @typescript-eslint/naming-convention */
import { ApolloQueryResult } from '@apollo/client';
import { KeyedMutator } from 'swr';

import * as APITypes from '../../../../../graphql-client/src/API';
import { ChannelMyOrganization, ChannelPartnerOrganization } from '../../../../../graphql-client/src/API';

// メッセンジャーアプリへの遷移に必要な型を定義する
export type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export type ApolloQueryResultState<T> = {
  isLoading: boolean;
  error: Error;
  model?: ApolloQueryResult<T> | null;
  mutate: KeyedMutator<ApolloQueryResult<T> | null>;
  isValidating: boolean;
};

export const RelationTypeCollection = {
  partner: 'partner',
  internal: 'internal',
} as const;
export type RelationTypeCollectionType = typeof RelationTypeCollection[keyof typeof RelationTypeCollection];

export type ListChannelsInfoQuery = {
  listChannels?: {
    __typename: 'ChannelConnection';
    items?: Array<{
      __typename: 'Channel';
      channelUid: string;
      latestMessagePostedAt?: string | null;
      latestMessageContent?: string | null;
      isRead?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      updatedAt: string;
      myOrganization: ChannelMyOrganization;
      partnerOrganizations: Array<ChannelPartnerOrganization | null>;
    } | null> | null;
    itemsPerPage: number;
    startIndex: number;
    totalCounts: number;
    nextToken?: string | null;
  } | null;
};

// https://gitlab.com/eseikatsu/es-square/squareMessenger/-/blob/release/20240129/services/infra-dynamic/lib/appsync/schema.graphql?ref_type=headsの内容を参照している
// nested queryの定義は npm run codegenによって自動生成されないので、graphq-clientの方で生成した定義とは別で定義している
export const listChannelsInfo =
  /* GraphQL */ `query ListChannels($relationType: RelationType, $organizationUid: ID, $itemsPerPage: Int, $startIndex: Int, $isRead: Boolean) {
  listChannels(
    relationType: $relationType
    organizationUid: $organizationUid
    itemsPerPage: $itemsPerPage
    startIndex: $startIndex
    isRead: $isRead
  ) {
    items {
      channelUid
      latestMessagePostedAt
      latestMessageContent
      isRead
      active
      createdAt
      updatedAt
      __typename
      myOrganization {
        domainUid
        organizationUid
        domainName
        organizationName
        organizationBusinessName
      }
      partnerOrganizations {
        organizationUid
        domainUid
        domainName
        organizationName
        organizationBusinessName
        detail {
          partnerDomain {
            domainUid
            logoImage {
              fileUid
              url
            }
            name
          }
          partnerOrganization {
            businessName
            denwaList {
              denwaNumber
              denwaNumberShubetsu
            }
            domainUid
            organizationUid
            name
            jusho {
              azaChoume
              cityName
              gaikuGoto
              isDirectInput
              jushoCode
              jushoText
              nokoriJusho
              ooazaTsuushou
              prefectureName
              yubinNumber
            }
            partnerCount
          }
          partnerUid
        }
      }
    }
    itemsPerPage
    startIndex
    totalCounts
    __typename
  }
}
` as GeneratedQuery<APITypes.ListChannelsQueryVariables, ListChannelsInfoQuery>;

export const countUnreadChannels = /* GraphQL */ `query ListChannels($relationType: RelationType, $isRead: Boolean) {
  listChannels(
    relationType: $relationType
    isRead: $isRead
  ) {
    items {
      channelUid
      isRead
      createdAt
      updatedAt
      __typename
    }
    totalCounts
    __typename
  }
}
` as GeneratedQuery<APITypes.ListChannelsQueryVariables, ListChannelsInfoQuery>;
