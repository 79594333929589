import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ChangeEvent, Dispatch, FC, SetStateAction, memo, useCallback, useMemo } from 'react';

import {
  PartnerApplicantsStatusCollection,
  PartnerApplicantsStatusCollectionType,
  PartnerApplicantsTypeCollection,
  PartnerApplicantsTypeCollectionType,
} from '@/Models/Esa/PartnerApplicants';

type PartnerApplicantsStatusSwitchButtonContainerProps = {
  partnerApplicantsType: PartnerApplicantsTypeCollectionType;
  partnerApplicantsStatus: PartnerApplicantsStatusCollectionType;
  setPartnerApplicantsStatus: Dispatch<SetStateAction<PartnerApplicantsStatusCollectionType>>;
};

type PartnerApplicantsStatusLabel = {
  label: string;
  value: PartnerApplicantsStatusCollectionType;
};

const PartnerApplicantsStatusSwitchButtonContainer: FC<PartnerApplicantsStatusSwitchButtonContainerProps> = memo(
  ({ partnerApplicantsType, partnerApplicantsStatus, setPartnerApplicantsStatus }) => {
    const partnerApplicantsStatusLabel: PartnerApplicantsStatusLabel[] = useMemo(() => {
      return [
        {
          label: partnerApplicantsType === PartnerApplicantsTypeCollection.sent ? '申請中' : '承認待',
          value: PartnerApplicantsStatusCollection.standby,
        },
        {
          label: partnerApplicantsType === PartnerApplicantsTypeCollection.sent ? '申請OK' : '承認済',
          value: PartnerApplicantsStatusCollection.approved,
        },
        {
          label: partnerApplicantsType === PartnerApplicantsTypeCollection.sent ? '申請NG' : '却下済',
          value: PartnerApplicantsStatusCollection.rejected,
        },
      ];
    }, [partnerApplicantsType]);

    const handleChangePartnerApplicantsStatus = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setPartnerApplicantsStatus(e.target.value as PartnerApplicantsStatusCollectionType);
      },
      [setPartnerApplicantsStatus]
    );

    return (
      <PartnerApplicantsStatusSwitchButtonPresenter
        partnerApplicantsStatus={partnerApplicantsStatus}
        partnerApplicantsStatusLabel={partnerApplicantsStatusLabel}
        handleChangePartnerApplicantsStatus={handleChangePartnerApplicantsStatus}
      />
    );
  }
);

export { PartnerApplicantsStatusSwitchButtonContainer as PartnerApplicantsStatusSwitchButton };

type PartnerApplicantsStatusSwitchButtonPresenterProps = {
  partnerApplicantsStatus: PartnerApplicantsStatusCollectionType;
  partnerApplicantsStatusLabel: PartnerApplicantsStatusLabel[];
  handleChangePartnerApplicantsStatus: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const PartnerApplicantsStatusSwitchButtonPresenter: FC<PartnerApplicantsStatusSwitchButtonPresenterProps> = ({
  partnerApplicantsStatus,
  partnerApplicantsStatusLabel,
  handleChangePartnerApplicantsStatus,
}) => (
  <RadioGroup
    row
    value={partnerApplicantsStatus}
    onChange={handleChangePartnerApplicantsStatus}
    css={{ paddingLeft: '8px' }}
  >
    {partnerApplicantsStatusLabel.map((p: PartnerApplicantsStatusLabel) => {
      return <FormControlLabel key={p.label} value={p.value} control={<Radio />} label={p.label} />;
    })}
  </RadioGroup>
);
