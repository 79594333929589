import { Address } from '@app/models';
import { css } from '@emotion/react';
import { useIsExtraSmallDevice } from '@lib/components';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import { ArrowLeft, Close, Magnify } from 'mdi-material-ui';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useKey } from 'react-use';

import { AddPartnerOrganizationResult } from './AddPartnerOrganizationResult';
import { AddPartnerOrganizationSearch } from './AddPartnerOrganizationSearch';

import { analyticsPlaceNameBusinessPartnerManagement } from '@/Consts/Values';
import { useUserActionTracker } from '@/Hooks/Analytics';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { Prefecture } from '@/Services/IMasterApiService';

type AddPartnerOrganizationDialogContainerProps = {
  isOpenDialog: boolean;
  closeDialog: () => void;
};

export type AreaItem = {
  pref: Prefecture | null;
  city: Address | null;
};

export type SearchOrganizationQuery = {
  excludeDomainUids: string;
  expandPartnerCount: boolean;
  businessName: string;
  denwaNumber: string;
  area: AreaItem;
};

type Stepper = {
  component: JSX.Element;
};
const StepperProcesses = {
  organizationSearch: 0,
  organizationResult: 1,
} as const;
type StepperTypes = typeof StepperProcesses[keyof typeof StepperProcesses];

const AddPartnerOrganizationDialogContainer: FC<AddPartnerOrganizationDialogContainerProps> = memo(
  ({ isOpenDialog, closeDialog }) => {
    const isExtraSmallDevice = useIsExtraSmallDevice();
    const user = useCurrentUser();
    const tracker = useUserActionTracker(analyticsPlaceNameBusinessPartnerManagement);
    const initialSearchOrganizationQuery: SearchOrganizationQuery = {
      expandPartnerCount: true,
      excludeDomainUids: user.domainUid,
      businessName: '',
      denwaNumber: '',
      area: {
        pref: null,
        city: null,
      },
    };
    const [searchOrganizationQuery, setSearchOrganizationQuery] =
      useState<SearchOrganizationQuery>(initialSearchOrganizationQuery);

    // 検索・結果の2ステップを定義
    const [stepperIndex, setStepperIndex] = useState<StepperTypes>(StepperProcesses.organizationSearch);

    const onClearSearchConditions = () => {
      setSearchOrganizationQuery(initialSearchOrganizationQuery);
    };

    const onMoveToPreviousStep = useCallback(() => {
      setStepperIndex(StepperProcesses.organizationSearch);
    }, []);
    const onMoveToNextStep = useCallback(() => {
      setStepperIndex(StepperProcesses.organizationResult);
      tracker('取引先検索ボタン押下');
    }, [tracker]);

    const [isStopSearch, setIsStopSearch] = useState(false);
    const handleIsInputEdit = useCallback((bool: boolean) => {
      setIsStopSearch(bool);
    }, []);
    useKey(
      'Enter',
      () => {
        if (!isStopSearch) {
          onMoveToNextStep();
        }
      },
      { event: 'keydown' },
      [isStopSearch, onMoveToNextStep]
    );

    const stepperList: Stepper[] = useMemo(
      () => [
        {
          component: (
            <AddPartnerOrganizationSearch
              searchOrganizationQuery={searchOrganizationQuery}
              setSearchOrganizationQuery={setSearchOrganizationQuery}
              handleIsInputEdit={handleIsInputEdit}
            />
          ),
        },
        {
          component: <AddPartnerOrganizationResult searchOrganizationQuery={searchOrganizationQuery} />,
        },
      ],
      [handleIsInputEdit, searchOrganizationQuery]
    );

    return (
      <AddPartnerOrganizationDialogPresenter
        isExtraSmallDevice={isExtraSmallDevice}
        isOpenDialog={isOpenDialog}
        closeDialog={closeDialog}
        stepper={stepperList[stepperIndex]}
        stepperIndex={stepperIndex}
        onMoveToPreviousStep={onMoveToPreviousStep}
        onMoveToNextStep={onMoveToNextStep}
        onClearSearchConditions={onClearSearchConditions}
      />
    );
  }
);

export { AddPartnerOrganizationDialogContainer as AddPartnerOrganizationDialog };

type AddPartnerOrganizationDialogPresenterProps = {
  isExtraSmallDevice: boolean;
  isOpenDialog: boolean;
  closeDialog: () => void;
  stepper: Stepper;
  stepperIndex: StepperTypes;
  onMoveToPreviousStep: () => void;
  onMoveToNextStep: () => void;
  onClearSearchConditions: () => void;
};

const dialogTitleStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '50px',
});

const dialogActionStyle = css({
  justifyContent: 'flex-start',
});

const previousStepButtonStyle = css({
  marginLeft: '16px',
});

export const AddPartnerOrganizationDialogPresenter: FC<AddPartnerOrganizationDialogPresenterProps> = memo(
  ({
    isExtraSmallDevice,
    isOpenDialog,
    closeDialog,
    stepper,
    stepperIndex,
    onMoveToPreviousStep,
    onMoveToNextStep,
    onClearSearchConditions,
  }) => {
    return (
      <Dialog open={isOpenDialog} onClose={closeDialog} maxWidth="md" fullWidth fullScreen={isExtraSmallDevice}>
        <DialogTitle css={dialogTitleStyle}>
          <Box display="flex" alignItems="center" fontWeight="bold">
            {stepperIndex === StepperProcesses.organizationSearch && <Magnify fontSize="small" color="primary" />}
            <Box ml={0.5}>
              {stepperIndex === StepperProcesses.organizationSearch ? '不動産会社組織検索' : '不動産会社組織一覧'}
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {stepperIndex === StepperProcesses.organizationSearch && (
              <Button onClick={onClearSearchConditions}>クリア</Button>
            )}
            <IconButton onClick={closeDialog} size="large">
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>{stepper.component}</DialogContent>
        <Divider />
        {stepperIndex === StepperProcesses.organizationSearch ? (
          <DialogActions>
            <Button variant="contained" onClick={onMoveToNextStep}>
              検索
            </Button>
          </DialogActions>
        ) : (
          <DialogActions css={dialogActionStyle}>
            <Button
              variant="outlined"
              onClick={onMoveToPreviousStep}
              startIcon={<ArrowLeft />}
              css={previousStepButtonStyle}
            >
              検索条件を変更する
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
);
