import { isNotNullOrEmptyString } from '@app/utils';
import { KeyedMutator } from 'swr';

import { useEsaModel } from './useEsaModel';

import { EsaDomain } from '@/Models/Esa/EsaDomain';
import { ServiceConfigure } from '@/Services/Configure';

export type DomainState = {
  isLoading: boolean;
  error: Error | null;
  domain: EsaDomain | null;
  mutate: KeyedMutator<EsaDomain | null>;
};

export const useEsaDomain = (domainUid: string): DomainState => {
  const { isLoading, error, model, mutate } = useEsaModel<EsaDomain | null>({
    api: {
      audience: ServiceConfigure.auth0Audience,
      baseUrl: ServiceConfigure.esAccountApi,
      path: isNotNullOrEmptyString(domainUid) ? `/domains/${domainUid}` : null,
    },
  });

  return {
    isLoading,
    error,
    domain: model,
    mutate,
  };
};
