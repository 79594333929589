import { getViewportHeight, isNotNullOrUndefined } from '@app/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { EsLoading, useOnOffState } from '@lib/components';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import * as Sentry from '@sentry/react';
import { FC, useCallback, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useEffectOnce, useMeasure } from 'react-use';

import { useAnnouncement } from './Announcement/AnnouncementHook';
import { GuidanceAlert } from './Guidance/GuidanceAlert';
import { IntegrationAlert } from './IntegrationAlert';
import { MaintenanceAlert } from './Maintenance/MaintenanceAlert';
import { ReducedLayout } from './ReducedLayout';
import { TopBar } from './Topbar';

import { supportSiteUrl } from '@/Consts/ExternalSiteUrls';
import { useDependency } from '@/Hooks/DependencyHook';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { useEsaDomain } from '@/Hooks/Esa/useEsaDomain';
import { OrganizationMap, useEsaDomainOrganizations } from '@/Hooks/Esa/useEsaDomainOrganizations';
import { useFullstar } from '@/Hooks/Fullstar';
import {
  MaintenanceSchedule,
  useIsOneApiMaintenance,
  useIsRentMaintenance,
  useOneApiMaintenanceInfo,
  useRentMaintenanceInfo,
} from '@/Hooks/MaintenanceInfoHook';
import { useIsSmallDevice } from '@/Hooks/Styles/IsSmallDevice';
import { useCustomerViewStateHooks } from '@/Hooks/customerViewHooks';
import { InvalidUserGuidePage } from '@/Pages/InvalidUserGuidePage';
import { SideMenuContainer } from '@/Pages/bukken/chintai/SideMenu';
import { MaintenanceInfoPage } from '@/Pages/maintenance/MaintenanceInfoPage';
import { ServiceConfigure } from '@/Services/Configure';
import { isEseikatsuDeveloper } from '@/Utils/DeveloperUtils';
import { getAuth0Id } from '@/Utils/EsaUtils';

export type MaintenanceInfo = {
  oneApiSchedule: MaintenanceSchedule | undefined;
  rentSchedule: MaintenanceSchedule | undefined;
};

const DefaultLayout: FC = () => {
  const { logout } = useAuth0();
  const isSmallDevice = useIsSmallDevice();
  const userInfo = useCurrentUser();
  const { domain, isLoading: isDomainLoading } = useEsaDomain(userInfo.domainUid);
  const { organizations } = useEsaDomainOrganizations({
    domainUid: userInfo.domainUid,
  });
  const organizationMap: OrganizationMap = new Map(
    organizations?.map(organization => [organization.organizationUid, organization])
  );
  const defaultOrganization = organizationMap.get(userInfo.defaultOrganizationUid);
  const announcement = useAnnouncement();
  const customerViewStateHooks = useCustomerViewStateHooks();
  const onLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: ServiceConfigure.auth0RedirectUrl } });
  }, [logout]);
  const [isSideMenuOpen, { setTrue: onSideMenuOpen, setFalse: onSideMenuClose }] = useOnOffState(false);
  const [sideMenuRef, sideMenuSize] = useMeasure<HTMLDivElement>();

  // OneAPI、物件検索のメンテナンス状況、メンテナンススケジュールを取得する
  const { data: isRentMaintenance, isLoading: isRentMaintenanceLoading } = useIsRentMaintenance();
  const { data: isOneApiMaintenance, isLoading: isOneApiMaintenanceLoading } = useIsOneApiMaintenance();
  const { data: oneApiSchedule, isLoading: isOneApiMaintenanceInfoLoading } = useOneApiMaintenanceInfo();
  const { data: rentSchedule, isLoading: isRentMaintenanceInfoLoading } = useRentMaintenanceInfo();

  const isMaintLoading =
    isRentMaintenanceLoading ||
    isOneApiMaintenanceLoading ||
    isOneApiMaintenanceInfoLoading ||
    isRentMaintenanceInfoLoading;
  const maintenanceInfo = { oneApiSchedule: oneApiSchedule?.prd, rentSchedule: rentSchedule };

  // メンテナンス中にアプリを操作できる権限を持つかを判定する
  const canOperateDuringMaintenance = isEseikatsuDeveloper(userInfo.email) && userInfo.role.isAdmin;

  const userId = getAuth0Id(userInfo);
  const DefaultConfig = {
    transport_type: 'beacon',
  } as const;
  gtag('config', import.meta.env.VITE_GTAG_ID, {
    ...DefaultConfig,
    user_id: userId,
  });

  const conditionStorageService = useDependency('conditionStorageService');

  useEffectOnce(() => {
    (async () => {
      await conditionStorageService.restoreConditions();
    })();
  });

  // 初回レンダリング時に isSmallDevice の結果が揺れる事象が起こるため、
  // isSmallDevice の結果が変わった場合は明示的に開閉状態を操作する
  useEffect(() => {
    isSmallDevice ? onSideMenuClose() : onSideMenuOpen();
    Sentry.setUser({email: userInfo.email ?? ''});
  }, [
    isSmallDevice,
    onSideMenuClose,
    onSideMenuOpen,
    userInfo.email,
  ]);

  // Fullstarのパラメータを設定
  useFullstar();

  if (isMaintLoading) {
    return (
      <Box height={getViewportHeight(100)} width="100%" overflow="hidden">
        <EsLoading />
      </Box>
    );
  }

  // ライセンスを持つアカウント or 宅建免許番号を持つアカウント 以外は利用不可
  if (!isDomainLoading && !domain?.takkenLicense && !domain?.ebone) {
    return (
      <ReducedLayout>
        <InvalidUserGuidePage />
      </ReducedLayout>
    );
  }

  if (isRentMaintenance && isRentMaintenance.inMaintenance && !canOperateDuringMaintenance) {
    return (
      <ReducedLayout>
        <MaintenanceInfoPage maintenanceSchedule={rentSchedule} />
      </ReducedLayout>
    );
  }

  if (isOneApiMaintenance && isOneApiMaintenance.prd.isMaintenance) {
    return (
      <ReducedLayout>
        <MaintenanceInfoPage maintenanceSchedule={oneApiSchedule?.prd} />
      </ReducedLayout>
    );
  }

  return (
    <Box display="flex" flexDirection="column" height={getViewportHeight(100)}>
      <IntegrationAlert />
      <TopBar
        isSideMenuOpen={isSideMenuOpen}
        onSideMenuOpen={onSideMenuOpen}
        onSideMenuClose={onSideMenuClose}
        isCustomerView={customerViewStateHooks.isCustomerView}
        isMainFeatureAvailable={true}
        accountUrl={new URL('profile/edit', ServiceConfigure.esAccountUrl).toString()}
        supportSiteUrl={supportSiteUrl}
        sumaiEntryNaikenUrl={ServiceConfigure.esSumaiEntryNaikenUrl}
        sumaiEntryMoshikomiUrl={ServiceConfigure.esSumaiEntryMoshikomiUrl}
        onLogout={onLogout}
        userInfo={userInfo}
        domainName={domain?.name ?? ''}
        sideMenu={<SideMenuContainer onItemClick={onSideMenuClose} domainUid={userInfo.domainUid} />}
        useAnnouncement={announcement}
      />
      <Box flexGrow="1" overflow="hidden hidden" display="flex" height="100%">
        {!isSmallDevice && (
          <Box
            {...{ ref: sideMenuRef }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
              position: 'sticky',
              top: 0,
              maxWidth: '15vw',
              minWidth: '15rem',
              transitionDuration: '225ms',
              transitionTimingFunction: 'ease',
              background: theme => theme.palette.secondary.main,
              marginLeft: isSideMenuOpen
                ? '0'
                : isNotNullOrUndefined(sideMenuSize.width)
                ? `-${sideMenuSize.width}px`
                : '0',
              scrollbarWidth: 'thin',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-track': {
                background: theme => theme.palette.background.paper,
              },
              '&::-webkit-scrollbar-thumb': {
                background: grey[400],
                borderRadius: '10px',
                boxShadow: theme => `inset 0 0 0 2px ${theme.palette.background.paper}`,
              },
            }}
          >
            <SideMenuContainer domainUid={userInfo.domainUid} />
          </Box>
        )}
        <Box flexGrow={1} maxWidth="100%" overflow="hidden auto">
          <MaintenanceAlert maintenanceInfo={maintenanceInfo} />
          {defaultOrganization?.kameiDantaiList.length === 0 && <GuidanceAlert />}
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default DefaultLayout;
