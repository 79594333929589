import { ApolloClient, ApolloQueryResult, InMemoryCache, gql } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import useSWR from 'swr';

import * as APITypes from '../../../../../graphql-client/src/API';

import {
  ApolloQueryResultState,
  GeneratedQuery,
  ListChannelsInfoQuery,
  listChannelsInfo,
  countUnreadChannels,
  RelationTypeCollectionType,
} from '@/Models/Messenger/Graphql';
import { ServiceConfigure } from '@/Services/Configure';

type ChannelsQuery = {
  itemsPerPage?: number;
  startIndex?: number;
  organizationUid?: string;
  organizationGuid?: string;
  isRead?: boolean;
  countOnly?: boolean;
  isNotRequest?: boolean;
  relationType?: RelationTypeCollectionType;
};

const DEFAULT_START_INDEX = 1;
const DEFAULT_PER_PAGE = 10;
const getChannelsQueryParams = (channelsQuery: ChannelsQuery): URLSearchParams => {
  const params = new URLSearchParams();
  const startIndex = channelsQuery.startIndex ?? DEFAULT_START_INDEX;
  params.append('startIndex', startIndex.toString());
  const itemsPerPage = channelsQuery.itemsPerPage ?? DEFAULT_PER_PAGE;
  params.append('itemsPerPage', itemsPerPage.toString());
  if (channelsQuery?.organizationUid) {
    params.append('organizationUid', channelsQuery.organizationUid ?? '');
  }
  return params;
};

export const useChannelsQuery = (): {
  channelsQuery: ChannelsQuery;
  setChannelsQuery: (params: ChannelsQuery) => void;
} => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getChannelsQuery = (): ChannelsQuery => {
    const startIndex = searchParams.get('startIndex');
    const itemsPerPage = searchParams.get('itemsPerPage');
    const organizationUid = searchParams.get('organizationUid');
    const organizationGuid = searchParams.get('organizationGuid');

    return {
      startIndex: startIndex ? parseInt(startIndex) || 0 : DEFAULT_START_INDEX,
      itemsPerPage: itemsPerPage ? parseInt(itemsPerPage) || 0 : DEFAULT_PER_PAGE,
      organizationUid: organizationUid ?? '',
      organizationGuid: organizationGuid ?? '',
    };
  };

  const [channelsQuery, setChannelsQuery] = useState<ChannelsQuery>(getChannelsQuery());

  useEffect(() => {
    const params = new URLSearchParams();

    searchParams.forEach((key, value) => params.set(value, key));
    getChannelsQueryParams(channelsQuery).forEach((key, value) => params.set(value, key));

    if (channelsQuery?.organizationGuid) {
      params.delete('organizationGuid');
    }
    if (channelsQuery?.organizationUid === undefined) {
      params.delete('organizationUid');
    }

    setSearchParams(params);
  }, [setSearchParams, channelsQuery, searchParams]);

  return {
    channelsQuery,
    setChannelsQuery,
  };
};

export const useChannels = (channelsQuery: ChannelsQuery): ApolloQueryResultState<ListChannelsInfoQuery> => {
  const client = new ApolloClient({
    uri: ServiceConfigure.messengerGraphqlEndpoint,
    // TODO #721 InMemoryCacheの挙動を調査する
    cache: new InMemoryCache({ resultCaching: false }),
  });

  const { getAccessTokenSilently } = useAuth0();
  const { value: token, loading: isTokenLoading } = useAsync(() =>
    getAccessTokenSilently({
      authorizationParams: {
        audience: ServiceConfigure.auth0Audience,
      },
    })
  );

  const fetcher = ([
    listChannelsInfo,
    countUnreadChannels,
    relationType,
    organizationUid,
    itemsPerPage,
    startIndex,
    isRead,
    countOnly,
  ]: [
    GeneratedQuery<APITypes.ListChannelsQueryVariables, ListChannelsInfoQuery>,
    GeneratedQuery<APITypes.ListChannelsQueryVariables, ListChannelsInfoQuery>,
    RelationTypeCollectionType,
    string | undefined,
    number | undefined,
    number | undefined,
    boolean | undefined,
    boolean | undefined
  ]): Promise<ApolloQueryResult<ListChannelsInfoQuery> | null> => {
    return client.query<ListChannelsInfoQuery>({
      query: gql`
        ${countOnly ? countUnreadChannels : listChannelsInfo}
      `,
      variables: {
        relationType: relationType,
        organizationUid: organizationUid === '' ? undefined : organizationUid,
        startIndex: startIndex,
        itemsPerPage: itemsPerPage,
        isRead: isRead,
      },
      context: {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    });
  };

  const { data, error, mutate, isValidating, isLoading } = useSWR(
    token === null || isTokenLoading || channelsQuery.isNotRequest === false
      ? null
      : [
          listChannelsInfo,
          countUnreadChannels,
          channelsQuery.relationType,
          channelsQuery.organizationUid,
          channelsQuery.itemsPerPage,
          channelsQuery.startIndex,
          channelsQuery.isRead,
          channelsQuery.countOnly,
        ],
    fetcher,
    {
      revalidateOnFocus: false,
      refreshInterval: 1000 * 30,
    }
  );

  return {
    isLoading,
    error,
    model: data,
    mutate,
    isValidating,
  };
};
