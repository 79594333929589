import { TextField } from '@e-seikatsu/design-system';
import { css } from '@emotion/react';
import { useIsExtraSmallDevice } from '@lib/components';
import { Box, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Dispatch, FC, SetStateAction, memo, useCallback } from 'react';

import { AreaItem, SearchOrganizationQuery } from './AddPartnerOrganizationDialog';
import { PartnerCitySelector } from './PartnerCitySelector';
import { PartnerPrefectureSelector } from './PartnerPrefectureSelector';

import { theme } from '@/Theme';

type AddPartnerOrganizationSearchContainerProps = {
  searchOrganizationQuery: SearchOrganizationQuery;
  setSearchOrganizationQuery: Dispatch<SetStateAction<SearchOrganizationQuery>>;
  handleIsInputEdit: (bool: boolean) => void;
};

const AddPartnerOrganizationSearchContainer: FC<AddPartnerOrganizationSearchContainerProps> = memo(
  ({ searchOrganizationQuery, setSearchOrganizationQuery, handleIsInputEdit }) => {
    const isExtraSmallDevice = useIsExtraSmallDevice();

    const handleChangeDomainName = useCallback(
      (value: string) => {
        setSearchOrganizationQuery({ ...searchOrganizationQuery, businessName: value });
      },
      [searchOrganizationQuery, setSearchOrganizationQuery]
    );

    const handleChangeDenwaNumber = useCallback(
      (value: string) => {
        setSearchOrganizationQuery({ ...searchOrganizationQuery, denwaNumber: value });
      },
      [searchOrganizationQuery, setSearchOrganizationQuery]
    );

    const handleChangeArea = useCallback(
      (value: AreaItem) => {
        setSearchOrganizationQuery({ ...searchOrganizationQuery, area: value });
      },
      [searchOrganizationQuery, setSearchOrganizationQuery]
    );

    return (
      <AddPartnerOrganizationSearchPresenter
        isExtraSmallDevice={isExtraSmallDevice}
        searchOrganizationQuery={searchOrganizationQuery}
        handleChangeDomainName={handleChangeDomainName}
        handleChangeDenwaNumber={handleChangeDenwaNumber}
        handleIsInputEdit={handleIsInputEdit}
        handleChangeArea={handleChangeArea}
      />
    );
  }
);

export { AddPartnerOrganizationSearchContainer as AddPartnerOrganizationSearch };

type AddPartnerOrganizationSearchPresenterProps = {
  isExtraSmallDevice: boolean;
  searchOrganizationQuery: SearchOrganizationQuery;
  handleChangeDomainName: (value: string) => void;
  handleChangeDenwaNumber: (value: string) => void;
  handleChangeArea: (value: AreaItem) => void;
  handleIsInputEdit: (bool: boolean) => void;
};

const searchFormStyle = css({
  '& > *': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    borderBottom: `solid 1px ${grey[200]}`,
    padding: '16px',
  },

  '&:first-child': {
    borderTop: `solid 1px ${grey[200]}`,
  },
});

const areaFormStyle = css({
  display: 'flex',
  gap: 8,
});

const areaFormMobileStyle = css({
  rowGap: 1,
});

export const AddPartnerOrganizationSearchPresenter: FC<AddPartnerOrganizationSearchPresenterProps> = memo(
  ({
    isExtraSmallDevice,
    searchOrganizationQuery,
    handleChangeDomainName,
    handleChangeDenwaNumber,
    handleChangeArea,
    handleIsInputEdit,
  }) => {
    return (
      <>
        <Box>検索条件を入力してください</Box>
        <Grid item xs={12}>
          <Grid container css={searchFormStyle}>
            <Grid item css={[{ backgroundColor: theme.palette.secondary.light }]} xs={12} md={4}>
              <Typography fontWeight="bold">会社名</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                length="full"
                placeholder="会社名を入力してください"
                value={searchOrganizationQuery.businessName}
                onChange={e => handleChangeDomainName(e.target.value)}
                autoFocus
                onCompositionStart={() => handleIsInputEdit(true)}
                onCompositionEnd={() => handleIsInputEdit(false)}
              />
            </Grid>
            <Grid item css={[{ backgroundColor: theme.palette.secondary.light }]} xs={12} md={4}>
              <Typography fontWeight="bold">電話</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                length="full"
                placeholder={
                  isExtraSmallDevice ? '電話を入力してください' : '電話を入力してください（例：012-345-678）'
                }
                helperText="ハイフンありで入力してください"
                value={searchOrganizationQuery.denwaNumber}
                onChange={e => handleChangeDenwaNumber(e.target.value)}
                onCompositionStart={() => handleIsInputEdit(true)}
                onCompositionEnd={() => handleIsInputEdit(false)}
              />
            </Grid>
            <Grid item css={[{ backgroundColor: theme.palette.secondary.light }]} xs={12} md={4}>
              <Typography fontWeight="bold">エリア</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box css={isExtraSmallDevice ? areaFormMobileStyle : areaFormStyle}>
                <PartnerPrefectureSelector
                  currentPref={searchOrganizationQuery.area.pref}
                  onChange={handleChangeArea}
                  handleIsInputEdit={handleIsInputEdit}
                />
                <PartnerCitySelector
                  currentPref={searchOrganizationQuery.area.pref}
                  currentCity={searchOrganizationQuery.area.city}
                  onChange={handleChangeArea}
                  handleIsInputEdit={handleIsInputEdit}
                  prefCode={searchOrganizationQuery.area.pref?.code ?? 0}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
);
