import { AuthenticationNotGetTokenError } from '@app/errors';
import { KeyedMutator } from 'swr';
import useSWRInfinite from 'swr/infinite';

import { useEsaApiClient } from '@/Services/EsaApiService';

export type ListItemResponse<T> = {
  items: T[];
};
const fetchedAll = <T>(data: Array<ListItemResponse<T>>, itemsPerPage: number): boolean => {
  const isEmpty = data[0].items.length === 0;

  if (isEmpty) {
    return true;
  }

  return data[data.length - 1].items.length < itemsPerPage;
};

const collectResults = <T>(data: Array<ListItemResponse<T>>): T[] => {
  let items: T[] = [];
  data.forEach(d => {
    items = [...items, ...d.items];
  });
  return items;
};

type Props = {
  api: {
    audience: string;
    baseUrl: string;
    path: string | null;
  };
};

export type UseEsaModelsProps = {
  startIndex?: number;
  itemsPerPage?: number;
  initialSize?: number;
  lazyLoad?: boolean;
  allFetch?: boolean;
  shouldFetch?: boolean;
};

export type LoadingModelsState<T> = {
  isLoading: true;
  error: null;
  mutate: KeyedMutator<ListItemResponse<T>[]>;
  isValidating: boolean;
  size: number;
  setSize: (size: number) => void;
};

export type ErrorModelsState<T> = {
  isLoading: false;
  error: Error;
  mutate: KeyedMutator<ListItemResponse<T>[]>;
  isValidating: boolean;
  size: number;
  setSize: (size: number) => void;
};

export type LoadedModelsState<T> = {
  isLoading: false;
  error: null;
  mutate: KeyedMutator<ListItemResponse<T>[]>;
  isValidating: boolean;
  size: number;
  setSize: (size: number) => void;
};

export type ModelsState<T> =
  | (LoadingModelsState<T> & { models: null })
  | (ErrorModelsState<T> & { models: null })
  | (LoadedModelsState<T> & { models: T[] });

// 50ならばほとんど全てのケースで1リクエストで全件取得できる
// 表示側のページネーションは表示側の責務
// 逆にパフォーマンスが落ちる or 負荷が高すぎるならば調整を検討する
const DEFAULT_FETCH_ITEMS = 50 as const;

export const useEsaModels = <T>({
  api: { audience, baseUrl, path },
  startIndex = 1,
  itemsPerPage = DEFAULT_FETCH_ITEMS,
  initialSize = 1,
  lazyLoad = true,
  shouldFetch = true,
}: Props & UseEsaModelsProps): ModelsState<T> => {
  const {
    isLoading,
    apiClient,
    error: authError,
  } = useEsaApiClient({
    audience,
    baseUrl,
  });

  const getKey = (pageIndex: number, previousPageData: ListItemResponse<T>): string | null => {
    if (!shouldFetch || !path) {
      return null;
    }
    if (isLoading) {
      return null;
    }
    if (previousPageData && !previousPageData.items) {
      return null;
    }
    return `${path}?startIndex=${startIndex + pageIndex * itemsPerPage}&itemsPerPage=${itemsPerPage}`;
  };

  const { data, error, mutate, isValidating, size, setSize } = useSWRInfinite(
    getKey,
    pathname => {
      if (authError) {
        // 認証で既にエラーを返していたら引き継ぐ
        throw authError;
      }
      if (!apiClient) {
        // isLoading = false なので到達しないはず。SWR が型推論を破壊している
        throw new Error('Unreachable code');
      }
      return apiClient.get<ListItemResponse<T>>({ path: pathname });
    },
    {
      initialSize,
      revalidateOnFocus: false,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // ログイン画面での無駄なリクエスト発生を防ぐため
        if (error instanceof AuthenticationNotGetTokenError) {
          return;
        }
        // 5秒後に再試行します。
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
    }
  );

  if (!shouldFetch) {
    return {
      isLoading: false,
      error: null,
      models: [],
      mutate,
      isValidating,
      size,
      setSize,
    };
  }

  if (error) {
    return {
      isLoading: false,
      error,
      models: null,
      mutate,
      isValidating,
      size,
      setSize,
    };
  }

  if (!data) {
    return {
      isLoading: true,
      error: null,
      models: null,
      mutate,
      isValidating,
      size,
      setSize,
    };
  }

  if (!lazyLoad && !fetchedAll(data, itemsPerPage)) {
    return {
      isLoading: true,
      error: null,
      models: null,
      mutate,
      isValidating,
      size,
      setSize,
    };
  }

  return {
    isLoading: false,
    error: null,
    models: collectResults(data),
    mutate,
    isValidating,
    size,
    setSize,
  };
};
