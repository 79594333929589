import { useCallback, useState } from 'react';
export const usePopoverDisplayState = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = useCallback((target) => setAnchorEl(target), []);
    const close = useCallback(() => setAnchorEl(null), []);
    return {
        anchorEl,
        open,
        close,
    };
};
