import { getViewportHeight } from '@app/utils';
import { SerializedStyles, css } from '@emotion/react';
import { Loading } from '@lib/components';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { FC, Fragment } from 'react';

import { Channel } from '../../../../../graphql-client/src/API';

import { EmptyPartnerOrganization } from './EmptyPartnerOrganization';
import { PartnerOrganizationItem } from './PartnerOrganizationItem';

import { EsaPublicDomain } from '@/Models/Esa/EsaDomain';
import { EsaOrganization, EsaPublicOrganization } from '@/Models/Esa/EsaOrganization';

type PartnerOrganizationListContainerProps = {
  channelList: (Channel | null)[];
  isChannelLoading: boolean;
  userOrganizations: EsaOrganization[];
};

const PartnerOrganizationListContainer: FC<PartnerOrganizationListContainerProps> = ({
  channelList,
  isChannelLoading,
  userOrganizations,
}) => {
  if (isChannelLoading) {
    return (
      <Box height={getViewportHeight(75)} width="100%" overflow="hidden">
        <Loading />
      </Box>
    );
  }

  if (channelList?.length === 0) {
    return <EmptyPartnerOrganization />;
  }

  return <PartnerOrganizationListPresenter channelList={channelList ?? []} userOrganizations={userOrganizations} />;
};

export { PartnerOrganizationListContainer as PartnerOrganizationList };

type PartnerOrganizationListPresenterProps = {
  channelList: (Channel | null)[];
  userOrganizations: EsaOrganization[];
};

export const paginationStyle = (): SerializedStyles =>
  css({
    '& .MuiTablePagination-toolbar': {
      flexDirection: 'row-reverse',
      paddingLeft: '8px',
    },
    '& .MuiTablePagination-select': {
      backgroundColor: 'white',
      border: `1px solid ${grey[300]}`,
      borderRadius: '4px',
      padding: '8px',
    },
  });

export const PartnerOrganizationListPresenter: FC<PartnerOrganizationListPresenterProps> = ({
  channelList,
  userOrganizations,
}) => (
  <Fragment>
    {channelList.map((channel: Channel | null) => {
      return (
        // コンポーネントの再利用性、型がほぼ同じであることを考慮し、asを許容する
        <PartnerOrganizationItem
          key={channel?.channelUid}
          domain={channel?.partnerOrganizations?.[0]?.detail?.partnerDomain as EsaPublicDomain}
          organization={channel?.partnerOrganizations?.[0]?.detail?.partnerOrganization as EsaPublicOrganization}
          channelUid={channel?.channelUid}
          channelMyOrganization={channel?.myOrganization}
          channelPartnerOrganization={channel?.partnerOrganizations?.[0]}
          isRead={channel?.isRead}
          active={channel?.active}
          userOrganizations={userOrganizations}
          partnerUid={channel?.partnerOrganizations?.[0]?.detail?.partnerUid ?? ''}
        />
      );
    })}
  </Fragment>
);
