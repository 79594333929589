import { Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

export type LabelColors = 'normal' | 'caution' | 'attention' | 'grey';
export const labelColorSxProps: Record<LabelColors, SystemStyleObject<Theme>> = {
  normal: {
    color: '#00A892',
    backgroundColor: '#E6F6F4',
  },
  caution: {
    color: '#E7421E',
    backgroundColor: '#FDECE8',
  },
  attention: {
    color: 'white',
    backgroundColor: theme => theme.palette.attention.main,
  },
  grey: {
    color: theme => theme.palette.text.primary,
    backgroundColor: grey[300],
  },
};

const favoriteColor = '#E3B309';
const favoriteColorDarken = '#D4A708';
type FavoriteColorSxPropsKeys = 'font' | 'border' | 'background' | 'backgroundFocused';
export const favoriteColorSxProps: Record<FavoriteColorSxPropsKeys, SystemStyleObject<Theme>> = {
  font: {
    color: favoriteColor,
  },
  border: {
    borderColor: favoriteColor,
  },
  background: {
    background: favoriteColor,
  },
  backgroundFocused: {
    '&:hover': {
      background: favoriteColorDarken,
    },
  },
};

type TextColorSxPropsKeys = 'title';
export const textColorSxProps: Record<TextColorSxPropsKeys, SystemStyleObject<Theme>> = {
  title: {
    color: '#142C62',
  },
};
