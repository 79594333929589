import { css } from '@emotion/react';
import { useIsExtraSmallDevice } from '@lib/components';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Close } from 'mdi-material-ui';
import { FC, memo, useCallback, useState } from 'react';
import { mutate } from 'swr';

import { useCancelPartnerApplicants } from '@/Hooks/Esa/usePartnerApplicants';

type PartnerApplicantsCancelDialogContainerProps = {
  isOpenCancelDialog: boolean;
  closeCancelDialog: () => void;
  closeDetailDialog: () => void;
  partnerApplicantsUid: string;
};

const PartnerApplicantsCancelDialogContainer: FC<PartnerApplicantsCancelDialogContainerProps> = memo(
  ({ isOpenCancelDialog, closeCancelDialog, closeDetailDialog, partnerApplicantsUid }) => {
    const isExtraSmallDevice = useIsExtraSmallDevice();
    const { cancelPartnerApplicants } = useCancelPartnerApplicants();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleCancelPartnerApplicants = useCallback(async () => {
      setIsLoading(true);
      await cancelPartnerApplicants(partnerApplicantsUid, { cancellationComment: '' });
      setIsLoading(false);
      closeCancelDialog();
      closeDetailDialog();
      // GraphQL を考慮すると、key に string 以外の型も入るので型チェックを行う
      await mutate(key => typeof key === 'string' && key.startsWith('/partner_applicants'));
    }, [cancelPartnerApplicants, closeCancelDialog, closeDetailDialog, partnerApplicantsUid]);

    return (
      <PartnerApplicantsCancelDialogPresenter
        isExtraSmallDevice={isExtraSmallDevice}
        isOpenCancelDialog={isOpenCancelDialog}
        closeCancelDialog={closeCancelDialog}
        isLoading={isLoading}
        handleCancelPartnerApplicants={handleCancelPartnerApplicants}
      />
    );
  }
);

export { PartnerApplicantsCancelDialogContainer as PartnerApplicantsCancelDialog };

type PartnerApplicantsCancelDialogPresenterProps = {
  isExtraSmallDevice: boolean;
  isOpenCancelDialog: boolean;
  closeCancelDialog: () => void;
  isLoading: boolean;
  handleCancelPartnerApplicants: () => Promise<void>;
};

const dialogTitleStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '50px',
});

const dialogActionStyle = css({
  textAlign: 'right',
});

export const PartnerApplicantsCancelDialogPresenter: FC<PartnerApplicantsCancelDialogPresenterProps> = memo(
  ({ isExtraSmallDevice, isOpenCancelDialog, closeCancelDialog, isLoading, handleCancelPartnerApplicants }) => {
    return (
      <Dialog open={isOpenCancelDialog} maxWidth="sm" fullWidth fullScreen={isExtraSmallDevice}>
        <DialogTitle css={dialogTitleStyle}>
          <Box display="flex" alignItems="center" fontWeight="bold">
            申請取消
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <IconButton onClick={closeCancelDialog} size="large">
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>取引先申請を本当に取り消してよろしいですか？</DialogContent>
        <Divider />
        <DialogActions css={dialogActionStyle}>
          <Button onClick={closeCancelDialog} css={{ color: grey[500] }}>
            キャンセル
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            loading={isLoading}
            css={{ marginRight: '8px' }}
            onClick={handleCancelPartnerApplicants}
          >
            申請を取り消す
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
);
