import { InvalidStateError } from '@app/errors';
import { isNullOrUndefined } from '@app/utils';
import { useIsSmallDevice, textSxProps } from '@lib/components';
import { Box, Button } from '@mui/material';
import { FC, ReactNode, createContext, memo, useContext, useEffect, useMemo, useRef } from 'react';
import useSWR from 'swr';

import { useEnqueueSnackbar } from '@/Hooks/Snackbar';
import { ServiceConfigure } from '@/Services/Configure';

type AppMetadata = { version: string; forceReload: boolean };
const updateIntervalMinutes = 5;

const fetcher =
  <T,>() =>
  async (url: string): Promise<T | null> =>
    ServiceConfigure.host === 'localhost' ? null : (await fetch(url)).json();

type ReloadNotifierHook = { isOldVersion: boolean; isForceReloadVersion: boolean };

const useReloadNotifier = (): ReloadNotifierHook => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const isShown = useRef(false);

  const {
    data: metadata,
    error,
    isLoading,
  } = useSWR(
    `/metadata.json`,
    async (url: string) => {
      return fetcher<AppMetadata>()(url);
    },
    {
      refreshInterval: 1000 * 60 * updateIntervalMinutes,
    }
  );

  /**
   * isOldVersion: バージョンが異なる場合は、常に再読み込みすべき古いバージョンを使っていると判定
   * isForceReloadVersion: isOldVersion かつ強制リロードフラグがあれば、強制的なリロードが必要と判定
   */

  const isOldVersion = useMemo(
    () =>
      isNullOrUndefined(error) && !isLoading && metadata !== null && metadata?.version !== ServiceConfigure.appVersion,
    [error, isLoading, metadata]
  );
  const isForceReloadVersion = useMemo(
    () => isOldVersion && (metadata?.forceReload ?? false),
    [isOldVersion, metadata?.forceReload]
  );

  useEffect(() => {
    if (isShown.current) {
      return;
    }
    if (isForceReloadVersion) {
      enqueueSnackbar(
        <ReloadNotifierSnackbar message={`${Math.floor(updateIntervalMinutes)}分後に画面を更新します。`} />,
        {
          persist: true,
          variant: 'error',
        },
        { hideIcon: true, hideCloseButton: true }
      );
      isShown.current = true;
      setInterval(() => window.location.reload(), 1000 * 60 * updateIntervalMinutes);
      return;
    }
    if (isOldVersion) {
      enqueueSnackbar(<ReloadNotifierSnackbar message="画面を更新してください。" />, {
        persist: true,
        variant: 'info',
        onClose: () => {
          isShown.current = false;
        },
      });
      isShown.current = true;
      return;
    }
  }, [enqueueSnackbar, isForceReloadVersion, isOldVersion]);

  return { isOldVersion, isForceReloadVersion };
};

const ReloadNotifierContext = createContext<ReloadNotifierHook | undefined>(undefined);

export const ReloadNotifierProvider: FC<{ children: ReactNode }> = memo(({ children }) => (
  <ReloadNotifierContext.Provider value={useReloadNotifier()}>{children}</ReloadNotifierContext.Provider>
));

export const useReloadNotifierContext = (): ReloadNotifierHook => {
  const ctx = useContext(ReloadNotifierContext);
  if (isNullOrUndefined(ctx)) {
    throw new InvalidStateError('ReloadNotifierContext が提供されていない状態で使用しようとしました');
  }
  return ctx;
};

const ReloadNotifierSnackbar: FC<{
  message: string;
}> = ({ message }) => {
  const isSmallDevice = useIsSmallDevice();

  return (
    <Box display="flex" alignItems="center" flexDirection={isSmallDevice ? 'column' : 'row'} columnGap={3} rowGap={1}>
      <Box>
        <Box>「いい生活Square物件検索」最新版が公開されました。{message}</Box>
        <Box sx={textSxProps.bold}>未保存のデータがある場合は破棄されますのでご注意ください。</Box>
      </Box>
      <Button color="inherit" size="small" variant="outlined" onClick={() => window.location.reload()}>
        更新
      </Button>
    </Box>
  );
};
