var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Badge as MuiBadge, badgeClasses } from '@mui/material';
export const BadgeColors = ['attention'];
const badgeColorSxProps = {
    attention: {
        backgroundColor: 'attention.main',
    },
};
export const Badge = (_a) => {
    var { color, children } = _a, muiProps = __rest(_a, ["color", "children"]);
    const badgeColorSx = badgeColorSxProps[color];
    return (_jsx(MuiBadge, Object.assign({}, muiProps, { sx: {
            [`& .${badgeClasses.badge}`]: badgeColorSx,
            color: 'white',
        } }, { children: children })));
};
