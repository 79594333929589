import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { deepCompare } from '@app/utils';
import { IconButton, Popover, popoverClasses, styled } from '@mui/material';
import { Bell } from 'mdi-material-ui';
import { memo, useCallback, useState, Fragment } from 'react';
import { Badge } from '../Colored';
import { usePopoverDisplayState } from '../Hooks';
import { useIsExtraSmallDevice } from '../Styles';
import { AnnouncementContents } from './AnnouncementContents';
const AnnouncementTriggerPopover = styled(Popover)({
    [`& .${popoverClasses.paper}`]: {
        width: '37.5rem',
        height: '25rem',
    },
});
export const AnnouncementButton = memo(({ useAnnouncement }) => {
    const [buttonRef, setButtonRef] = useState(null);
    const { open: openPopoverRaw, close: closePopover, anchorEl } = usePopoverDisplayState();
    const openPopover = useCallback(() => {
        if (buttonRef !== null) {
            openPopoverRaw(buttonRef);
            useAnnouncement.markAsRead();
        }
    }, [buttonRef, openPopoverRaw, useAnnouncement]);
    //モバイル端末でアクセス時にボタンのサイズを変更するために使用する
    //可能な限りボタンのサイズを変更したくない(大きい方が良い)ため、画面のサイズの判定がより狭いExtraを使用する
    const isSmallDevice = useIsExtraSmallDevice();
    return (_jsxs(Fragment, { children: [_jsx(IconButton, Object.assign({ onClick: openPopover, ref: setButtonRef, color: "inherit", size: "large", css: isSmallDevice ? { width: '40px', height: '40px', p: 1 } : {} }, { children: _jsx(Badge, Object.assign({ color: "attention", invisible: !useAnnouncement.isUnreadNewsExists, variant: "dot", overlap: "circular" }, { children: _jsx(Bell, { sx: { fontSize: '24px', color: 'text.secondary' } }) })) })), _jsx(AnnouncementTriggerPopover, Object.assign({ anchorEl: anchorEl, onClose: closePopover, open: anchorEl !== null, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                } }, { children: _jsx(AnnouncementContents, { isLoading: useAnnouncement.isLoading, newsData: useAnnouncement.newsData }) }))] }));
}, deepCompare);
