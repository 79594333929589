import { textSxProps } from '@lib/components';
import { Box, Typography } from '@mui/material';
import { FC, memo } from 'react';

import { PartnerApplicantsPage } from '@/Components/Partner/Applicants/PartnerApplicantsPage';
import { textColorSxProps } from '@/Hooks/Styles/ColorStyle';
import { PartnerApplicantsTypeCollection } from '@/Models/Esa/PartnerApplicants';

const PartnerApplicantsReceivedPageContainer: FC = memo(() => {
  return <PartnerApplicantsReceivedPagePresenter />;
});

export { PartnerApplicantsReceivedPageContainer as PartnerApplicantsReceivedPage };

export const PartnerApplicantsReceivedPagePresenter: FC = memo(() => (
  <Box display="flex" flexDirection="column" sx={{ minHeight: '100%' }}>
    <Box width="100%" pt={3}>
      <Typography mb={3} mx={2} sx={{ ...textColorSxProps.title, ...textSxProps.bold, ...textSxProps.xl }}>
        取引先管理 ＞ 受信した申請
      </Typography>
      <PartnerApplicantsPage partnerApplicantsType={PartnerApplicantsTypeCollection.received} />
    </Box>
  </Box>
));
