import { Datejun } from '@app/models';
import { formatRFC3339, startOfDay, sub } from 'date-fns';

import { isEkitohoEnable } from './ConditionMapperUtils';

import {
  BukkenDateRangeRadioState,
  B2bKokaiCodeRadioState,
  BukkenNyukyoKanoCodeMap,
  BukkenNyukyoKanoDateRangeRadioState,
  KozoConditionsParamMap,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import {
  IndoorEquipConditions,
  BuildingEquipConditions,
  ChushajoConditions,
  FloorConditions,
  GasConditions,
  KitchenConditions,
  NyukyoConditions,
  RoomStorageConditions,
  SanitaryEquipConditions,
  SecurityConditions,
  TelecomEquipConditions,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchKodawariConditionEnums';
import {
  ChintaiSearchConditions,
  KodawariCondition,
  MapAreaConditions,
  PlaceAndLineCondition,
} from '@/Models/SearchConditions/ChintaiSearchConditions';

type ConditionParamMapperDefinition<C> = {
  paramName: string;
  paramValueGetter: (condition: C) => string[];
};

const dateRangeFromToParamValue = (state: BukkenDateRangeRadioState, date: Date | undefined): string[] => {
  switch (state) {
    case 'select':
      return date !== undefined ? [formatRFC3339(startOfDay(date))] : [];
    case 'today':
      return [formatRFC3339(startOfDay(new Date()))];
    case 'threeDays': {
      const today = startOfDay(new Date());
      const fromDate = sub(today, { days: 3 });
      return [formatRFC3339(fromDate)];
    }
    case 'sevenDays': {
      const today = startOfDay(new Date());
      const fromDate = sub(today, { days: 7 });
      return [formatRFC3339(fromDate)];
    }
    case 'unselected':
      return [];
  }
};

const KodawariConditionParamMappers: readonly ConditionParamMapperDefinition<KodawariCondition>[] = [
  {
    paramName: 'chushajo_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(ChushajoConditions.chushajoExist) ? ['true'] : [],
  },
  {
    paramName: 'churinjo_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(ChushajoConditions.churinjoExist) ? ['true'] : [],
  },
  {
    paramName: 'bike_okiba_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(ChushajoConditions.bikeStorageExist) ? ['true'] : [],
  },
  {
    paramName: 'shozaikai_num.to',
    paramValueGetter: (c: KodawariCondition): string[] => (c.kodawari.includes(FloorConditions.first) ? ['1'] : []),
  },
  {
    paramName: 'shozaikai_num.from',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(FloorConditions.secondOrAbove) ? ['2'] : [],
  },
  {
    paramName: 'saijokai_flag',
    paramValueGetter: (c: KodawariCondition): string[] => (c.kodawari.includes(FloorConditions.top) ? ['true'] : []),
  },
  {
    paramName: 'keiyaku_joken_pet',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(NyukyoConditions.petMayBeOK) ? ['可', '相談'] : [],
  },
  {
    paramName: 'keiyaku_joken_gakki_shiyo',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(NyukyoConditions.musicalInstMayBeOK) ? ['可', '相談'] : [],
  },
  {
    paramName: 'keiyaku_joken_jimusho',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(NyukyoConditions.officeUsageMayBeOK) ? ['可', '相談'] : [],
  },
  {
    paramName: 'keiyaku_joken_seibetsu',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(NyukyoConditions.womanOnly) ? ['女性限定'] : [],
  },
  {
    paramName: 'keiyaku_joken_futari_nyukyo',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(NyukyoConditions.twoPersonMayBeOK) ? ['可', '相談'] : [],
  },
  {
    paramName: 'keiyaku_joken_gaikokujin_nyukyo',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(NyukyoConditions.foreignerMayBeOK) ? ['可', '相談'] : [],
  },
  {
    paramName: 'keiyaku_joken_gakusei_senyo',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(NyukyoConditions.studentOnly) ? ['限定'] : [],
  },
  {
    paramName: 'keiyaku_joken_room_share',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(NyukyoConditions.roomShareMayBeOK) ? ['可', '相談'] : [],
  },
  {
    paramName: 'keiyaku_joken_koreisha',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(NyukyoConditions.seniorCitizensMayBeOK) ? ['可', '相談'] : [],
  },
  {
    paramName: 'hoshonin_code',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(NyukyoConditions.guarantorUnnecessary) ? ['2'] : [],
  },
  {
    paramName: 'gas_stove_setting_state_ka_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(KitchenConditions.gasBurnerCanBePlaced) ? ['true'] : [],
  },
  {
    paramName: 'gas_stove_setting_state_zumi_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(KitchenConditions.gasBurnerIsPlaced) ? ['true'] : [],
  },
  {
    paramName: 'cooking_stove_2_socket_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(KitchenConditions.twoBurner) ? ['true'] : [],
  },
  {
    paramName: 'cooking_stove_3_socket_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(KitchenConditions.moreThanThreeBurner) ? ['true'] : [],
  },
  {
    paramName: 'ih_cooking_heater_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(KitchenConditions.ihCooker) ? ['true'] : [],
  },
  {
    paramName: 'system_kitchen_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(KitchenConditions.systemKitchen) ? ['true'] : [],
  },
  {
    paramName: 'counter_kitchen_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(KitchenConditions.counterKitchen) ? ['true'] : [],
  },
  {
    paramName: 'senmenjo_dokuritsu_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(SanitaryEquipConditions.separatedLavatory) ? ['true'] : [],
  },
  {
    paramName: 'yokushitsu_kansoki_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(SanitaryEquipConditions.bathroomDryer) ? ['true'] : [],
  },
  {
    paramName: 'washlet_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(SanitaryEquipConditions.washingToiletSeat) ? ['true'] : [],
  },
  {
    paramName: 'oidaki_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(SanitaryEquipConditions.bathReheat) ? ['true'] : [],
  },
  {
    paramName: 'bath_toilet_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(SanitaryEquipConditions.separatedBathAndToilet) ? ['true'] : [],
  },
  {
    paramName: 'shitsunai_sentakuki_okiba_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(SanitaryEquipConditions.inroomWashingMachinePlace) ? ['true'] : [],
  },
  {
    paramName: 'aircon_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(IndoorEquipConditions.airConAvailable) ? ['true'] : [],
  },
  {
    paramName: 'yuka_danbo_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(IndoorEquipConditions.floorHeater) ? ['true'] : [],
  },
  {
    paramName: 'kagu_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(IndoorEquipConditions.kagu_flag) ? ['true'] : [],
  },
  {
    paramName: 'kaden_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(IndoorEquipConditions.kaden_flag) ? ['true'] : [],
  },
  {
    paramName: 'walk_in_closet_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(RoomStorageConditions.walkinCloset) ? ['true'] : [],
  },
  {
    paramName: 'loft_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(RoomStorageConditions.loftAvailable) ? ['true'] : [],
  },
  {
    paramName: 'trunk_room_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(RoomStorageConditions.trunkRoom) ? ['true'] : [],
  },
  {
    paramName: 'bs_cs_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(TelecomEquipConditions.bscs) ? ['true'] : [],
  },
  {
    paramName: 'catv_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(TelecomEquipConditions.cableTv) ? ['true'] : [],
  },
  {
    paramName: 'internet_free_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(TelecomEquipConditions.internet_free_flag) ? ['true'] : [],
  },
  {
    paramName: 'tv_intercom_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(SecurityConditions.intercomWithMonitor) ? ['true'] : [],
  },
  {
    paramName: 'auto_lock_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(SecurityConditions.autoLock) ? ['true'] : [],
  },
  {
    paramName: 'takuhai_box_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(SecurityConditions.deliveryBox) ? ['true'] : [],
  },
  {
    paramName: 'kanrinin_jochu_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(SecurityConditions.caretakerResident) ? ['true'] : [],
  },
  {
    paramName: 'security_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(SecurityConditions.hasSecurity) ? ['true'] : [],
  },
  {
    paramName: 'bohan_camera_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(SecurityConditions.securityCamera) ? ['true'] : [],
  },
  {
    paramName: 'elevator_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(BuildingEquipConditions.elevator) ? ['true'] : [],
  },
  {
    paramName: 'shikichinai_gomi_okiba_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(BuildingEquipConditions.dumpInArea) ? ['true'] : [],
  },
  {
    paramName: 'designers_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(BuildingEquipConditions.designers) ? ['true'] : [],
  },
  {
    paramName: 'bunjo_chintai_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(BuildingEquipConditions.condominiumRent) ? ['true'] : [],
  },
  {
    paramName: 'gas_kubun',
    paramValueGetter: (c: KodawariCondition): string[] => {
      const values: string[] = [];
      if (c.kodawari.includes(GasConditions.cityGas)) {
        values.push('都市ガス');
      }
      if (c.kodawari.includes(GasConditions.propane)) {
        values.push('プロパン');
      }
      return values;
    },
  },
  {
    paramName: 'veranda_balcony_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(BuildingEquipConditions.verandaBalcony) ? ['true'] : [],
  },
  {
    paramName: 'roof_balcony_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(BuildingEquipConditions.roofBalcony) ? ['true'] : [],
  },
  {
    paramName: 'senyo_niwa_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(BuildingEquipConditions.privateGarden) ? ['true'] : [],
  },
  {
    paramName: 'barrier_free_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(BuildingEquipConditions.barrierFree) ? ['true'] : [],
  },
  {
    paramName: 'flooring_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(BuildingEquipConditions.flooring) ? ['true'] : [],
  },
  {
    paramName: 'kadobeya_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(BuildingEquipConditions.cornerRoom) ? ['true'] : [],
  },
  {
    paramName: 'renovation_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(BuildingEquipConditions.renovatedBuilding) ? ['true'] : [],
  },
  {
    paramName: 'maisonette_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(BuildingEquipConditions.maisonette) ? ['true'] : [],
  },
  {
    paramName: 'soundproof_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(BuildingEquipConditions.insulationFacility) ? ['true'] : [],
  },
  {
    paramName: 'all_denka_flag',
    paramValueGetter: (c: KodawariCondition): string[] =>
      c.kodawari.includes(BuildingEquipConditions.allElectricHome) ? ['true'] : [],
  },
];

const LatLngParamMappers: readonly ConditionParamMapperDefinition<MapAreaConditions>[] = [
  {
    paramName: 'ido.from',
    paramValueGetter: (c: MapAreaConditions): string[] =>
      c.jgsLatitudeFrom !== undefined ? [c.jgsLatitudeFrom.toString()] : [],
  },
  {
    paramName: 'ido.to',
    paramValueGetter: (c: MapAreaConditions): string[] =>
      c.jgsLatitudeTo !== undefined ? [c.jgsLatitudeTo.toString()] : [],
  },
  {
    paramName: 'keido.from',
    paramValueGetter: (c: MapAreaConditions): string[] =>
      c.jgsLongitudeFrom !== undefined ? [c.jgsLongitudeFrom.toString()] : [],
  },
  {
    paramName: 'keido.to',
    paramValueGetter: (c: MapAreaConditions): string[] =>
      c.jgsLongitudeTo !== undefined ? [c.jgsLongitudeTo.toString()] : [],
  },
];

const PlaceAndLineParamMappers: readonly ConditionParamMapperDefinition<PlaceAndLineCondition>[] = [
  {
    paramName: 'kotsu_rosen_code',
    paramValueGetter: (c: PlaceAndLineCondition): string[] => c.lines.map(l => l.lineCode.toFixed(0)),
  },
  {
    paramName: 'kotsu_eki_code',
    paramValueGetter: (c: PlaceAndLineCondition): string[] => c.stations.map(s => s.stationCode.toFixed(0)),
  },
];

/* API を叩く時のリクエストパラメータと検索条件 state をマッピングする処理 */
/*
  - paramyName
    - リクエストパラメータ名
  - paramValueGetter
    - 保持している検索条件 state からリクエストパラメータに変換する
*/
export const ConditionParamMappers: readonly ConditionParamMapperDefinition<ChintaiSearchConditions>[] = [
  ...LatLngParamMappers,
  ...KodawariConditionParamMappers,
  ...PlaceAndLineParamMappers,
  {
    paramName: 'tatemono_name',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => (c.bukkenName.length > 0 ? [c.bukkenName] : []),
  },
  {
    paramName: 'tatemono_name_kana',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => (c.bukkenNameKana.length > 0 ? [c.bukkenNameKana] : []),
  },
  {
    paramName: 'chinryo.from',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.chinryoFrom !== undefined ? [c.chinryoFrom.toString()] : [],
  },
  {
    paramName: 'chinryo.to',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.chinryoTo !== undefined ? [c.chinryoTo.toString()] : [],
  },
  {
    paramName: 'komi_chinryo.from',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.komiChinryoFrom !== undefined ? [c.komiChinryoFrom.toString()] : [],
  },
  {
    paramName: 'komi_chinryo.to',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.komiChinryoTo !== undefined ? [c.komiChinryoTo.toString()] : [],
  },
  {
    paramName: 'free_rent_flag',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.freeRentAvailable ? [c.freeRentAvailable.toString()] : [],
  },
  {
    paramName: 'shikikin_nashi_flag',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.isExcludeShikikin ? [c.isExcludeShikikin.toString()] : [],
  },
  {
    paramName: 'reikin_nashi_flag',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.isExcludeReikin ? [c.isExcludeReikin.toString()] : [],
  },
  {
    paramName: 'shikikin_kagetsu.to',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.shikikinTo !== undefined ? [c.shikikinTo.toString()] : [],
  },
  {
    paramName: 'reikin_kagetsu.to',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.reikinTo !== undefined ? [c.reikinTo.toString()] : [],
  },
  {
    paramName: 'kotsu_ekitoho',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (!isEkitohoEnable(c.lines, c.stations)) {
        return [];
      }
      return c.kotsuEkitoho !== undefined ? [c.kotsuEkitoho.toString()] : [];
    },
  },
  {
    paramName: 'search_menseki.from',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.mensekiFrom !== undefined ? [c.mensekiFrom.toString()] : [],
  },
  {
    paramName: 'search_menseki.to',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.mensekiTo !== undefined ? [c.mensekiTo.toString()] : [],
  },
  {
    paramName: 'kokokuryo_kagetsu.from',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.kokokuyroKagetsuFrom !== undefined ? [c.kokokuyroKagetsuFrom.toString()] : [],
  },
  {
    paramName: 'kokokuryo_kagetsu.to',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.kokokuryoKagetsuTo !== undefined ? [c.kokokuryoKagetsuTo.toString()] : [],
  },
  {
    paramName: 'search_boshu_shubetsu_code',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.searchBoshuShubetsuCode.length > 0 ? c.searchBoshuShubetsuCode : [],
  },
  {
    paramName: 'search_madori_code2',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.searchMadoriCode.length > 0 ? c.searchMadoriCode : [],
  },
  {
    paramName: 'chintai_genkyo_code',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.searchGenkyoCode.length > 0 ? c.searchGenkyoCode : [],
  },
  {
    paramName: 'shunko_datejun.from',
    // 築n年という検索条件からOne APIの検索形式である竣工がn日以降という値へと変換を行う
    paramValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (c.chikuNensu === undefined) {
        return [];
      }
      const today = startOfDay(new Date());
      const fromDate = sub(today, { years: Number(c.chikuNensu) });
      return [Datejun.dateToDatejun(fromDate).toString()];
    },
  },
  {
    paramName: 'shinchiku_flag',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => (c.isShinchiku ? [c.isShinchiku.toString()] : []),
  },
  {
    paramName: 'kozo',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (c.kozo.length === 0) {
        return [];
      }
      return c.kozo.map(it => KozoConditionsParamMap[it]).flat();
    },
  },
  {
    paramName: 'one_network_keisai_kaishi_time.from',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => {
      return dateRangeFromToParamValue(c.kokaiRadioState, c.kokaiDateFrom);
    },
  },
  {
    paramName: 'one_network_keisai_kaishi_time.to',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (c.kokaiRadioState === BukkenDateRangeRadioState.select) {
        return c.kokaiDateTo !== undefined ? [formatRFC3339(startOfDay(c.kokaiDateTo))] : [];
      } else {
        return [];
      }
    },
  },
  {
    paramName: 'saishu_koshin_time.from',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => {
      return dateRangeFromToParamValue(c.koshinRadioState, c.koshinDateFrom);
    },
  },
  {
    paramName: 'saishu_koshin_time.to',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (c.koshinRadioState === BukkenDateRangeRadioState.select) {
        return c.koshinDateTo !== undefined ? [formatRFC3339(startOfDay(c.koshinDateTo))] : [];
      } else {
        return [];
      }
    },
  },
  {
    paramName: 'nyukyo_kano_code',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (c.nyukyoKanoRadioState.state === BukkenNyukyoKanoDateRangeRadioState.unselected) {
        return [];
      } else {
        const codeList = [BukkenNyukyoKanoCodeMap[c.nyukyoKanoRadioState.state]];
        if (
          c.nyukyoKanoRadioState.state === BukkenNyukyoKanoDateRangeRadioState.select &&
          c.nyukyoKanoRadioState.hasPlan
        ) {
          codeList.push(BukkenNyukyoKanoCodeMap[BukkenNyukyoKanoDateRangeRadioState.plan]);
        }
        return codeList;
      }
    },
  },
  {
    paramName: 'nyukyo_kano_datejun.from',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (c.nyukyoKanoRadioState.state === BukkenNyukyoKanoDateRangeRadioState.select) {
        return c.nyukyoKanoDateFrom !== undefined ? [Datejun.dateToDatejun(c.nyukyoKanoDateFrom).toString()] : [];
      } else {
        return [];
      }
    },
  },
  {
    paramName: 'nyukyo_kano_datejun.to',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (c.nyukyoKanoRadioState.state === BukkenNyukyoKanoDateRangeRadioState.select) {
        return c.nyukyoKanoDateTo !== undefined ? [Datejun.dateToDatejun(c.nyukyoKanoDateTo).toString()] : [];
      } else {
        return [];
      }
    },
  },
  {
    paramName: 'naiken_kubun_code',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => (c.naiken !== '0' ? [c.naiken] : []),
  },
  {
    paramName: 'property_full_key',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.propertyFullKey.length > 0 ? [c.propertyFullKey] : [],
  },
  {
    paramName: 'organization_guid',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.organizationGuid.length > 0 ? [c.organizationGuid] : [],
  },
  {
    paramName: 'motozuke_gyosha_name',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => (c.kanriGyosha.length > 0 ? [c.kanriGyosha] : []),
  },
  {
    paramName: 'heya_kukaku_number',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => (c.heyaNumber.length > 0 ? [c.heyaNumber] : []),
  },
  {
    paramName: 'moshikomi_flag',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => (c.isExcludeMoshikomiExist ? [String(false)] : []),
  },
  {
    paramName: 'sokunyukyo_ka_flag',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.isSokunyukyoEnable ? [c.isSokunyukyoEnable.toString()] : [],
  },
  {
    paramName: 'order',
    paramValueGetter: (c: ChintaiSearchConditions): string[] => [
      [c.order, 'tatemono_name.asc', 'sort_heya_kukaku_number.asc'].join(','),
    ],
  },
  {
    paramName: 'b2b_kokai_code',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.bukkenKokaiScopeRadioState !== B2bKokaiCodeRadioState.unselected ? [c.bukkenKokaiScopeRadioState] : [],
  },
  {
    paramName: 'total_gazo_count.from',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.totalGazoCountFrom !== undefined ? [c.totalGazoCountFrom.toString()] : [],
  },
  {
    paramName: 'gaikan_gazo_count.from',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.gaikanGazoCountFrom !== undefined ? [c.gaikanGazoCountFrom.toString()] : [],
  },
  {
    paramName: 'madori_gazo_count.from',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.madoriGazoCountFrom !== undefined ? [c.madoriGazoCountFrom.toString()] : [],
  },
  {
    paramName: 'naikan_gazo_count.from',
    paramValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.naikanGazoCountFrom !== undefined ? [c.naikanGazoCountFrom.toString()] : [],
  },
];
