type RouteDefinition = {
  readonly path: string;
  readonly title: string;
};

type RouteKeys =
  | 'root'
  | 'login'
  | '404'
  | 'chintaiBukkenSearch'
  | 'chintaiBukkenDetail'
  | 'chintaiBukkenMapSearch'
  | 'chintaiBukkenMapSearchDetail'
  | 'chintaiBukkenPreviewReservation'
  | 'chintaiBukkenMoveinApplication'
  | 'partner'
  | 'partnerApplicantsSent'
  | 'partnerApplicantsSentDialog'
  | 'partnerApplicantsReceived'
  | 'partnerApplicantsReceivedDialog'
  | 'setting'
  | 'settingOrganization';

export const RouteDefinitions = Object.freeze<Record<RouteKeys, RouteDefinition>>({
  root: {
    path: '/',
    title: '',
  },
  login: {
    path: '/login',
    title: 'ログイン',
  },
  404: {
    path: '/404',
    title: '404',
  },
  chintaiBukkenSearch: {
    path: '/bukken/chintai/search',
    title: '物件検索',
  },
  chintaiBukkenDetail: {
    path: '/bukken/chintai/search/detail/:guid',
    title: '物件詳細',
  },
  chintaiBukkenMapSearch: {
    path: '/bukken/chintai/mapSearch',
    title: '物件地図検索',
  },
  chintaiBukkenMapSearchDetail: {
    path: '/bukken/chintai/mapSearch/detail/:guid',
    title: '物件詳細（地図検索）',
  },
  chintaiBukkenPreviewReservation: {
    path: '/bukken/chintai/preview-reservation',
    title: '内見予約',
  },
  chintaiBukkenMoveinApplication: {
    path: '/bukken/chintai/movein-application',
    title: '申込',
  },
  partner: {
    path: '/partner',
    title: '取引先管理',
  },
  partnerApplicantsSent: {
    path: '/partner_applicants/sent',
    title: '送信した申請',
  },
  partnerApplicantsSentDialog: {
    path: '/partner_applicants/sent/:partnerApplicantUid',
    title: '送信した申請ダイアログ',
  },
  partnerApplicantsReceived: {
    path: '/partner_applicants/received',
    title: '受信した申請',
  },
  partnerApplicantsReceivedDialog: {
    path: '/partner_applicants/received/:partnerApplicantUid',
    title: '受信した申請ダイアログ',
  },
  setting: {
    path: '/setting',
    title: '設定',
  },
  settingOrganization: {
    path: '/setting/:organizationUid',
    title: '組織設定',
  },
});
