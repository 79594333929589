import { getViewportHeight } from '@app/utils';
import { SerializedStyles, css } from '@emotion/react';
import { Loading } from '@lib/components';
import { Box, Pagination, TablePagination } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ChangeEvent, FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { Channel } from '../../../../../graphql-client/src/API';

import { MyOrganizationsSelector } from './MyOrganizationSelector';
import { PartnerOrganizationList } from './PartnerOrganizationList';

import { AppState } from '@/Consts/AppState';
import { searchResultFooterZIndex, searchResultHeaderZIndex } from '@/Consts/Values';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { useEsaDomainOrganizations } from '@/Hooks/Esa/useEsaDomainOrganizations';
import { useChannelsQuery, useChannels } from '@/Hooks/Messenger/useChannels';
import { EsaOrganization } from '@/Models/Esa/EsaOrganization';
import { RelationTypeCollection } from '@/Models/Messenger/Graphql';
import {
  ItemPerPageKey,
  ItemsPerPageCollection,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import { GeneralNotFoundPage } from '@/Pages/error/GeneralNotFoundPage';
import { GeneralServerErrorPage } from '@/Pages/error/GeneralServerErrorPage';
import { getTotalPageCount } from '@/Utils/SearchResultUtils';

export const allOrganization = {
  organizationUid: undefined,
  name: '所属組織すべて',
};
const PartnerOrganizationPageContainer: FC = () => {
  const { channelsQuery, setChannelsQuery } = useChannelsQuery();

  const [itemsPerPage, setItemsPerPage] = useLocalStorage(
    AppState.partnerListItemsPerPage,
    channelsQuery.itemsPerPage
  ) as [number, React.Dispatch<React.SetStateAction<number>>, () => void];
  const startIndex = useMemo(() => {
    if (!channelsQuery.startIndex) {
      return 1;
    }
    return Math.floor(channelsQuery.startIndex);
  }, [channelsQuery.startIndex]);

  const rowsPerPageOptions = Object.values(ItemsPerPageCollection).map(item => {
    return { value: item, label: `${item}件` };
  });

  const handleChangePage = useCallback(
    (_event: ChangeEvent<unknown> | null, newPage: number) => {
      if (newPage === null) {
        return;
      }
      setChannelsQuery({
        startIndex: newPage,
        itemsPerPage: channelsQuery.itemsPerPage,
        organizationUid: channelsQuery.organizationUid,
      });
    },
    [channelsQuery.itemsPerPage, channelsQuery.organizationUid, setChannelsQuery]
  );

  const handleChangeItemsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const targetItemsPerPage = parseInt(event.target.value);
      setChannelsQuery({
        startIndex: 1,
        itemsPerPage: targetItemsPerPage,
        organizationUid: channelsQuery.organizationUid,
      });
      setItemsPerPage(targetItemsPerPage);
    },
    [channelsQuery.organizationUid, setItemsPerPage, setChannelsQuery]
  );

  const user = useCurrentUser();

  const { organizations, isLoading: isDomainLoading } = useEsaDomainOrganizations({ domainUid: user.domainUid });
  const hasMasterRole = useMemo(() => {
    return user.role.isAdmin;
  }, [user.role.isAdmin]);

  const userOrganizations: EsaOrganization[] = useMemo(() => {
    if (!organizations) {
      return [];
    }

    if (hasMasterRole) {
      return organizations;
    }

    return organizations?.filter((o: EsaOrganization) => user.organizationUids.includes(o.organizationUid));
  }, [organizations, user.organizationUids, hasMasterRole]);

  const organizationList = useMemo(() => {
    return [allOrganization, ...userOrganizations];
  }, [userOrganizations]);

  const [currentOrganizationUid, setCurrentOrganizationUid] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (channelsQuery.organizationGuid) {
      const organizationGuid = userOrganizations.find(
        o => o.ebone?.organizationGuid === channelsQuery.organizationGuid
      )?.organizationUid;
      setCurrentOrganizationUid(organizationGuid);
    }
    if (channelsQuery.organizationUid) {
      setCurrentOrganizationUid(channelsQuery.organizationUid);
    }
  }, [
    channelsQuery.organizationGuid,
    channelsQuery.organizationUid,
    currentOrganizationUid,
    organizationList,
    userOrganizations,
  ]);

  const handleCurrentOrganizationUid = (uid: string | undefined): void => {
    setChannelsQuery({
      startIndex: 1,
      itemsPerPage: channelsQuery.itemsPerPage,
      organizationUid: uid,
    });
    setCurrentOrganizationUid(uid);
  };

  const isMultipleOrganization = useMemo(() => {
    return userOrganizations ? userOrganizations.length > 1 : false;
  }, [userOrganizations]);

  const {
    model: channelModel,
    isLoading: isChannelLoading,
    error: channelError,
  } = useChannels({
    itemsPerPage: channelsQuery.itemsPerPage,
    startIndex: channelsQuery.startIndex,
    organizationUid: currentOrganizationUid,
    relationType: RelationTypeCollection.partner,
  });
  const channelList = channelModel?.data.listChannels?.items;
  const totalCount = channelModel?.data.listChannels?.totalCounts ?? 0;
  const totalPageCount = getTotalPageCount(totalCount, itemsPerPage) ?? 0;

  useEffect(() => {
    setChannelsQuery({
      startIndex: startIndex,
      itemsPerPage: itemsPerPage,
      organizationUid: currentOrganizationUid,
      organizationGuid: channelsQuery.organizationGuid,
    });
  }, [startIndex, itemsPerPage, setChannelsQuery, currentOrganizationUid, channelsQuery.organizationGuid]);

  if (isDomainLoading) {
    return (
      <Box height={getViewportHeight(75)} width="100%" overflow="hidden">
        <Loading />
      </Box>
    );
  }

  const CHANNEL_NOT_FOUND_MESSAGE = '対象組織のチャンネルがありません';
  const ACCESS_DENIED_MESSAGE = '対象チャンネルへの閲覧権限がありません';

  if (channelError) {
    if (channelError.message === CHANNEL_NOT_FOUND_MESSAGE || channelError.message === ACCESS_DENIED_MESSAGE) {
      return <GeneralNotFoundPage />;
    }
    return <GeneralServerErrorPage />;
  }

  return (
    <PartnerOrganizationPagePresenter
      channelList={channelList ?? []}
      isChannelLoading={isChannelLoading}
      startIndex={startIndex}
      itemsPerPage={itemsPerPage}
      totalCount={totalCount}
      totalPageCount={totalPageCount}
      rowsPerPageOptions={rowsPerPageOptions}
      handleChangePage={handleChangePage}
      handleChangeItemsPerPage={handleChangeItemsPerPage}
      organizationList={organizationList}
      currentOrganizationUid={currentOrganizationUid}
      handleCurrentOrganizationUid={handleCurrentOrganizationUid}
      userOrganizations={userOrganizations}
      isMultipleOrganization={isMultipleOrganization}
    />
  );
};

export { PartnerOrganizationPageContainer as PartnerOrganizationPage };

type PartnerOrganizationPagePresenterProps = {
  channelList: (Channel | null)[];
  isChannelLoading: boolean;
  startIndex: number;
  itemsPerPage: number;
  totalCount: number;
  totalPageCount: number;
  rowsPerPageOptions: {
    value: ItemPerPageKey;
    label: string;
  }[];
  handleChangePage: (_event: ChangeEvent<unknown> | null, newPage: number) => void;
  handleChangeItemsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
  organizationList: (
    | EsaOrganization
    | {
        organizationUid: undefined;
        name: string;
      }
  )[];
  currentOrganizationUid: string | undefined;
  handleCurrentOrganizationUid: (uid: string | undefined) => void;
  userOrganizations: EsaOrganization[];
  isMultipleOrganization: boolean;
};

export const paginationStyle = (): SerializedStyles =>
  css({
    '& .MuiTablePagination-toolbar': {
      flexDirection: 'row-reverse',
      paddingLeft: '8px',
    },
    '& .MuiTablePagination-select': {
      backgroundColor: 'white',
      border: `1px solid ${grey[300]}`,
      borderRadius: '4px',
      padding: '8px',
    },
  });

export const PartnerOrganizationPagePresenter: FC<PartnerOrganizationPagePresenterProps> = ({
  channelList,
  isChannelLoading,
  startIndex,
  itemsPerPage,
  totalCount,
  totalPageCount,
  rowsPerPageOptions,
  handleChangePage,
  handleChangeItemsPerPage,
  organizationList,
  currentOrganizationUid,
  handleCurrentOrganizationUid,
  userOrganizations,
  isMultipleOrganization,
}) => {
  return (
    <Fragment>
      <Box
        zIndex={searchResultHeaderZIndex}
        position="sticky"
        bgcolor="grey.100"
        top={0}
        pt={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        flexWrap="wrap"
      >
        <TablePagination
          component="div"
          css={paginationStyle}
          page={startIndex - 1}
          count={totalCount}
          rowsPerPage={itemsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          labelRowsPerPage=""
          labelDisplayedRows={({ from, to, count }) => {
            return (
              <Box component="span" display="flex" flexDirection="row" alignItems="center" mr={1.5} ml={1}>
                <Box component="span" fontWeight="bold" fontSize="18px" mr={0.5}>
                  {`${from}-${to}件`}
                </Box>
                <Box component="span">{`/ 全${count}件`}</Box>
              </Box>
            );
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeItemsPerPage}
          ActionsComponent={() => <div />}
        />
        <div />
        {isMultipleOrganization && (
          <Box display="flex" justifyContent="flex-end" py={1} ml="auto" mr={2}>
            <MyOrganizationsSelector
              organizationList={organizationList}
              currentOrganizationUid={currentOrganizationUid}
              handleCurrentOrganizationUid={handleCurrentOrganizationUid}
            />
          </Box>
        )}
      </Box>
      <PartnerOrganizationList
        channelList={channelList}
        isChannelLoading={isChannelLoading}
        userOrganizations={userOrganizations}
      />
      {channelList?.length !== 0 && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          py={1}
          position="sticky"
          bottom={0}
          zIndex={searchResultFooterZIndex}
          bgcolor="grey.100"
        >
          <Pagination
            count={totalPageCount}
            onChange={handleChangePage}
            page={startIndex}
            shape="rounded"
            size="medium"
          />
        </Box>
      )}
    </Fragment>
  );
};
