import { AuthenticationNotGetTokenError } from '@app/errors';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';

import EsaApiClient from './EsaApiClient';
import { IEsaApiClient } from './IEsaApiClient';

type Props = {
  audience: string;
  baseUrl: string;
  scope?: string;
};

export type ApiClientState =
  | {
      isLoading: true;
      error: null;
      apiClient: null;
    }
  | {
      isLoading: false;
      error: Error;
      apiClient: null;
    }
  | {
      isLoading: false;
      error: null;
      apiClient: IEsaApiClient;
    };

export const useEsaApiClient = ({ audience, baseUrl, scope }: Props): ApiClientState => {
  const { error, getAccessTokenSilently, getAccessTokenWithPopup, isLoading } = useAuth0();
  const apiClient = useMemo<IEsaApiClient>(
    () =>
      new EsaApiClient(baseUrl, async (): Promise<string> => {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience,
            scope,
          },
        }).catch(async e => {
          if (e?.error === 'consent_required') {
            return getAccessTokenWithPopup({
              authorizationParams: {
                audience,
                scope,
              },
            }).catch(() => {
              throw new AuthenticationNotGetTokenError();
            });
          }
          throw new AuthenticationNotGetTokenError();
        });
        if (!token) {
          throw new AuthenticationNotGetTokenError();
        }
        return token;
      }),
    [audience, baseUrl, getAccessTokenSilently, getAccessTokenWithPopup, scope]
  );

  if (isLoading) {
    return { isLoading: true, error: null, apiClient: null };
  }
  if (error) {
    return { isLoading: false, error, apiClient: null };
  }

  return { isLoading: false, error: null, apiClient };
};
