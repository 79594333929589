import { EsaPublicDomain } from '@/Models/Esa/EsaDomain';
import { EsaPublicOrganization } from '@/Models/Esa/EsaOrganization';
import {
  PartnerApplicantsStatusCollectionType,
  PartnerApplicantsTypeCollection,
  PartnerApplicantsTypeCollectionType,
} from '@/Models/Esa/PartnerApplicants';

export const getPartnerApplicantsStatusChip = (
  partnerApplicantsType: PartnerApplicantsTypeCollectionType,
  partnerApplicantsStatus: PartnerApplicantsStatusCollectionType
): {
  label: string;
  color: string;
} => {
  const sentLabelMap = {
    standby: '申請中',
    approved: '申請OK',
    rejected: '申請NG',
    cancelled: '取消済',
  };
  const receivedLabelMap = {
    standby: '承認待',
    approved: '承認済',
    rejected: '却下済',
    cancelled: '取消済',
  };
  const colorMap = {
    standby: '#FC8F2A',
    approved: '#48A550',
    rejected: '#E7421E',
    cancelled: '',
  };

  switch (partnerApplicantsType) {
    case PartnerApplicantsTypeCollection.sent:
      return { label: sentLabelMap[partnerApplicantsStatus], color: colorMap[partnerApplicantsStatus] };
    case PartnerApplicantsTypeCollection.received:
      return { label: receivedLabelMap[partnerApplicantsStatus], color: colorMap[partnerApplicantsStatus] };
  }
};

export const getPartnerApplicantDomainOrganizationName = (
  domain: EsaPublicDomain,
  organization: EsaPublicOrganization
): {
  label: string;
  value: string;
}[] => {
  if (organization.businessName) {
    return [
      {
        label: '会社名',
        value: organization.businessName,
      },
    ];
  }

  return [
    {
      label: '会社名',
      value: domain.name,
    },
    {
      label: '組織名（店舗名）',
      value: organization.name,
    },
  ];
};
