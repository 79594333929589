import { Autocomplete, TextField } from '@mui/material';
import { FC, memo } from 'react';

import { allOrganization } from './PartnerOrganizationPage';

import { EsaOrganization } from '@/Models/Esa/EsaOrganization';

type MyOrganizationsSelectorContainerProps = {
  organizationList: (
    | EsaOrganization
    | {
        organizationUid: undefined;
        name: string;
      }
  )[];
  currentOrganizationUid: string | undefined;
  handleCurrentOrganizationUid: (uid: string | undefined) => void;
};

const MyOrganizationsSelectorContainer: FC<MyOrganizationsSelectorContainerProps> = memo(
  ({ organizationList, currentOrganizationUid, handleCurrentOrganizationUid }) => {
    return (
      <MyOrganizationsSelectorPresenter
        organizationList={organizationList}
        currentOrganizationUid={currentOrganizationUid}
        handleCurrentOrganizationUid={handleCurrentOrganizationUid}
      />
    );
  }
);

export { MyOrganizationsSelectorContainer as MyOrganizationsSelector };

type MyOrganizationsSelectorPresenterProps = {
  readonly organizationList: (
    | EsaOrganization
    | {
        organizationUid: undefined;
        name: string;
      }
  )[];
  currentOrganizationUid: string | undefined;
  handleCurrentOrganizationUid: (uid: string | undefined) => void;
};

export const MyOrganizationsSelectorPresenter: FC<MyOrganizationsSelectorPresenterProps> = memo(
  ({ organizationList, currentOrganizationUid, handleCurrentOrganizationUid }) => (
    <Autocomplete
      autoComplete
      autoHighlight
      disableClearable
      closeText="閉じる"
      noOptionsText="候補なし"
      size="small"
      value={organizationList.find(o => o?.organizationUid === currentOrganizationUid) ?? allOrganization}
      renderInput={params => <TextField {...params} variant="outlined" placeholder="組織名を入力" />}
      options={organizationList}
      getOptionLabel={option => option?.name ?? ''}
      onChange={(_, newValue) => handleCurrentOrganizationUid(newValue.organizationUid)}
      css={{ width: '180px', backgroundColor: 'white', marginLeft: '8px' }}
    />
  )
);
