import { isNullOrUndefined, deepCompare } from '@app/utils';
import { textSxProps, useIsSmallDevice } from '@lib/components';
import { ServiceConfigure } from '@lib/components/src/Consts/Configure';
import {
  Box,
  Button,
  buttonClasses,
  Divider,
  dividerClasses,
  IconButton,
  Menu,
  MenuItem,
  PopoverOrigin,
  styled,
  Typography,
} from '@mui/material';
import { AccountCircle, ChevronDown, OpenInNew } from 'mdi-material-ui';
import { memo, useState, useCallback, MouseEvent, FC, Fragment, useMemo } from 'react';

const MenuPosition: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

type AccountMenuProps = {
  loginContextEmail?: string;
  userName: string;
  email: string | null;
  domainName: string;
  accountUrl: string;
  sumaiEntryNaikenUrl: string;
  sumaiEntryMoshikomiUrl: string;
  onLogout: () => void;
};

const AccountMenuDevider = styled(Divider)({
  [`&.${dividerClasses.root}`]: { margin: 0 },
});

export const AccountMenu: FC<AccountMenuProps> = memo(
  ({
    loginContextEmail,
    userName,
    email,
    domainName,
    onLogout: onLogoutCallback,
    accountUrl,
    sumaiEntryNaikenUrl,
    sumaiEntryMoshikomiUrl,
  }) => {
    const [anchorElement, setAnchorElement] = useState<Element | null>(null);
    const isSmallDevice = useIsSmallDevice();
    const isOpen = !isNullOrUndefined(anchorElement);
    const onIconClicked = useCallback((ev: MouseEvent) => {
      setAnchorElement(ev.currentTarget);
    }, []);
    const onMenuClosed = useCallback(() => {
      setAnchorElement(null);
    }, []);
    const onLogout = useCallback(() => {
      setAnchorElement(null);
      onLogoutCallback();
    }, [onLogoutCallback]);
    const displayUserName = useMemo(() => {
      // 宅建ライセンスがない場合、メールアドレスをユーザー名として表示
      return userName ? userName : loginContextEmail;
    }, [loginContextEmail, userName]);

    const buttonComponent = isSmallDevice ? (
      <IconButton color="inherit" onClick={onIconClicked} size="large" css={{ width: '40px', height: '40px', p: 1 }}>
        <AccountCircle sx={{ fontSize: '24px', color: 'text.secondary' }} />
      </IconButton>
    ) : (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Button
          data-testid="accountMenu"
          onClick={onIconClicked}
          endIcon={<ChevronDown sx={{ color: 'text.secondary' }} />}
          disableElevation
          color="inherit"
          sx={{ [`& .${buttonClasses.endIcon}`]: { marginLeft: '4px' } }}
        >
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Typography color="textSecondary" sx={textSxProps.xs}>
              {domainName}
            </Typography>
            <Typography sx={textSxProps.md}>{displayUserName}</Typography>
          </Box>
        </Button>
      </Box>
    );

    return (
      <Fragment>
        {buttonComponent}
        <Menu anchorEl={anchorElement} anchorOrigin={MenuPosition} open={isOpen} onClose={onMenuClosed}>
          <Box px={2} py={1}>
            <Typography>{displayUserName} としてログイン中</Typography>
            <Typography variant="body2" color="textSecondary">
              {email ? email : loginContextEmail}
            </Typography>
          </Box>
          <MenuItem component="a" href={accountUrl} target="_blank" rel="noopener">
            <Box display="flex" alignItems="center">
              アカウント管理
              <OpenInNew sx={{ fontSize: '1rem', color: 'textSecondary', marginLeft: 1 }} />
            </Box>
          </MenuItem>
          {isSmallDevice && (
            <Fragment>
              <AccountMenuDevider />
              <MenuItem component="a" href={sumaiEntryNaikenUrl} target="_blank" rel="noopener">
                内見一覧
              </MenuItem>
              <MenuItem component="a" href={sumaiEntryMoshikomiUrl} target="_blank" rel="noopener">
                申込一覧
              </MenuItem>
            </Fragment>
          )}
          <AccountMenuDevider />
          <MenuItem component="a" href={ServiceConfigure.termsOfService} target="_blank" rel="noopener">
            <Box display="flex" alignItems="center">
              サービス利用規約
              <OpenInNew sx={{ fontSize: '1rem', color: 'textSecondary', marginLeft: 1 }} />
            </Box>
          </MenuItem>
          <MenuItem component="a" href={ServiceConfigure.privacyPolicy} target="_blank" rel="noopener">
            <Box display="flex" alignItems="center">
              プライバシーポリシー
              <OpenInNew sx={{ fontSize: '1rem', color: 'textSecondary', marginLeft: 1 }} />
            </Box>
          </MenuItem>
          <MenuItem component="a" href={ServiceConfigure.userdataExternalTransmission} target="_blank" rel="noopener">
            <Box display="flex" alignItems="center">
              利用者情報の外部送信について
              <OpenInNew sx={{ fontSize: '1rem', color: 'textSecondary', marginLeft: 1 }} />
            </Box>
          </MenuItem>
          <AccountMenuDevider />
          <MenuItem onClick={onLogout}>ログアウト</MenuItem>
        </Menu>
      </Fragment>
    );
  },
  deepCompare
);
