import { Alert } from '@e-seikatsu/design-system';
import { css } from '@emotion/react';
import { useIsExtraSmallDevice } from '@lib/components';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Close } from 'mdi-material-ui';
import { FC, memo, useCallback, useState } from 'react';
import { Arguments, mutate } from 'swr';

import { useDeletePartner } from '@/Hooks/Esa/usePartners';

type PartnerDeleteDialogContainerProps = {
  isOpenDeleteDialog: boolean;
  closeDeleteDialog: () => void;
  partnerUid?: string;
};

const PartnerDeleteDialogContainer: FC<PartnerDeleteDialogContainerProps> = memo(
  ({ isOpenDeleteDialog, closeDeleteDialog, partnerUid }) => {
    const isExtraSmallDevice = useIsExtraSmallDevice();
    const { deletePartner } = useDeletePartner();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleDeletePartnerApplicants = useCallback(async () => {
      setIsLoading(true);
      await deletePartner(partnerUid ?? '');
      setIsLoading(false);
      closeDeleteDialog();
      await mutate(
        (key: Arguments) => Array.isArray(key) && typeof key[0] === 'string' && key[0].includes('ListChannels')
      );
    }, [closeDeleteDialog, deletePartner, partnerUid]);

    return (
      <PartnerDeleteDialogPresenter
        isExtraSmallDevice={isExtraSmallDevice}
        isOpenDeleteDialog={isOpenDeleteDialog}
        closeDeleteDialog={closeDeleteDialog}
        isLoading={isLoading}
        handleDeletePartnerApplicants={handleDeletePartnerApplicants}
      />
    );
  }
);

export { PartnerDeleteDialogContainer as PartnerDeleteDialog };

type PartnerDeleteDialogPresenterProps = {
  isExtraSmallDevice: boolean;
  isOpenDeleteDialog: boolean;
  closeDeleteDialog: () => void;
  isLoading: boolean;
  handleDeletePartnerApplicants: () => Promise<void>;
};

const dialogTitleStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '50px',
});

const dialogActionStyle = css({
  textAlign: 'right',
});

export const PartnerDeleteDialogPresenter: FC<PartnerDeleteDialogPresenterProps> = memo(
  ({ isExtraSmallDevice, isOpenDeleteDialog, closeDeleteDialog, isLoading, handleDeletePartnerApplicants }) => {
    return (
      <Dialog open={isOpenDeleteDialog} maxWidth="sm" fullWidth fullScreen={isExtraSmallDevice}>
        <DialogTitle css={dialogTitleStyle}>
          <Box display="flex" alignItems="center" fontWeight="bold">
            取引先から削除
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <IconButton onClick={closeDeleteDialog} size="large">
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box mb={1}>取引先から本当に削除してよろしいですか？</Box>
          <Alert color="warning">
            <Alert.Content>
              ・削除の取り消しはできません<br></br>
              ・互いのメッセージ履歴は残りますが、送受信ができなくなります<br></br>
              ・再度取引先に追加したい場合は、もう一度取引先申請から行う必要があります
            </Alert.Content>
          </Alert>
        </DialogContent>
        <Divider />
        <DialogActions css={dialogActionStyle}>
          <Button onClick={closeDeleteDialog} css={{ color: grey[500] }}>
            キャンセル
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            loading={isLoading}
            css={{ marginRight: '8px' }}
            onClick={handleDeletePartnerApplicants}
          >
            取引先から削除する
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
);
