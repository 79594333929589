export const AppState = {
  /** 物件の一覧表示件数 */
  bukkenListItemsPerPage: 'bukkenListItemsPerPage',
  /** 取引先の一覧表示件数 */
  partnerListItemsPerPage: 'partnerListItemsPerPage',
  /** 取引先申請の一覧表示件数 */
  partnerApplicantsListItemsPerPage: 'partnerApplicantsListItemsPerPage',
  /** データ統合Alertの非表示日時 */
  hideIntegrationAlertDate: (userGuid: string) => `${userGuid}/hideIntegrationAlertDate`,
} as const;
