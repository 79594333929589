import { getViewportHeight } from '@app/utils';
import { Loading } from '@lib/components';
import { Box } from '@mui/material';
import { FC, Fragment, memo } from 'react';

import { EmptyPartnerApplicants } from './EmptyPartnerApplicants';
import { PartnerApplicantsItem } from './PartnerApplicantsItem';

import { PartnerApplicants, PartnerApplicantsTypeCollectionType } from '@/Models/Esa/PartnerApplicants';

type PartnerApplicantsListContainerProps = {
  partnerApplicantsList: PartnerApplicants[];
  isLoading: boolean;
  isValidating: boolean;
  totalCount: number;
  partnerApplicantsType: PartnerApplicantsTypeCollectionType;
  isMultipleOrganization: boolean;
  openDialog: () => void;
};

const PartnerApplicantsListContainer: FC<PartnerApplicantsListContainerProps> = memo(
  ({
    partnerApplicantsList,
    isLoading,
    isValidating,
    totalCount,
    partnerApplicantsType,
    isMultipleOrganization,
    openDialog,
  }) => {
    if (isLoading || isValidating) {
      return (
        <Box height={getViewportHeight(75)} width="100%" overflow="hidden">
          <Loading />
        </Box>
      );
    }

    if (totalCount === 0) {
      return (
        <>
          <EmptyPartnerApplicants openDialog={openDialog} />
        </>
      );
    }

    return (
      <PartnerApplicantsListPresenter
        partnerApplicantsList={partnerApplicantsList}
        partnerApplicantsType={partnerApplicantsType}
        isMultipleOrganization={isMultipleOrganization}
      />
    );
  }
);

export { PartnerApplicantsListContainer as PartnerApplicantsList };

type PartnerApplicantsListPresenterProps = {
  partnerApplicantsList: PartnerApplicants[];
  partnerApplicantsType: PartnerApplicantsTypeCollectionType;
  isMultipleOrganization: boolean;
};

export const PartnerApplicantsListPresenter: FC<PartnerApplicantsListPresenterProps> = ({
  partnerApplicantsList,
  partnerApplicantsType,
  isMultipleOrganization,
}) => (
  <Fragment>
    {partnerApplicantsList.map((partnerApplicant: PartnerApplicants) => {
      return (
        <PartnerApplicantsItem
          key={partnerApplicant.partnerApplicantUid}
          partnerApplicantsType={partnerApplicantsType}
          partnerApplicant={partnerApplicant}
          isMultipleOrganization={isMultipleOrganization}
        />
      );
    })}
  </Fragment>
);
