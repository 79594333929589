import { getViewportHeight } from '@app/utils';
import { Icon } from '@e-seikatsu/design-system';
import { Loading, useIsExtraSmallDevice } from '@lib/components';
import { Box, Typography } from '@mui/material';
import { FC, memo, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import UnableForMobile from './UnableForMobilePage';

import { EsaOrganizationList } from '@/Components/Setting/EsaOrganizationList';
import { NotificationSetting } from '@/Components/Setting/NotificationSetting';
import { PartnerAutoApprovalSetting } from '@/Components/Setting/PartnerAutoApprovalSetting';
import { PartnerSearchPublicSetting } from '@/Components/Setting/PartnerSearchPublicSetting';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { useEsaDomainOrganizations } from '@/Hooks/Esa/useEsaDomainOrganizations';
import { EsaOrganization } from '@/Models/Esa/EsaOrganization';
import { GeneralNotFoundPage } from '@/Pages/error/GeneralNotFoundPage';

const SettingPageContainer: FC = memo(() => {
  const isExtraSmallDevice = useIsExtraSmallDevice();
  const navigate = useNavigate();

  const user = useCurrentUser();
  const hasMasterRole = useMemo(() => {
    return user.role.isAdmin;
  }, [user.role.isAdmin]);

  const { organizationUid } = useParams();
  const { organizations, isLoading } = useEsaDomainOrganizations({
    domainUid: user.domainUid,
  });

  const userOrganizations = useMemo(() => {
    if (!organizations) {
      return [];
    }

    if (hasMasterRole) {
      return organizations;
    }

    return organizations?.filter((o: EsaOrganization) => user.organizationUids.includes(o.organizationUid));
  }, [organizations, user.organizationUids, hasMasterRole]);

  const settingOrganization = useMemo(() => {
    if (organizationUid) {
      return userOrganizations?.filter((o: EsaOrganization) => o.organizationUid === organizationUid)[0];
    }
    return userOrganizations?.filter((o: EsaOrganization) => o.organizationUid === user.defaultOrganizationUid)[0];
  }, [organizationUid, user.defaultOrganizationUid, userOrganizations]);

  useEffect(() => {
    if (!organizationUid && userOrganizations && userOrganizations.length > 0) {
      navigate(`/setting/${user.defaultOrganizationUid}`);
    }
  }, [navigate, organizationUid, user.defaultOrganizationUid, userOrganizations]);

  const uids = userOrganizations.map((o: EsaOrganization) => o.organizationUid);
  const isContainedOrganization = organizationUid ? uids.includes(organizationUid) : false;

  if (!organizationUid || isLoading) {
    return (
      <Box height={getViewportHeight(75)} width="100%" overflow="hidden">
        <Loading />
      </Box>
    );
  }

  // 設定画面を表示しようとしている組織がログインユーザーの所属組織かを判定する
  if (!isContainedOrganization) {
    return <GeneralNotFoundPage />;
  }

  return isExtraSmallDevice ? (
    <UnableForMobile />
  ) : (
    <SettingPagePresenter organizationList={userOrganizations} settingOrganization={settingOrganization} />
  );
});

export { SettingPageContainer as SettingPage };

type SettingPagePresenterProps = {
  organizationList: EsaOrganization[];
  settingOrganization: EsaOrganization;
};

export const SettingPagePresenter: FC<SettingPagePresenterProps> = memo(({ organizationList, settingOrganization }) => (
  <Box display="flex" flexDirection="column" minHeight="100%" maxWidth="100%" pt={3} px={3} gap={2}>
    <Box display="flex" alignItems="center" gap={1}>
      <Typography fontWeight="bold" fontSize="20px">
        設定
      </Typography>
      <Icon iconName="chevronRight" size="large" color="black" />
      <EsaOrganizationList
        organizationList={organizationList}
        settingOrganizationUid={settingOrganization.organizationUid}
      />
    </Box>
    <PartnerSearchPublicSetting settingOrganization={settingOrganization} />
    <PartnerAutoApprovalSetting settingOrganization={settingOrganization} />
    <NotificationSetting />
  </Box>
));
