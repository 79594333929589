import { Address } from '@app/models';
import { useIsExtraSmallDevice } from '@lib/components';
import { Autocomplete, TextField } from '@mui/material';
import { FC, memo } from 'react';
import { useAsync } from 'react-use';

import { AreaItem } from './AddPartnerOrganizationDialog';

import { useDependency } from '@/Hooks/DependencyHook';
import { Prefecture } from '@/Services/IMasterApiService';

type PartnerCitySelectorContainerProps = {
  currentPref: Prefecture | null;
  currentCity: Address | null;
  prefCode: number;
  onChange: (pref: AreaItem) => void;
  handleIsInputEdit: (bool: boolean) => void;
};

const PartnerCitySelectorContainer: FC<PartnerCitySelectorContainerProps> = memo(
  ({ currentPref, currentCity, prefCode, onChange, handleIsInputEdit }) => {
    const isExtraSmallDevice = useIsExtraSmallDevice();
    const masterApiService = useDependency('masterApiService');
    const cities = useAsync(() => masterApiService.getCities(prefCode), [masterApiService, prefCode]).value ?? [];

    return (
      <PartnerCitySelectorPresenter
        isExtraSmallDevice={isExtraSmallDevice}
        cities={cities}
        pref={currentPref}
        city={currentCity}
        onChange={onChange}
        handleIsInputEdit={handleIsInputEdit}
      />
    );
  }
);

export { PartnerCitySelectorContainer as PartnerCitySelector };

type PartnerCitySelectorPresenterProps = {
  isExtraSmallDevice: boolean;
  cities: readonly Address[];
  pref: Prefecture | null;
  city: Address | null;
  onChange: (value: AreaItem) => void;
  handleIsInputEdit: (bool: boolean) => void;
};

export const PartnerCitySelectorPresenter: FC<PartnerCitySelectorPresenterProps> = memo(
  ({ isExtraSmallDevice, cities, pref, city, onChange, handleIsInputEdit }) => {
    return (
      <Autocomplete
        closeText="閉じる"
        noOptionsText="候補なし"
        size="small"
        value={city}
        options={cities}
        onChange={(_, newValue) => onChange({ pref: pref, city: newValue })}
        getOptionLabel={option => option.city_name}
        isOptionEqualToValue={(option, value) => {
          return option.city_name === value.city_name;
        }}
        onFocus={() => handleIsInputEdit(true)}
        onBlur={() => handleIsInputEdit(false)}
        renderInput={params => <TextField {...params} variant="outlined" placeholder="市区郡を入力" />}
        css={{ minWidth: '200px' }}
        fullWidth={isExtraSmallDevice}
      />
    );
  }
);
