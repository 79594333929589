import { css } from '@emotion/react';
import { useIsExtraSmallDevice } from '@lib/components';
import { Box, Chip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { parseISO } from 'date-fns';
import { Reply } from 'mdi-material-ui';
import { FC, Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  PartnerApplicants,
  PartnerApplicantsStatusCollection,
  PartnerApplicantsTypeCollection,
  PartnerApplicantsTypeCollectionType,
} from '@/Models/Esa/PartnerApplicants';
import { formatDateTime } from '@/Utils/DisplayText/Date';
import { getPartnerApplicantsStatusChip } from '@/Utils/PartnerApplicantsUtils';

type PartnerApplicantsItemContainerProps = {
  partnerApplicantsType: PartnerApplicantsTypeCollectionType;
  partnerApplicant: PartnerApplicants;
  isMultipleOrganization: boolean;
};

const PartnerApplicantsItemContainer: FC<PartnerApplicantsItemContainerProps> = ({
  partnerApplicantsType,
  partnerApplicant,
  isMultipleOrganization,
}) => {
  const isExtraSmallDevice = useIsExtraSmallDevice();
  const navigate = useNavigate();
  const openDialog = useCallback(
    (partnerApplicantUid: string) => {
      navigate(`${partnerApplicantUid}`);
    },
    [navigate]
  );

  const partnerApplicantDomainName = useMemo(() => {
    if (partnerApplicantsType === PartnerApplicantsTypeCollection.sent) {
      return partnerApplicant.partnerDomain.name;
    }

    return partnerApplicant.domain.name;
  }, [partnerApplicant.domain.name, partnerApplicant.partnerDomain.name, partnerApplicantsType]);

  const partnerApplicantOrganizationName = useMemo(() => {
    if (partnerApplicantsType === PartnerApplicantsTypeCollection.sent) {
      return partnerApplicant.partnerOrganization.name;
    }

    return partnerApplicant.organization.name;
  }, [partnerApplicant.organization.name, partnerApplicant.partnerOrganization.name, partnerApplicantsType]);

  const partnerApplicantOrganizationBusinessName = useMemo(() => {
    if (partnerApplicantsType === PartnerApplicantsTypeCollection.sent) {
      return partnerApplicant.partnerOrganization.businessName;
    }

    return partnerApplicant.organization.businessName;
  }, [
    partnerApplicant.organization.businessName,
    partnerApplicant.partnerOrganization.businessName,
    partnerApplicantsType,
  ]);

  const partnerApplicantsStatusChip = getPartnerApplicantsStatusChip(partnerApplicantsType, partnerApplicant.state);

  return isExtraSmallDevice ? (
    <PartnerApplicantsItemMobilePresenter
      partnerApplicantsType={partnerApplicantsType}
      openDialog={openDialog}
      partnerApplicant={partnerApplicant}
      partnerApplicantDomainName={partnerApplicantDomainName}
      partnerApplicantOrganizationName={partnerApplicantOrganizationName}
      partnerApplicantOrganizationBusinessName={partnerApplicantOrganizationBusinessName}
      partnerApplicantsStatusChip={partnerApplicantsStatusChip}
      isMultipleOrganization={isMultipleOrganization}
    />
  ) : (
    <PartnerApplicantsItemPresenter
      partnerApplicantsType={partnerApplicantsType}
      openDialog={openDialog}
      partnerApplicant={partnerApplicant}
      partnerApplicantDomainName={partnerApplicantDomainName}
      partnerApplicantOrganizationName={partnerApplicantOrganizationName}
      partnerApplicantOrganizationBusinessName={partnerApplicantOrganizationBusinessName}
      partnerApplicantsStatusChip={partnerApplicantsStatusChip}
      isMultipleOrganization={isMultipleOrganization}
    />
  );
};

export { PartnerApplicantsItemContainer as PartnerApplicantsItem };

type PartnerApplicantsItemPresenterProps = {
  partnerApplicantsType: PartnerApplicantsTypeCollectionType;
  openDialog: (partnerApplicantUid: string) => void;
  partnerApplicant: PartnerApplicants;
  partnerApplicantDomainName: string;
  partnerApplicantOrganizationName: string;
  partnerApplicantOrganizationBusinessName: string | null;
  partnerApplicantsStatusChip?: {
    label: string;
    color: string;
  };
  isMultipleOrganization: boolean;
};

const tagStyle = (backGroundcolor?: string) =>
  css({
    borderRadius: '2px',
    height: '20px',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'white',
    background: backGroundcolor,
  });

const commentStyle = css({
  display: '-webkit-box',
  overflow: 'hidden',
  lineHeight: 1.2,
  /* eslint-disable @typescript-eslint/naming-convention */
  WebkitLineClamp: '1',
  // NOTE: WebkitBoxOrient は非推奨だが、CSS で行数指定の省略は他に方法がないため、使用する
  WebkitBoxOrient: 'vertical',
  /* eslint-enable @typescript-eslint/naming-convention */
});

export const PartnerApplicantsItemPresenter: FC<PartnerApplicantsItemPresenterProps> = ({
  partnerApplicantsType,
  openDialog,
  partnerApplicant,
  partnerApplicantDomainName,
  partnerApplicantOrganizationName,
  partnerApplicantOrganizationBusinessName,
  partnerApplicantsStatusChip,
  isMultipleOrganization,
}) => (
  <Fragment>
    <Box
      display="grid"
      gridTemplateColumns={isMultipleOrganization ? '0.3fr 3fr 2.5fr 1fr 1.4fr' : '0.3fr 3fr 2.5fr 1.4fr'}
      gridAutoRows="100%"
      alignItems="center"
      sx={{
        backgroundColor: theme => theme.palette.background.paper,
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: `${grey[200]}`,
        },
      }}
      borderBottom={`1px solid ${grey[200]}`}
      pl={1}
      py={1}
      mx={2}
      minHeight="68px"
      onClick={() => openDialog(partnerApplicant.partnerApplicantUid)}
      borderRadius="4px"
    >
      <Box minWidth="60px" mr={2} ml={1}>
        <Chip
          size="small"
          label={partnerApplicantsStatusChip?.label}
          css={tagStyle(partnerApplicantsStatusChip?.color)}
        />
      </Box>
      <Box mx={2} fontSize="16px" fontWeight="bold" css={{ wordBreak: 'break-all' }}>
        {partnerApplicantOrganizationBusinessName ? (
          <>{partnerApplicantOrganizationBusinessName}</>
        ) : (
          <>
            {partnerApplicantDomainName} {partnerApplicantOrganizationName}
          </>
        )}
      </Box>
      <Box mx={1} maxWidth="30vw">
        <Box css={commentStyle}>{partnerApplicant.comment}</Box>
        {partnerApplicant.state === PartnerApplicantsStatusCollection.rejected && partnerApplicant.rejectionComment && (
          <Box display="flex" alignItems="center" color="GrayText" mt={1}>
            <Reply />
            <Box css={commentStyle}>{partnerApplicant.rejectionComment}</Box>
          </Box>
        )}
      </Box>
      {isMultipleOrganization && (
        <Box textAlign="center" color="GrayText">
          {partnerApplicantsType === PartnerApplicantsTypeCollection.sent
            ? partnerApplicant.organization.name
            : partnerApplicant.partnerOrganization.name}
        </Box>
      )}
      <Box mr={4} textAlign="right" color="GrayText">
        {formatDateTime(parseISO(partnerApplicant.modifiedAt))}
      </Box>
    </Box>
  </Fragment>
);

export const PartnerApplicantsItemMobilePresenter: FC<PartnerApplicantsItemPresenterProps> = ({
  partnerApplicantsType,
  openDialog,
  partnerApplicant,
  partnerApplicantDomainName,
  partnerApplicantOrganizationName,
  partnerApplicantOrganizationBusinessName,
  partnerApplicantsStatusChip,
  isMultipleOrganization,
}) => (
  <Fragment>
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="space-around"
      sx={{
        flexFlow: 'column',
        backgroundColor: theme => theme.palette.background.paper,
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: `${grey[200]}`,
        },
      }}
      borderBottom={`1px solid ${grey[200]}`}
      pl={1}
      py={1}
      minHeight="158px"
      onClick={() => openDialog(partnerApplicant.partnerApplicantUid)}
      borderRadius="4px"
    >
      <Chip
        size="small"
        label={partnerApplicantsStatusChip?.label}
        css={tagStyle(partnerApplicantsStatusChip?.color)}
        sx={{ marginLeft: '8px', width: '60px' }}
      />
      <Box m={1} fontSize="16px" fontWeight="bold" css={{ wordBreak: 'break-all' }}>
        {partnerApplicantOrganizationBusinessName ? (
          <>{partnerApplicantOrganizationBusinessName}</>
        ) : (
          <>
            {partnerApplicantDomainName} {partnerApplicantOrganizationName}
          </>
        )}
      </Box>
      <Box m={1}>
        <Box css={commentStyle}>{partnerApplicant.comment}</Box>
        {partnerApplicant.state === PartnerApplicantsStatusCollection.rejected && partnerApplicant.rejectionComment && (
          <Box display="flex" alignItems="center" color="GrayText" mt={1}>
            <Reply />
            <Box css={commentStyle}>{partnerApplicant.rejectionComment}</Box>
          </Box>
        )}
      </Box>
      {isMultipleOrganization && (
        <Box ml={1} color="GrayText">
          担当：
          {partnerApplicantsType === PartnerApplicantsTypeCollection.sent
            ? partnerApplicant.organization.name
            : partnerApplicant.partnerOrganization.name}
        </Box>
      )}
      <Box m={1} color="GrayText">
        {formatDateTime(parseISO(partnerApplicant.modifiedAt))}
      </Box>
    </Box>
  </Fragment>
);
