import { Button } from '@e-seikatsu/design-system';
import { Box, Tooltip } from '@mui/material';
import { FC, memo, ReactNode } from 'react';

import { EsaOrganization } from '@/Models/Esa/EsaOrganization';

type SettingCommonDisplayProps = {
  openDialog: () => void;
  dataGuide: string;
  isDisableEditButton: boolean;
  toolTipMessage: string;
  settingOrganization: EsaOrganization;
  settingTitle: string;
  settingContent: ReactNode;
};

export const SettingCommonDisplay: FC<SettingCommonDisplayProps> = memo(
  ({ openDialog, dataGuide, isDisableEditButton, toolTipMessage, settingTitle, settingContent }) => (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="var(--color-semantic-bg-white)"
      border="solid 1px var(--color-semantic-border-divider)"
      borderRadius="var(--radius-content-box)"
      p={3}
      gap={1}
      data-guide={dataGuide}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Box fontWeight="bold" fontSize="20px">
          {settingTitle}
        </Box>
        <Tooltip title={toolTipMessage} placement="top" disableInteractive>
          <span>
            <Button
              variant="outlined"
              size="small"
              onClick={openDialog}
              prependIcon="edit"
              disabled={isDisableEditButton}
            >
              編集
            </Button>
          </span>
        </Tooltip>
      </Box>
      {settingContent}
    </Box>
  )
);
