import { Alert } from '@e-seikatsu/design-system';
import { ServiceConfigure as Configure } from '@lib/components/src/Consts/Configure';
import { Link } from '@mui/material';
import { OpenInNew } from 'mdi-material-ui';
import { FC, memo, useCallback, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

import { AppState } from '@/Consts/AppState';
import { useLoginContext } from '@/Hooks/Esa/RequireLoginContext';
import { ServiceConfigure } from '@/Services/Configure';
import { formatDate, isFutureNowDate, isNowDate } from '@/Utils/DisplayText/Date';
import { enableIntegrateAccount, enableIntegrateDomain } from '@/Utils/LoginContextUtils';

const IntegrationAlertContainer: FC = memo(() => {
  const loginContext = useLoginContext();
  const isEnableIntegrateDomain = enableIntegrateDomain(loginContext);
  const isEnableIntegrateAccount = enableIntegrateAccount(loginContext);

  const [hideIntegrationAlertDate, setHideIntegrationAlertDate] = useLocalStorage<string | undefined>(
    AppState.hideIntegrationAlertDate(loginContext.currentUserUid ?? ''),
    undefined
  );
  const isShowIntegrationAlert = useMemo(() => {
    return (
      hideIntegrationAlertDate === undefined ||
      (!isFutureNowDate(hideIntegrationAlertDate) && !isNowDate(hideIntegrationAlertDate))
    );
  }, [hideIntegrationAlertDate]);

  const onClose = useCallback(() => {
    const now = formatDate(new Date());
    setHideIntegrationAlertDate(now);
  }, [setHideIntegrationAlertDate]);

  return (
    <IntegrationAlertPresenter
      isEnableIntegrateDomain={isEnableIntegrateDomain}
      isEnableIntegrateAccount={isEnableIntegrateAccount}
      isShowIntegrationAlert={isShowIntegrationAlert}
      onClose={onClose}
    />
  );
});

export { IntegrationAlertContainer as IntegrationAlert };

type IntegrationAlertPresenterProps = {
  isEnableIntegrateDomain: boolean;
  isEnableIntegrateAccount: boolean;
  isShowIntegrationAlert: boolean;
  onClose: () => void;
};

export const IntegrationAlertPresenter: FC<IntegrationAlertPresenterProps> = memo(
  ({ isEnableIntegrateDomain, isEnableIntegrateAccount, isShowIntegrationAlert, onClose }) => (
    <>
      {isShowIntegrationAlert && isEnableIntegrateDomain && (
        <Alert color="info" onClose={onClose} isTopFixed={true}>
          <Alert.Title>いい生活アカウントのデータ統合を完了してください</Alert.Title>
          <Alert.Content>
            <>
              複数の法人情報が登録されています。
              {isEnableIntegrateAccount ? (
                <>
                  メンバーや組織情報を正しく管理するために、
                  <Link
                    href={`${ServiceConfigure.esAccountUrl}/integration/plans`}
                    target="_blank"
                    rel="noopener noreferrer"
                    display="inline"
                  >
                    法人情報を統合
                    <OpenInNew css={{ verticalAlign: 'middle', fontSize: '16px' }} />
                  </Link>
                  してください。
                </>
              ) : (
                <>アカウント管理者にお知らせのうえ、法人情報を統合してください。</>
              )}
              （
              <Link
                href={`${Configure.esAccountSupportUrl}/knowledge-base/activation/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                サポートサイト
                <OpenInNew css={{ verticalAlign: 'middle', fontSize: '16px' }} />
              </Link>
              ）
            </>
          </Alert.Content>
        </Alert>
      )}
    </>
  )
);
