import { Alert } from '@e-seikatsu/design-system';
import { Box, Collapse, Link } from '@mui/material';
import { useState, FC } from 'react';

import { domainNameGuidanceSupportSiteUrl } from '@/Consts/ExternalSiteUrls';
import { ServiceConfigure } from '@/Services/Configure';

const localSxProps = {
  alertText: {
    outline: 'solid 1px rgba(0,0,0,0.12)',
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: 2,
    },
  },
  closeIcon: {
    marginBottom: 'auto',
  },
} as const;

const GuidanceAlertContainer: FC = () => {
  const [isAlertOpen, setIsAlertOpen] = useState(true);
  const esAccountOrganizationUrl = ServiceConfigure.esAccountUrl + '/organizations';
  return (
    <GuidanceAlertPresenter
      isAlertOpen={isAlertOpen}
      setIsAlertOpen={setIsAlertOpen}
      esAccountOrganizationUrl={esAccountOrganizationUrl}
    />
  );
};

export { GuidanceAlertContainer as GuidanceAlert };

type GuidanceAlertPresenterProps = {
  isAlertOpen: boolean;
  setIsAlertOpen: (value: boolean) => void;
  esAccountOrganizationUrl: string;
};
const GuidanceAlertPresenter: FC<GuidanceAlertPresenterProps> = ({
  isAlertOpen,
  setIsAlertOpen,
  esAccountOrganizationUrl,
}) => {
  return (
    <Collapse in={isAlertOpen}>
      <Box p={2} sx={localSxProps.root}>
        <Alert
          color="warning"
          onClose={() => {
            setIsAlertOpen(false);
          }}
        >
          <Alert.Title>
            <Box fontSize="18px">2022年9月1日 不動産の公正競争規約 改正にともなう所属団体記載のお願い</Box>
          </Alert.Title>
          <Alert.Content>
            <div>規約改正によりインターネット広告に所属団体名の記載が定められました。</div>
            <div>
              貴社帯図面に反映するため、管理者の方は
              <Link
                href={esAccountOrganizationUrl}
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
                color="inherit"
              >
                組織情報編集画面
              </Link>
              から所属団体名の登録をお願いします。
              <Link
                href={domainNameGuidanceSupportSiteUrl}
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
                color="inherit"
              >
                詳しくはこちら
              </Link>
            </div>
          </Alert.Content>
        </Alert>
      </Box>
    </Collapse>
  );
};
