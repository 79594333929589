import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getViewportHeight } from '@app/utils';
import { Box, Container } from '@mui/material';
import { Component } from 'react';
import { uncaughtErrorMessageHeader, uncaughtErrorMessageText } from '../Consts/Messages';
import { ErrorMessage } from './ErrorMessage';
export class UncaughtErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    componentDidCatch(error, info) {
        this.props.reportError(error, info.componentStack, true);
        this.setState({ hasError: true });
    }
    render() {
        if (this.state.hasError) {
            return (_jsx(Box, Object.assign({ display: "flex", alignItems: "center", height: getViewportHeight(100) }, { children: _jsx(Container, { children: _jsx(ErrorMessage, { header: uncaughtErrorMessageHeader, mainMessage: uncaughtErrorMessageText }) }) })));
        }
        return this.props.children;
    }
}
