import { NotFoundError } from '@app/errors';
import { Loading } from '@lib/components';
import { Box } from '@mui/material';
import { FC } from 'react';
import { generatePath } from 'react-router';
import { Navigate, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';

import { useDependency } from '@/Hooks/DependencyHook';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { OrganizationMap, useEsaDomainOrganizations } from '@/Hooks/Esa/useEsaDomainOrganizations';
import { getInitialChintaiSearchConditions } from '@/Models/SearchConditions/ChintaiBukkenSearchConditions';
import { RouteDefinitions } from '@/Pages/RouteDefinitions';

type PropertyFullKeyRedirectParams = {
  pfk: string;
};

const PropertyFullKeyRedirect: FC = () => {
  const { pfk: propertyFullKey } = useParams<PropertyFullKeyRedirectParams>();
  const bukkenApiService = useDependency('bukkenApiService');
  const userInfo = useCurrentUser();
  const { organizations } = useEsaDomainOrganizations({
    domainUid: userInfo.domainUid,
  });
  const organizationMap: OrganizationMap = new Map(
    organizations?.map(organization => [organization.organizationUid, organization])
  );
  const defaultOrganization = organizationMap.get(userInfo.defaultOrganizationUid);
  const bukkenGuidFetchStatus = useAsync(async (): Promise<string> => {
    const params = getInitialChintaiSearchConditions();
    params.propertyFullKey = propertyFullKey ?? '';
    params.organizationGuid = defaultOrganization?.organizationUid ?? '';
    const result = await bukkenApiService.searchChintaiBukken(params, {});
    if (result.items.length === 0) {
      throw new NotFoundError('いい生活番号に該当する賃借物件');
    }

    return result.items[0].chinshaku_bukken_view.chinshaku_bukken_guid;
  });

  if (bukkenGuidFetchStatus.loading) {
    return <Loading />;
  }

  if (bukkenGuidFetchStatus.error) {
    return (
      <Box p={2} textAlign="center">
        <p>{bukkenGuidFetchStatus.error.message}</p>
      </Box>
    );
  }

  return (
    <Navigate
      to={generatePath(RouteDefinitions.chintaiBukkenDetail.path, {
        guid: bukkenGuidFetchStatus.value,
      })}
    />
  );
};

export default PropertyFullKeyRedirect;
