import { useCallback, useState } from 'react';
/**
 * bool 値の状態、及び状態を変更するための不変な関数を返す Hook です。
 * イベントハンドラが bool 状態の変更を行う場合の最適化や、
 * トグル関数が必要な場合などで使用します。
 * @param init 初期状態
 */
export const useOnOffState = init => {
    const [state, setState] = useState(init);
    const setTrue = useCallback(() => setState(true), []);
    const setFalse = useCallback(() => setState(false), []);
    const toggle = useCallback(() => setState(s => !s), []);
    return [state, { setTrue, setFalse, toggle }];
};
