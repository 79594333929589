import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { CellphoneOff } from 'mdi-material-ui';
import { FC, memo } from 'react';

import { theme } from '@/Theme';

const rootStyle = css({
  bgcolor: 'background.default',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: theme.spacing(10),
});

const iconStyle = css({
  color: theme.palette.grey[400],
  fontSize: theme.typography.pxToRem(80),
  marginBottom: theme.spacing(2),
});

const UnableForMobile: FC = memo(() => (
  <Box css={rootStyle}>
    <CellphoneOff css={iconStyle} />
    <Typography fontSize="16px" align="center">
      スマートフォンでは
      <br />
      こちらの画面にアクセスできません
      <br />
      <br />
      お手数ですがPCからご利用ください
    </Typography>
  </Box>
));

export default UnableForMobile;
