import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { deepCompare } from '@app/utils';
import { Chip, chipClasses } from '@mui/material';
import { Close } from 'mdi-material-ui';
import { memo, useMemo } from 'react';
import { textSxProps } from '../Styles';
export const ConditionBadge = memo(props => {
    const onDelete = useMemo(() => {
        if (props.onDelete === undefined) {
            return undefined;
        }
        return () => {
            if (props.onDelete === undefined) {
                return;
            }
            if (Array.isArray(props.onDelete)) {
                props.onDelete.forEach(it => it());
            }
            else {
                props.onDelete();
            }
        };
    }, [props]);
    return (_jsx(Chip, { sx: {
            margin: '2px',
            maxWidth: '100%',
            bgcolor: 'secondary.main',
            color: 'primary.main',
            [`& .${chipClasses.label}`]: textSxProps.sm,
        }, label: props.label, deleteIcon: _jsx(Close, { sx: {
                color: 'primary.main',
                width: '1.25rem',
                height: '1.25rem',
            } }), onDelete: onDelete }));
}, deepCompare);
