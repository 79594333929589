export type ErrorMessageObject = {
  messageHeader: string;
  messageText: string;
};

export const networkOtherErrorMessageHeader = 'サーバーでエラーが発生しました';
export const networkOtherErrorMessageText = 'ご迷惑をおかけいたしますが、しばらく経ってから再度アクセスしてください';

export const getCityDataErrorMessageHeader = '市区郡情報を取得できません';
export const getOoazaDataErrorMessageHeader = '町名情報を取得できません';
export const getLineDataErrorMessageHeader = '路線情報を取得できません';
export const getStationDataErrorMessageHeader = '駅情報を取得できません';

export const loginFailureMessageTexts = [
  'ログインに失敗しました。',
  '恐れ入りますが、サポートサイトをご確認いただき、再度ログインをお願いいたします。',
] as const;

// 物件検索
const searchErrorMessageHeader = '検索中にエラーが発生しました';
export const BukkenSearchErrorMessage = Object.freeze({
  default: Object.freeze<ErrorMessageObject>({
    messageHeader: searchErrorMessageHeader,
    messageText: 'ご迷惑をおかけいたしますが、しばらく経ってから再度検索してください',
  }),
  tooManyConditions: Object.freeze<ErrorMessageObject>({
    messageHeader: searchErrorMessageHeader,
    messageText: '検索条件が多すぎるため、エリアや沿線、その他条件を減らして再度お試しください',
  }),
  tooManySearchResults: Object.freeze<ErrorMessageObject>({
    messageHeader: searchErrorMessageHeader,
    messageText: '検索結果が多すぎるため、エリアや沿線、その他条件を調整して再度お試しください',
  }),
});

// 内見/申込管理 リダイレクト
const SumaiEntryRedirectErrorMessageHeader = 'いい生活Square 内見/申込管理 への遷移中にエラーが発生しました';
export const SumaiEntryRedirectErrorMessage = Object.freeze({
  default: Object.freeze<ErrorMessageObject>({
    messageHeader: SumaiEntryRedirectErrorMessageHeader,
    messageText: 'ご迷惑をおかけいたしますが、しばらく経ってから再度お試しください',
  }),
  notFound: Object.freeze<ErrorMessageObject>({
    messageHeader: SumaiEntryRedirectErrorMessageHeader,
    messageText: '物件が見つかりませんでした。掲載終了となった可能性があります。',
  }),
});

// 組織検索
const searchOrganizationErrorMessageHeader = '検索中にエラーが発生しました';
export const OrganizationSearchErrorMessage = Object.freeze({
  default: Object.freeze<ErrorMessageObject>({
    messageHeader: searchOrganizationErrorMessageHeader,
    messageText: 'ご迷惑をおかけいたしますが、しばらく経ってから再度検索してください',
  }),
  tooManySearchResults: Object.freeze<ErrorMessageObject>({
    messageHeader: searchOrganizationErrorMessageHeader,
    messageText: '検索結果が多すぎるため、条件を調整して再度お試しください',
  }),
});
