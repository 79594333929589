import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { isNotNullOrUndefined, deepCompare } from '@app/utils';
import { Alert } from '@e-seikatsu/design-system';
import { Box } from '@mui/material';
import { memo } from 'react';
export const NavitimeMap = memo(props => {
    var _a, _b;
    return isNotNullOrUndefined(window.navitime) && isNotNullOrUndefined(props.hook.mapRef) ? (_jsx(Box, { ref: props.hook.mapRef, id: props.hook.mapID, sx: {
            height: {
                xs: props.fullScreen ? '100%' : '15rem',
                md: props.fullScreen ? '100%' : '30rem',
            },
            width: props.fullScreen ? '100%' : 'inherit',
            '& .ntgeo-parent': {
                '& .ntgeo-zoombar': {
                    left: ((_a = props.zoomBarOption) === null || _a === void 0 ? void 0 : _a.left) ? `${props.zoomBarOption.left} !important` : '',
                    top: ((_b = props.zoomBarOption) === null || _b === void 0 ? void 0 : _b.top) ? `${props.zoomBarOption.top} !important` : '',
                },
            },
        } })) : (_jsx(Alert, Object.assign({ color: "error" }, { children: _jsx(Alert.Content, { children: "\u5730\u56F3\u306E\u8AAD\u307F\u8FBC\u307F\u306B\u5931\u6557\u3057\u307E\u3057\u305F" }) })));
}, deepCompare);
