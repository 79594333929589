import { getViewportHeight } from '@app/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { EsLoading } from '@lib/components';
import { Box, CssBaseline, styled } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { FC, Fragment, useEffect, useState } from 'react';

import { ReloadNotifierProvider } from './Components/Snackbar/ReloadNotification';

import { SnackMessage } from '@/Components/NotistackContent';
import { DependencyProvider } from '@/Hooks/DependencyHook';
import RootSwitch from '@/Pages/routes';
import { ServiceConfigure } from '@/Services/Configure';
import type { Services } from '@/Services/SetupDependencies';

// SP版のウィンドウ幅ではanchorOriginのhorizontal: centerがNotistack側で無効化されるため強制的に上書きしている
export const CenteredSnackbarProvider = styled(SnackbarProvider)({
  '& .anchorOriginTopCenter': {
    left: '50% !important',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '& .anchorOriginBottomCenter': {
    left: '50% !important',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const App: FC = () => {
  const [services, setServices] = useState<Services | null>(null);
  const { logout, getAccessTokenSilently, isAuthenticated, user } = useAuth0();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    import(/* webpackChunkName: "SetupDependencies" */ './Services/SetupDependencies')
      .then(module => {
        setIsLoading(false);
        return module.setupDependencies(
          ServiceConfigure,
          getAccessTokenSilently,
          logout,
          isAuthenticated,
          isLoading,
          user?.sub
        );
      })
      .then(services => {
        setServices(services);
      });
  }, [getAccessTokenSilently, logout, isAuthenticated, isLoading, user?.sub]);

  if (isLoading) {
    return (
      <Box height={getViewportHeight(100)} width="100%" overflow="hidden">
        <EsLoading />
      </Box>
    );
  }

  return (
    <Fragment>
      <CssBaseline />
      {services !== null ? (
        <DependencyProvider services={services}>
          <CenteredSnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            content={(key, message) => <SnackMessage id={key} message={message} />}
          >
            <ReloadNotifierProvider>
              <RootSwitch />
            </ReloadNotifierProvider>
          </CenteredSnackbarProvider>
        </DependencyProvider>
      ) : (
        <Box height={getViewportHeight(100)} width="100%" overflow="hidden">
          <EsLoading />
        </Box>
      )}
    </Fragment>
  );
};

export default App;
