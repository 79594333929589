import { isNotNullOrUndefined } from '@app/utils';
import { formatRFC3339, isValid, parseISO, startOfDay } from 'date-fns';

import { isEkitohoEnable } from './ConditionMapperUtils';

import {
  asBukkenDateRangeRadioState,
  asChintaiResultOrder,
  BukkenDateRangeRadioState,
  ChintaiResultOrder,
  KozoConditions,
  MadoriConditions,
  SearchBoshuShubetsuConditions,
  GenkyoConditions,
  BukkenNyukyoKanoDateRangeRadioState,
  asBukkenNyukyoKanoDateRangeRadioState,
  B2bKokaiCodeRadioState,
  B2bKokaiCodeRadioStateType,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchConditionEnums';
import {
  asKodawariConditions,
  KodawariConditions,
} from '@/Models/SearchConditions/ChintaiSearchConditionEnums/ChintaiSearchKodawariConditionEnums';
import {
  ChintaiSearchConditions,
  MapAreaConditions,
  MapSearchConditions,
  PlaceAndLineCondition,
} from '@/Models/SearchConditions/ChintaiSearchConditions';

type ChintaiSearchConditionKeys = keyof ChintaiSearchConditions;

type ConditionPageQueryMapperDefinition<C> = {
  queryName: string;
  pageQueryValueGetter: (condition: C, options?: PageQueryValueGetterOptions) => string[];
  pageQueryValueSetter: (rawValue: string[], condition: C, options?: PageQueryValueSetterOptions) => void;
};

export type PageQueryValueGetterOptions = {
  isMapAreaConditionPrior?: boolean;
  isAddressConditionPrior?: boolean;
};

export type PageQueryValueSetterOptions = {
  ignorePlaceAndLineCondition?: boolean;
};

const toBoolean = (booleanStr: string): boolean => {
  return booleanStr.toLowerCase() === 'true';
};

const queryValueToDateCondition = (value: string[]): Date | undefined => {
  if (value.length === 0) {
    return undefined;
  }
  const parsed = parseISO(value[0]);
  return isValid(parsed) ? parsed : undefined;
};

const getNumberFromPageQueryValue = (value: string[]): number | undefined => {
  if (value.length === 0) {
    return undefined;
  } else {
    const parsed = Number(value[0]);
    return isNaN(parsed) ? undefined : parsed;
  }
};

const MapAreaConditionsPageQueryMappers: Record<
  keyof MapAreaConditions,
  ConditionPageQueryMapperDefinition<ChintaiSearchConditions>
> = {
  jgsLatitudeFrom: {
    queryName: 'ido.from',
    pageQueryValueGetter: (c: ChintaiSearchConditions, options): string[] => {
      if (
        options?.isAddressConditionPrior === true &&
        (c.stations.length > 0 || c.lines.length > 0 || c.places.length > 0)
      ) {
        return [];
      }
      return c.jgsLatitudeFrom !== undefined ? [c.jgsLatitudeFrom.toString()] : [];
    },
    pageQueryValueSetter: (v: string[], c: MapAreaConditions): void => {
      c.jgsLatitudeFrom = getNumberFromPageQueryValue(v);
    },
  },
  jgsLatitudeTo: {
    queryName: 'ido.to',
    pageQueryValueGetter: (c: ChintaiSearchConditions, options): string[] => {
      if (
        options?.isAddressConditionPrior === true &&
        (c.stations.length > 0 || c.lines.length > 0 || c.places.length > 0)
      ) {
        return [];
      }
      return c.jgsLatitudeTo !== undefined ? [c.jgsLatitudeTo.toString()] : [];
    },
    pageQueryValueSetter: (v: string[], c: MapAreaConditions): void => {
      c.jgsLatitudeTo = getNumberFromPageQueryValue(v);
    },
  },
  jgsLongitudeFrom: {
    queryName: 'keido.from',
    pageQueryValueGetter: (c: ChintaiSearchConditions, options): string[] => {
      if (
        options?.isAddressConditionPrior === true &&
        (c.stations.length > 0 || c.lines.length > 0 || c.places.length > 0)
      ) {
        return [];
      }
      return c.jgsLongitudeFrom !== undefined ? [c.jgsLongitudeFrom.toString()] : [];
    },
    pageQueryValueSetter: (v: string[], c: MapAreaConditions): void => {
      c.jgsLongitudeFrom = getNumberFromPageQueryValue(v);
    },
  },
  jgsLongitudeTo: {
    queryName: 'keido.to',
    pageQueryValueGetter: (c: ChintaiSearchConditions, options): string[] => {
      if (
        options?.isAddressConditionPrior === true &&
        (c.stations.length > 0 || c.lines.length > 0 || c.places.length > 0)
      ) {
        return [];
      }
      return c.jgsLongitudeTo !== undefined ? [c.jgsLongitudeTo.toString()] : [];
    },
    pageQueryValueSetter: (v: string[], c: MapAreaConditions): void => {
      c.jgsLongitudeTo = getNumberFromPageQueryValue(v);
    },
  },
};

const MapSearchConditionsPageQueryMappers: Record<
  keyof MapSearchConditions,
  ConditionPageQueryMapperDefinition<MapSearchConditions>
> = {
  mapWGSLatitude: {
    queryName: 'map_latitude',
    pageQueryValueGetter: (c: MapSearchConditions): string[] =>
      c.mapWGSLatitude !== undefined ? [c.mapWGSLatitude.toString()] : [],
    pageQueryValueSetter: (v: string[], c: MapSearchConditions): void => {
      c.mapWGSLatitude = getNumberFromPageQueryValue(v);
    },
  },
  mapWGSLongitude: {
    queryName: 'map_longitude',
    pageQueryValueGetter: (c: MapSearchConditions): string[] =>
      c.mapWGSLongitude !== undefined ? [c.mapWGSLongitude.toString()] : [],
    pageQueryValueSetter: (v: string[], c: MapSearchConditions): void => {
      c.mapWGSLongitude = getNumberFromPageQueryValue(v);
    },
  },
  mapZoom: {
    queryName: 'map_zoom',
    pageQueryValueGetter: (c: MapSearchConditions): string[] => (c.mapZoom !== undefined ? [c.mapZoom.toString()] : []),
    pageQueryValueSetter: (v: string[], c: MapSearchConditions): void => {
      c.mapZoom = getNumberFromPageQueryValue(v);
    },
  },
};

const PlaceAndLineConditionPageQueryMappers: Record<
  keyof PlaceAndLineCondition,
  ConditionPageQueryMapperDefinition<ChintaiSearchConditions>
> = {
  places: {
    queryName: 'jusho',
    pageQueryValueGetter: (c: ChintaiSearchConditions, options): string[] => {
      if (
        options?.isMapAreaConditionPrior === true &&
        isNotNullOrUndefined(c.jgsLatitudeFrom) &&
        isNotNullOrUndefined(c.jgsLatitudeTo) &&
        isNotNullOrUndefined(c.jgsLongitudeFrom) &&
        isNotNullOrUndefined(c.jgsLongitudeTo)
      ) {
        return [];
      }
      return c.places.map(a =>
        [a.prefecture.toFixed(0), a.city.toFixed(0), a.ooaza].filter(x => x.length > 0).join('+')
      );
    },
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions, options?: PageQueryValueSetterOptions): void => {
      if (options?.ignorePlaceAndLineCondition === true) {
        return;
      }
      c.places = v.map(araw => {
        const splitted = araw.split('+');
        return {
          prefecture: parseInt(splitted[0]) ?? '',
          city: parseInt(splitted[1]) ?? '',
          ooaza: splitted[2] ?? '',
        };
      });
    },
  },
  stations: {
    queryName: 'station',
    pageQueryValueGetter: (c: ChintaiSearchConditions, options): string[] => {
      if (
        options?.isMapAreaConditionPrior === true &&
        isNotNullOrUndefined(c.jgsLatitudeFrom) &&
        isNotNullOrUndefined(c.jgsLatitudeTo) &&
        isNotNullOrUndefined(c.jgsLongitudeFrom) &&
        isNotNullOrUndefined(c.jgsLongitudeTo)
      ) {
        return [];
      }
      return c.stations.map(s =>
        [s.prefCodeList.map(it => it.toFixed(0)).join(','), s.lineCode.toFixed(0), s.stationCode.toFixed(0)].join('+')
      );
    },
    pageQueryValueSetter: (v, c, options?: PageQueryValueSetterOptions): void => {
      if (options?.ignorePlaceAndLineCondition === true) {
        return;
      }
      c.stations = v.map(sraw => {
        const [joinedPrefCode, lineCode, stationCode] = sraw.split('+');
        const prefCodeList = joinedPrefCode.split(',').map(it => parseInt(it));
        return {
          prefCodeList: prefCodeList,
          lineCode: parseInt(lineCode),
          stationCode: parseInt(stationCode),
        };
      });
    },
  },
  lines: {
    queryName: 'line',
    pageQueryValueGetter: (c: ChintaiSearchConditions, options): string[] => {
      if (
        options?.isMapAreaConditionPrior === true &&
        isNotNullOrUndefined(c.jgsLatitudeFrom) &&
        isNotNullOrUndefined(c.jgsLatitudeTo) &&
        isNotNullOrUndefined(c.jgsLongitudeFrom) &&
        isNotNullOrUndefined(c.jgsLongitudeTo)
      ) {
        return [];
      }
      return c.lines.map(l => [l.prefCodeList.map(it => it.toFixed(0)).join(','), l.lineCode.toFixed(0)].join('+'));
    },
    pageQueryValueSetter: (v, c, options?: PageQueryValueSetterOptions): void => {
      if (options?.ignorePlaceAndLineCondition === true) {
        return;
      }
      c.lines = v.map(lraw => {
        const [joinedPrefCode, lineCode] = lraw.split('+');
        const prefCodeList = joinedPrefCode.split(',').map(it => parseInt(it));
        return {
          prefCodeList: prefCodeList,
          lineCode: parseInt(lineCode),
        };
      });
    },
  },
};

/* React でルーティングするためのマッピング処理 */
/* 
  - queryName
    - ルーティングパラメータ名
  - pageQueryValueGetter
    - 保持している検索条件 state からルーティングパラメータに変換する
  - pageQueryValueSetter
    - ルーティングパラメータから検索条件 state に変換する
*/
export const ConditionPageQueryMappers: Record<
  ChintaiSearchConditionKeys,
  ConditionPageQueryMapperDefinition<ChintaiSearchConditions>
> = {
  ...MapAreaConditionsPageQueryMappers,
  ...MapSearchConditionsPageQueryMappers,
  ...PlaceAndLineConditionPageQueryMappers,
  bukkenName: {
    queryName: 'tatemono_name',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => (c.bukkenName.length > 0 ? [c.bukkenName] : []),
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.bukkenName = v.length > 0 ? v[0] : '';
    },
  },
  bukkenNameKana: {
    queryName: 'tatemono_name_kana',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.bukkenNameKana.length > 0 ? [c.bukkenNameKana] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.bukkenNameKana = v.length > 0 ? v[0] : '';
    },
  },
  chinryoFrom: {
    queryName: 'chinryo.from',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.chinryoFrom !== undefined ? [c.chinryoFrom.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.chinryoFrom = getNumberFromPageQueryValue(v);
    },
  },
  chinryoTo: {
    queryName: 'chinryo.to',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.chinryoTo !== undefined ? [c.chinryoTo.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.chinryoTo = getNumberFromPageQueryValue(v);
    },
  },
  komiChinryoFrom: {
    queryName: 'komi_chinryo.from',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.komiChinryoFrom !== undefined ? [c.komiChinryoFrom.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.komiChinryoFrom = getNumberFromPageQueryValue(v);
    },
  },
  komiChinryoTo: {
    queryName: 'komi_chinryo.to',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.komiChinryoTo !== undefined ? [c.komiChinryoTo.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.komiChinryoTo = getNumberFromPageQueryValue(v);
    },
  },
  freeRentAvailable: {
    queryName: 'free_rent_flag',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => {
      return c.freeRentAvailable ? [c.freeRentAvailable.toString()] : [];
    },
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.freeRentAvailable = v.length > 0 ? toBoolean(v[0]) : false;
    },
  },
  isExcludeShikikin: {
    queryName: 'shikikin_nashi_flag',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.isExcludeShikikin ? [c.isExcludeShikikin.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.isExcludeShikikin = v.length > 0 ? toBoolean(v[0]) : false;
    },
  },
  isExcludeReikin: {
    queryName: 'reikin_nashi_flag',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.isExcludeReikin ? [c.isExcludeReikin.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.isExcludeReikin = v.length > 0 ? toBoolean(v[0]) : false;
    },
  },
  shikikinTo: {
    queryName: 'shikikin_kagetsu.to',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.shikikinTo !== undefined ? [c.shikikinTo.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.shikikinTo = getNumberFromPageQueryValue(v);
    },
  },
  reikinTo: {
    queryName: 'reikin_kagetsu.to',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.reikinTo !== undefined ? [c.reikinTo.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.reikinTo = getNumberFromPageQueryValue(v);
    },
  },
  kotsuEkitoho: {
    queryName: 'kotsu_ekitoho',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (!isEkitohoEnable(c.lines, c.stations)) {
        return [];
      }
      return c.kotsuEkitoho !== undefined ? [c.kotsuEkitoho.toString()] : [];
    },
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions, options?: PageQueryValueSetterOptions): void => {
      if (options?.ignorePlaceAndLineCondition === true) {
        return;
      }
      c.kotsuEkitoho = getNumberFromPageQueryValue(v);
    },
  },
  mensekiFrom: {
    queryName: 'search_menseki.from',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.mensekiFrom !== undefined ? [c.mensekiFrom.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.mensekiFrom = getNumberFromPageQueryValue(v);
    },
  },
  mensekiTo: {
    queryName: 'search_menseki.to',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.mensekiTo !== undefined ? [c.mensekiTo.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.mensekiTo = getNumberFromPageQueryValue(v);
    },
  },
  kokokuyroKagetsuFrom: {
    queryName: 'kokokuryo_kagetsu.from',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.kokokuyroKagetsuFrom !== undefined ? [c.kokokuyroKagetsuFrom.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.kokokuyroKagetsuFrom = getNumberFromPageQueryValue(v);
    },
  },
  kokokuryoKagetsuTo: {
    queryName: 'kokokuryo_kagetsu.to',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.kokokuryoKagetsuTo !== undefined ? [c.kokokuryoKagetsuTo.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.kokokuryoKagetsuTo = getNumberFromPageQueryValue(v);
    },
  },
  searchBoshuShubetsuCode: {
    queryName: 'search_boshu_shubetsu_code',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.searchBoshuShubetsuCode.length > 0 ? c.searchBoshuShubetsuCode : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      const newValues = v
        .filter(paramVal =>
          Object.values(SearchBoshuShubetsuConditions).some(boshuShubetsuCode => boshuShubetsuCode === paramVal)
        )
        .map(it => it as SearchBoshuShubetsuConditions);
      c.searchBoshuShubetsuCode = newValues.length > 0 ? newValues : [];
    },
  },
  searchMadoriCode: {
    queryName: 'search_madori_code2',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.searchMadoriCode.length > 0 ? c.searchMadoriCode : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      const newValues = v
        .filter(paramVal => Object.values(MadoriConditions).some(madoriCode => madoriCode === paramVal))
        .map(it => it as MadoriConditions);
      c.searchMadoriCode = newValues.length > 0 ? newValues : [];
    },
  },
  searchGenkyoCode: {
    queryName: 'chintai_genkyo_code',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.searchGenkyoCode.length > 0 ? c.searchGenkyoCode : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      const newValues = v
        .filter(paramVal => Object.values(GenkyoConditions).some(genkyoCode => genkyoCode === paramVal))
        .map(it => it as GenkyoConditions);
      c.searchGenkyoCode = newValues.length > 0 ? newValues : [];
    },
  },
  chikuNensu: {
    queryName: 'chiku_nensu',
    // URLにはn年前という値のまま保存を行う
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.chikuNensu !== undefined ? [c.chikuNensu.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.chikuNensu = getNumberFromPageQueryValue(v);
    },
  },
  isShinchiku: {
    queryName: 'shinchiku_flag',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => (c.isShinchiku ? [c.isShinchiku.toString()] : []),
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.isShinchiku = v.length > 0 ? toBoolean(v[0]) : false;
    },
  },
  kozo: {
    queryName: 'kozo',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => (c.kozo.length > 0 ? c.kozo : []),
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      const newValues = v
        .filter(paramVal => Object.values(KozoConditions).some(kozo => kozo === paramVal))
        .map(it => it as KozoConditions);
      c.kozo = newValues.length > 0 ? newValues : [];
    },
  },
  kokaiDateFrom: {
    queryName: 'kokai_date.from',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (c.kokaiRadioState === BukkenDateRangeRadioState.select) {
        return c.kokaiDateFrom !== undefined ? [formatRFC3339(startOfDay(c.kokaiDateFrom))] : [];
      } else {
        return [];
      }
    },
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.kokaiDateFrom = queryValueToDateCondition(v);
    },
  },
  kokaiDateTo: {
    queryName: 'kokai_date.to',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (c.kokaiRadioState === BukkenDateRangeRadioState.select) {
        return c.kokaiDateTo !== undefined ? [formatRFC3339(startOfDay(c.kokaiDateTo))] : [];
      } else {
        return [];
      }
    },
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.kokaiDateTo = queryValueToDateCondition(v);
    },
  },
  kokaiRadioState: {
    queryName: 'kokai_radio_state',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => {
      return c.kokaiRadioState !== BukkenDateRangeRadioState.unselected ? [c.kokaiRadioState] : [];
    },
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      if (v.length === 0) {
        c.kokaiRadioState = BukkenDateRangeRadioState.unselected;
      }
      c.kokaiRadioState = asBukkenDateRangeRadioState(v[0]) ?? BukkenDateRangeRadioState.unselected;
    },
  },
  koshinDateFrom: {
    queryName: 'koshin_date.from',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (c.koshinRadioState === BukkenDateRangeRadioState.select) {
        return c.koshinDateFrom !== undefined ? [formatRFC3339(startOfDay(c.koshinDateFrom))] : [];
      } else {
        return [];
      }
    },
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.koshinDateFrom = queryValueToDateCondition(v);
    },
  },
  koshinDateTo: {
    queryName: 'koshin_date.to',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (c.koshinRadioState === BukkenDateRangeRadioState.select) {
        return c.koshinDateTo !== undefined ? [formatRFC3339(startOfDay(c.koshinDateTo))] : [];
      } else {
        return [];
      }
    },
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.koshinDateTo = queryValueToDateCondition(v);
    },
  },
  koshinRadioState: {
    queryName: 'koshin_radio_state',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => {
      return c.koshinRadioState !== BukkenDateRangeRadioState.unselected ? [c.koshinRadioState] : [];
    },
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      if (v.length === 0) {
        c.koshinRadioState = BukkenDateRangeRadioState.unselected;
      }
      c.koshinRadioState = asBukkenDateRangeRadioState(v[0]) ?? BukkenDateRangeRadioState.unselected;
    },
  },
  nyukyoKanoDateFrom: {
    queryName: 'nyukyo_kano_datejun.from',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (c.nyukyoKanoRadioState.state === BukkenNyukyoKanoDateRangeRadioState.select) {
        return c.nyukyoKanoDateFrom !== undefined ? [formatRFC3339(startOfDay(c.nyukyoKanoDateFrom))] : [];
      } else {
        return [];
      }
    },
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.nyukyoKanoDateFrom = queryValueToDateCondition(v);
    },
  },
  nyukyoKanoDateTo: {
    queryName: 'nyukyo_kano_datejun.to',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (c.nyukyoKanoRadioState.state === BukkenNyukyoKanoDateRangeRadioState.select) {
        return c.nyukyoKanoDateTo !== undefined ? [formatRFC3339(startOfDay(c.nyukyoKanoDateTo))] : [];
      } else {
        return [];
      }
    },
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.nyukyoKanoDateTo = queryValueToDateCondition(v);
    },
  },
  nyukyoKanoRadioState: {
    queryName: 'nyukyo_kano_radio_state',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => {
      if (c.nyukyoKanoRadioState.state === BukkenNyukyoKanoDateRangeRadioState.unselected) {
        return [];
      } else {
        const stateList = [c.nyukyoKanoRadioState.state];
        if (
          c.nyukyoKanoRadioState.state === BukkenNyukyoKanoDateRangeRadioState.select &&
          c.nyukyoKanoRadioState.hasPlan
        ) {
          stateList.push(BukkenNyukyoKanoDateRangeRadioState.plan);
        }
        return stateList;
      }
    },
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      if (v.length === 0) {
        c.nyukyoKanoRadioState.state = BukkenNyukyoKanoDateRangeRadioState.unselected;
      }
      c.nyukyoKanoRadioState.hasPlan = v.includes(BukkenNyukyoKanoDateRangeRadioState.plan);
      const state = v.filter(it => it !== BukkenNyukyoKanoDateRangeRadioState.plan);
      c.nyukyoKanoRadioState.state =
        asBukkenNyukyoKanoDateRangeRadioState(state[0]) ?? BukkenNyukyoKanoDateRangeRadioState.unselected;
    },
  },
  naiken: {
    queryName: 'naiken_kubun_code',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => (c.naiken !== '0' ? [c.naiken] : []),
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      if (v.length === 0) {
        c.naiken = '0';
      } else {
        c.naiken = v[0];
      }
    },
  },
  propertyFullKey: {
    queryName: 'property_full_key',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.propertyFullKey.length > 0 ? [c.propertyFullKey] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.propertyFullKey = v.length > 0 ? v[0] : '';
    },
  },
  organizationGuid: {
    queryName: 'organization_guid',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.organizationGuid.length > 0 ? [c.organizationGuid] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.organizationGuid = v.length > 0 ? v[0] : '';
    },
  },
  kanriGyosha: {
    queryName: 'motozuke_gyosha_name',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => (c.kanriGyosha.length > 0 ? [c.kanriGyosha] : []),
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.kanriGyosha = v.length > 0 ? v[0] : '';
    },
  },
  kodawari: {
    queryName: 'kodawari',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => c.kodawari,
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.kodawari = v.map(asKodawariConditions).filter((v): v is KodawariConditions => v !== null);
    },
  },
  heyaNumber: {
    queryName: 'heya_number',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => (c.heyaNumber.length > 0 ? [c.heyaNumber] : []),
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.heyaNumber = v[0] ?? '';
    },
  },
  isExcludeMoshikomiExist: {
    queryName: 'is_exclude_moshikomi_exist',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.isExcludeMoshikomiExist ? [c.isExcludeMoshikomiExist.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.isExcludeMoshikomiExist = v.length > 0 ? toBoolean(v[0]) : false;
    },
  },
  isSokunyukyoEnable: {
    queryName: 'is_sokunyukyo_enable',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.isSokunyukyoEnable ? [c.isSokunyukyoEnable.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.isSokunyukyoEnable = v.length > 0 ? toBoolean(v[0]) : false;
    },
  },
  order: {
    queryName: 'order',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => [c.order],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.order = asChintaiResultOrder(v[0]) ?? ChintaiResultOrder.lastUpdatedTimeDescending;
    },
  },
  bukkenKokaiScopeRadioState: {
    queryName: 'b2b_kokai_code',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] => {
      return c.bukkenKokaiScopeRadioState !== B2bKokaiCodeRadioState.unselected ? [c.bukkenKokaiScopeRadioState] : [];
    },
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.bukkenKokaiScopeRadioState =
        v.length > 0 ? (v[0] as B2bKokaiCodeRadioStateType) : B2bKokaiCodeRadioState.unselected;
    },
  },
  totalGazoCountFrom: {
    queryName: 'total_gazo_count.from',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.totalGazoCountFrom !== undefined ? [c.totalGazoCountFrom.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.totalGazoCountFrom = getNumberFromPageQueryValue(v);
    },
  },
  gaikanGazoCountFrom: {
    queryName: 'gaikan_gazo_count.from',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.gaikanGazoCountFrom !== undefined ? [c.gaikanGazoCountFrom.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.gaikanGazoCountFrom = getNumberFromPageQueryValue(v);
    },
  },
  madoriGazoCountFrom: {
    queryName: 'madori_gazo_count.from',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.madoriGazoCountFrom !== undefined ? [c.madoriGazoCountFrom.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.madoriGazoCountFrom = getNumberFromPageQueryValue(v);
    },
  },
  naikanGazoCountFrom: {
    queryName: 'naikan_gazo_count.from',
    pageQueryValueGetter: (c: ChintaiSearchConditions): string[] =>
      c.naikanGazoCountFrom !== undefined ? [c.naikanGazoCountFrom.toString()] : [],
    pageQueryValueSetter: (v: string[], c: ChintaiSearchConditions): void => {
      c.naikanGazoCountFrom = getNumberFromPageQueryValue(v);
    },
  },
};
