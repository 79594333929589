import { useOnOffState } from '@lib/components';
import { Box } from '@mui/material';
import { FC, Fragment, memo, useMemo } from 'react';

import { PartnerSearchPublicEditDialog } from './PartnerSearchPublicEditDialog';
import { SettingCommonDisplay } from './SettingCommonDisplay';

import { DisplayItem } from '@/Components/Forms/DisplayItem';
import { notAdminSettingMessage } from '@/Consts/Messages';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import {
  EsaOrganization,
  PartnerSearchPublicStatusCollection,
  PartnerSearchPublicStatusCollectionType,
} from '@/Models/Esa/EsaOrganization';

type PartnerSearchPublicSettingContainerProps = {
  settingOrganization: EsaOrganization;
};

const PartnerSearchPublicSettingContainer: FC<PartnerSearchPublicSettingContainerProps> = memo(
  ({ settingOrganization }) => {
    const user = useCurrentUser();
    const hasMasterRole = useMemo(() => {
      return user.role.isAdmin;
    }, [user.role.isAdmin]);

    const [
      isOpenPartnerSearchPublicSettingDialog,
      { setTrue: openPartnerSearchPublicSettingDialog, setFalse: closePartnerSearchPublicSettingDialog },
    ] = useOnOffState(false);

    const publicStatus: PartnerSearchPublicStatusCollectionType = useMemo(() => {
      return settingOrganization.isPublic
        ? PartnerSearchPublicStatusCollection.public
        : PartnerSearchPublicStatusCollection.private;
    }, [settingOrganization.isPublic]);

    const toolTipMessage = useMemo(() => {
      return hasMasterRole ? '' : notAdminSettingMessage;
    }, [hasMasterRole]);

    return (
      <PartnerSearchPublicSettingPresenter
        isOpenDialog={isOpenPartnerSearchPublicSettingDialog}
        openDialog={openPartnerSearchPublicSettingDialog}
        closeDialog={closePartnerSearchPublicSettingDialog}
        hasMasterRole={hasMasterRole}
        toolTipMessage={toolTipMessage}
        settingOrganization={settingOrganization}
        publicStatus={publicStatus}
      />
    );
  }
);

export { PartnerSearchPublicSettingContainer as PartnerSearchPublicSetting };

type PartnerSearchPublicSettingPresenterProps = {
  isOpenDialog: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  hasMasterRole: boolean;
  toolTipMessage: string;
  settingOrganization: EsaOrganization;
  publicStatus: PartnerSearchPublicStatusCollectionType;
};

export const PartnerSearchPublicSettingPresenter: FC<PartnerSearchPublicSettingPresenterProps> = memo(
  ({ isOpenDialog, openDialog, closeDialog, hasMasterRole, toolTipMessage, settingOrganization, publicStatus }) => (
    <Fragment>
      <SettingCommonDisplay
        openDialog={openDialog}
        dataGuide="organization-public-settings"
        isDisableEditButton={!hasMasterRole}
        toolTipMessage={toolTipMessage}
        settingOrganization={settingOrganization}
        settingTitle="組織情報公開"
        settingContent={
          <>
            <Box color="var(--color-semantic-text-secondary)">
              取引先追加の検索結果上で他のユーザーに表示する状態を管理します。
            </Box>
            <DisplayItem
              itemList={[{ label: '公開状態', value: publicStatus }]}
              gridLabelProps={{ height: '20px' }}
              gridValueProps={{ height: '20px' }}
            />
          </>
        }
      />
      {isOpenDialog && (
        <PartnerSearchPublicEditDialog
          isOpenDialog={isOpenDialog}
          closeDialog={closeDialog}
          settingOrganization={settingOrganization}
        />
      )}
    </Fragment>
  )
);
