import { NewsData } from '@app/models/src/News/NewsData';
import { AnnouncementButton, textSxProps, useIsExtraSmallDevice, useIsSmallDevice } from '@lib/components';
import {
  AppBar,
  Box,
  Button,
  buttonClasses,
  Divider,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from '@mui/material';
import { CalendarClock, ChevronLeft, HelpCircle, Menu, Feather } from 'mdi-material-ui';
import { memo, ReactElement, useCallback, FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { AccountMenu } from './AccountMenu';
import { MessageIcon } from './Message/MesseageIcon';

import { EsaUser } from '@/Models/Esa/EsaUser';

type TopBarProps = {
  loginContextEmail?: string;
  userInfo: EsaUser;
  domainName: string;
  useAnnouncement: {
    isLoading: boolean;
    isUnreadNewsExists: boolean;
    newsData: NewsData[] | undefined;
    markAsRead: () => void;
  };
  isSideMenuOpen: boolean;
  accountUrl: string;
  supportSiteUrl: string;
  sumaiEntryNaikenUrl: string;
  sumaiEntryMoshikomiUrl: string;
  onLogout: () => void;
  sideMenu: ReactElement;
  isCustomerView: boolean;
} & (
  | {
      isMainFeatureAvailable: false;
    }
  | {
      isMainFeatureAvailable: true;
      onSideMenuOpen: () => void;
      onSideMenuClose: () => void;
    }
);

export const TopBar: FC<TopBarProps> = memo(props => {
  const onSideMenuOpen = useCallback(() => {
    if (props.isMainFeatureAvailable) {
      props.onSideMenuOpen();
    }
  }, [props]);
  const onSideMenuClose = useCallback(() => {
    if (props.isMainFeatureAvailable) {
      props.onSideMenuClose();
    }
  }, [props]);

  const isSmallDevice = useIsSmallDevice();
  const isExtraSmallDevice = useIsExtraSmallDevice();

  return (
    <Fragment>
      <AppBar
        position="static"
        sx={{
          bgcolor: 'white',
          color: 'text.primary',
          zIndex: 100,
        }}
      >
        <Toolbar sx={{ overflowX: 'auto' }}>
          {props.isMainFeatureAvailable && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={props.isSideMenuOpen ? props.onSideMenuClose : props.onSideMenuOpen}
              edge="start"
              size="large"
            >
              <Menu />
            </IconButton>
          )}
          <Link
            to="/"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            <img src="/e_square_logo.svg" alt="いい生活Squareロゴ" style={{ height: '56px' }} />
          </Link>
          {!isSmallDevice && !props.isCustomerView && (
            <Box ml={{ xs: 0, md: 6 }} display="flex" flexDirection="row" alignItems="center">
              <Box mr={{ xs: 0, md: 2 }}>
                <Button
                  component="a"
                  href={props.sumaiEntryNaikenUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                  sx={{
                    [`& .${buttonClasses.startIcon}`]: {
                      marginRight: '4px',
                    },
                    [`& .${buttonClasses.startIcon} :nth-of-type(1)`]: {
                      color: 'text.secondary',
                      fontSize: '24px',
                    },
                  }}
                  startIcon={<CalendarClock />}
                >
                  <Typography component="span" variant="button" sx={textSxProps.md}>
                    内見一覧
                  </Typography>
                </Button>
              </Box>
              <Box mr={{ xs: 0, md: 2 }}>
                <Button
                  component="a"
                  href={props.sumaiEntryMoshikomiUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                  sx={{
                    [`& .${buttonClasses.startIcon}`]: {
                      marginRight: '4px',
                    },
                    [`& .${buttonClasses.startIcon} :nth-of-type(1)`]: {
                      color: 'text.secondary',
                      fontSize: '24px',
                    },
                  }}
                  startIcon={<Feather />}
                >
                  <Typography component="span" variant="button" sx={textSxProps.md}>
                    申込一覧
                  </Typography>
                </Button>
              </Box>
            </Box>
          )}
          <Box flexGrow={1} />
          <Box display="flex" flexDirection="row" alignItems="center">
            {!props.isCustomerView && <MessageIcon />}
            <Box mr={{ xs: 0, md: 1 }}>
              <AnnouncementButton useAnnouncement={props.useAnnouncement} />
            </Box>
            <Box mr={{ xs: 0, md: 1 }}>
              {isSmallDevice ? (
                <IconButton
                  component="a"
                  href={props.supportSiteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="large"
                  css={{ width: '40px', height: '40px', p: 1 }}
                >
                  <HelpCircle sx={{ color: 'text.secondary', fontSize: '24px' }} />
                </IconButton>
              ) : (
                <Button
                  component="a"
                  href={props.supportSiteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                  sx={{
                    textDecoration: 'none',
                    [`& .${buttonClasses.iconSizeMedium}`]: {
                      '& *:first-of-type': {
                        color: 'text.secondary',
                        fontSize: '24px',
                      },
                    },
                    [`& .${buttonClasses.startIcon}`]: {
                      marginRight: '4px',
                    },
                  }}
                  startIcon={<HelpCircle sx={{ color: 'text.secondary', fontSize: '24px' }} />}
                >
                  <Typography component="span" variant="button" sx={textSxProps.md}>
                    サポート
                  </Typography>
                </Button>
              )}
            </Box>
            {!props.isCustomerView && (
              <AccountMenu
                loginContextEmail={props.loginContextEmail}
                userName={props.userInfo.name}
                email={props.userInfo.email}
                domainName={props.domainName}
                onLogout={props.onLogout}
                accountUrl={props.accountUrl}
                sumaiEntryNaikenUrl={props.sumaiEntryNaikenUrl}
                sumaiEntryMoshikomiUrl={props.sumaiEntryMoshikomiUrl}
              />
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {isSmallDevice && props.isMainFeatureAvailable && (
        <SwipeableDrawer anchor="left" open={props.isSideMenuOpen} onClose={onSideMenuClose} onOpen={onSideMenuOpen}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              width: isExtraSmallDevice ? '80vw' : '15rem',
              minHeight: '100%',
              bgcolor: 'secondary.main',
              overflowY: 'hidden',
            }}
          >
            <IconButton
              onClick={props.onSideMenuClose}
              sx={{
                marginRight: 'auto',
              }}
              size="large"
            >
              <ChevronLeft />
            </IconButton>
            <Divider />
            {props.sideMenu}
          </Box>
        </SwipeableDrawer>
      )}
    </Fragment>
  );
});
