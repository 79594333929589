import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Box, CircularProgress, Container } from '@mui/material';
export const Loading = props => {
    var _a;
    return (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", p: 2, height: "100%" }, { children: [_jsx(CircularProgress, {}), _jsx(Box, Object.assign({ py: 1 }, { children: (_a = props.message) !== null && _a !== void 0 ? _a : '読込中…' }))] })));
};
export const EsLoading = ({ children }) => (_jsxs(Container, Object.assign({ sx: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    } }, { children: [_jsx(Box, { component: "img", src: "/img/loading_icon.gif", alt: "loading", sx: { mixBlendMode: 'multiply' } }), children] })));
