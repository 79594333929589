import { isNotNullOrUndefined } from '@app/utils';
import { ErrorAnnounce } from '@e-seikatsu/design-system';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';

import { MaintenanceSchedule } from '@/Hooks/MaintenanceInfoHook';
import { isInvalidDate } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/SearchConditionValidHooks';

type MaintenanceInfoPageContainerProps = {
  maintenanceSchedule: MaintenanceSchedule | undefined;
};

export const MaintenanceInfoPageContainer: FC<MaintenanceInfoPageContainerProps> = ({ maintenanceSchedule }) => {
  const from = new Date(String(maintenanceSchedule?.from));
  const to = new Date(String(maintenanceSchedule?.to));
  const isMaintenanceInfoSchedule =
    isNotNullOrUndefined(from) && isNotNullOrUndefined(to) && !isInvalidDate(from) && !isInvalidDate(to);

  const fromToText = isMaintenanceInfoSchedule ? `${format(from, 'M/d H:mm')} ～ ${format(to, 'M/d H:mm')}` : null;
  return <MaintenanceInfoPagePresenter fromToText={fromToText} />;
};

export { MaintenanceInfoPageContainer as MaintenanceInfoPage };

type MaintenanceInfoPagePresenterProps = {
  fromToText: string | null;
};

export const MaintenanceInfoPagePresenter: FC<MaintenanceInfoPagePresenterProps> = ({ fromToText }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      textAlign="center"
      p={2}
    >
      <ErrorAnnounce
        title={
          <>
            現在、システムメンテナンス中です
            <br />
            {fromToText}
          </>
        }
        comment="ご不便をおかけしまして申し訳ございませんが、サービス再開までしばらくお待ちください"
      />
    </Box>
  );
};
