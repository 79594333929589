import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Box, svgIconClasses, Typography } from '@mui/material';
import { Fragment, createElement } from 'react';
import { textSxProps } from '../Styles';
export const EbMessage = ({ mainMessage, secondMessage, iconComponent }) => {
    return (_jsxs(Fragment, { children: [createElement(iconComponent, {
                sx: {
                    fontSize: '10rem',
                    color: 'rgba(0,0,0,0.1)',
                    marginTop: -7.5,
                },
            }), _jsx(Typography, Object.assign({ component: "h1", sx: Object.assign(Object.assign({}, textSxProps.bold), { fontSize: '1.75rem', paddingTop: 1 }) }, { children: mainMessage })), _jsx(Box, Object.assign({ pt: 1, sx: {
                    color: 'text.secondary',
                    [`& .${svgIconClasses.root}`]: {
                        fontSize: 'inherit',
                        width: '1.25em',
                        height: '1.25em',
                        verticalAlign: 'top',
                    },
                } }, { children: secondMessage.map((m, i) => (_jsx(Typography, { children: m }, i))) }))] }));
};
