import { isNullOrUndefined } from '@app/utils';
import { css } from '@emotion/react';
import { useIsSmallDevice } from '@lib/components';
import { Badge, Box, IconButton, Menu as MuiMenu, MenuItem } from '@mui/material';
import { grey } from '@mui/material/colors';
import { MessageTextOutline, AccountMultiple, Domain } from 'mdi-material-ui';
import React, { memo, useCallback, FC, useMemo, useState, MouseEvent as ReactMouseEvent } from 'react';

import { messengerWindowFeatures } from '@/Components/Partner/PartnerStatusButton';
import { useChannels } from '@/Hooks/Messenger/useChannels';
import { RelationTypeCollection } from '@/Models/Messenger/Graphql';
import { ServiceConfigure } from '@/Services/Configure';

const MessageIconContainer: FC = memo(() => {
  const isSmallDevice = useIsSmallDevice();

  const [anchorElement, setAnchorElement] = useState<Element | null>(null);
  const isOpen = !isNullOrUndefined(anchorElement);
  const onIconClicked = useCallback((event: ReactMouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorElement(null);
  }, []);
  const onOpenMessenger = useCallback(() => {
    window.open(ServiceConfigure.messengerUrl, '_blank', messengerWindowFeatures);
  }, []);
  const onOpenInternalMessenger = useCallback(() => {
    window.open(`${ServiceConfigure.messengerUrl}/internal`, '_blank', messengerWindowFeatures);
  }, []);

  const { model: partnerChannelModel } = useChannels({
    itemsPerPage: undefined,
    startIndex: undefined,
    organizationUid: undefined,
    isRead: false,
    countOnly: true,
    relationType: RelationTypeCollection.partner,
  });
  const partnerChannelList = partnerChannelModel?.data.listChannels;
  const { model: internalChannelModel } = useChannels({
    itemsPerPage: undefined,
    startIndex: undefined,
    organizationUid: undefined,
    isRead: false,
    countOnly: true,
    relationType: RelationTypeCollection.internal,
  });
  const internalChannelList = internalChannelModel?.data.listChannels;

  const isExistsUnreadPartnerChannel = useMemo(() => {
    if (!partnerChannelList) {
      return false;
    }
    return partnerChannelList.totalCounts > 0;
  }, [partnerChannelList]);
  const isExistsUnreadInternalChannel = useMemo(() => {
    if (!internalChannelList) {
      return false;
    }
    return internalChannelList.totalCounts > 0;
  }, [internalChannelList]);

  const isExistsUnreadChannel = useMemo(() => {
    if (!partnerChannelList || !internalChannelList) {
      return false;
    }
    return partnerChannelList.totalCounts > 0 || internalChannelList.totalCounts > 0;
  }, [internalChannelList, partnerChannelList]);

  return (
    <MessageIconPresenter
      isSmallDevice={isSmallDevice}
      isOpen={isOpen}
      anchorElement={anchorElement}
      onIconClicked={onIconClicked}
      handleClose={handleClose}
      onOpenMessenger={onOpenMessenger}
      onOpenInternalMessenger={onOpenInternalMessenger}
      isExistsUnreadPartnerChannel={isExistsUnreadPartnerChannel}
      isExistsUnreadInternalChannel={isExistsUnreadInternalChannel}
      isExistsUnreadChannel={isExistsUnreadChannel}
    />
  );
});

export { MessageIconContainer as MessageIcon };

type MessageIconPresenterProps = {
  isSmallDevice: boolean;
  isOpen: boolean;
  anchorElement: Element | null;
  onIconClicked: (event: ReactMouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  onOpenMessenger: () => void;
  onOpenInternalMessenger: () => void;
  isExistsUnreadPartnerChannel: boolean;
  isExistsUnreadInternalChannel: boolean;
  isExistsUnreadChannel: boolean;
};

const badgeStyle = css({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& .MuiBadge-badge': {
    backgroundColor: '#F44336',
    position: 'relative',
    right: '18px',
    top: '12px',
  },
});

const iconStyle = css({
  fontSize: '1.2rem',
  color: grey[600],
  marginLeft: 0.5,
});

export const MessageIconPresenter: FC<MessageIconPresenterProps> = memo(
  ({
    isSmallDevice,
    isOpen,
    anchorElement,
    onIconClicked,
    handleClose,
    onOpenMessenger,
    onOpenInternalMessenger,
    isExistsUnreadPartnerChannel,
    isExistsUnreadInternalChannel,
    isExistsUnreadChannel,
  }) => (
    <>
      {isSmallDevice ? (
        <Box width="40px">
          <Badge badgeContent={isExistsUnreadChannel ? ' ' : 0} variant="dot" overlap="circular" css={badgeStyle}>
            <IconButton onClick={onOpenMessenger}>
              <MessageTextOutline sx={{ color: 'text.secondary', fontSize: '24px' }} />
            </IconButton>
          </Badge>
        </Box>
      ) : (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box width="40px">
            <Badge badgeContent={isExistsUnreadChannel ? ' ' : 0} variant="dot" overlap="circular" css={badgeStyle}>
              <IconButton onClick={onIconClicked}>
                <MessageTextOutline sx={{ color: 'text.secondary', fontSize: '24px' }} />
              </IconButton>
            </Badge>
            <MuiMenu anchorEl={anchorElement} open={isOpen} onClose={handleClose} css={{ display: 'flex' }}>
              <Box>
                <Badge
                  badgeContent={isExistsUnreadPartnerChannel ? ' ' : 0}
                  variant="dot"
                  overlap="circular"
                  css={badgeStyle}
                >
                  <MenuItem onClick={onOpenMessenger}>
                    <AccountMultiple css={iconStyle} />
                    取引先
                  </MenuItem>
                </Badge>
              </Box>
              <Box>
                <Badge
                  badgeContent={isExistsUnreadInternalChannel ? ' ' : 0}
                  variant="dot"
                  overlap="circular"
                  css={badgeStyle}
                >
                  <MenuItem onClick={onOpenInternalMessenger}>
                    <Domain css={iconStyle} />
                    自社内
                  </MenuItem>
                </Badge>
              </Box>
            </MuiMenu>
          </Box>
        </Box>
      )}
    </>
  )
);
