export type ViewStatusBukken = {
  bukkenName: string | null;
  bukkenGuid: string;
  kukakuName: string | null;
  kukakuGuid: string | null;
  kokaimotoGuid: string;
  torikomiSourceUserId?: string | null;
};
export const viewStatusAction = {
  detail: '詳細画面',
  motodukeFlyer: '元付帯図面DL',
  kyakudukeFlyer: '貴社帯図面DL',
  torikomi: '物件取込',
  naiken: '内見',
  moshikomi: '申込',
  imageZipDL: '画像一括ダウンロード',
  customerView: '接客用表示',
  initialCostEstimate: '初期費用見積概算',
  message: 'メッセージ',
} as const;
export type ViewStatusAction = typeof viewStatusAction[keyof typeof viewStatusAction];

export interface ISendingViewStatusService {
  send(viewStatusBukken: ViewStatusBukken, action: ViewStatusAction): void;
}
