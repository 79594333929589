import { EsaPublicDomain } from './EsaDomain';
import { EsaPublicOrganization } from './EsaOrganization';

export const PartnerApplicantsTypeCollection = {
  sent: 'sent',
  received: 'received',
} as const;
export type PartnerApplicantsTypeCollectionType =
  typeof PartnerApplicantsTypeCollection[keyof typeof PartnerApplicantsTypeCollection];

export const PartnerApplicantsStatusCollection = {
  approved: 'approved',
  rejected: 'rejected',
  standby: 'standby',
} as const;

export type PartnerApplicantsStatusCollectionType =
  typeof PartnerApplicantsStatusCollection[keyof typeof PartnerApplicantsStatusCollection];
export const isPartnerApplicantsStatus = (key: string | undefined): key is PartnerApplicantsStatusCollectionType => {
  return Object.values(PartnerApplicantsStatusCollection).includes(key as PartnerApplicantsStatusCollectionType);
};

export type PartnerApplicants = {
  approvalComment: string;
  cancellationComment: string;
  comment: string;
  createdAt: string;
  domain: EsaPublicDomain;
  domainUid: string;
  email: string;
  modifiedAt: string;
  organizationUid: string;
  organization: EsaPublicOrganization;
  partnerApplicantUid: string;
  partnerDomain: EsaPublicDomain;
  partnerDomainUid: string;
  partnerOrganization: EsaPublicOrganization;
  partnerOrganizationUid: string;
  rejectionComment: string;
  state: PartnerApplicantsStatusCollectionType;
  userName: string;
};

export type PartnerApplicantsPostPayload = {
  approvalComment: string;
  cancellationComment: string;
  comment: string;
  organizationUid: string;
  partnerOrganizationUid: string;
  rejectionComment: string;
};
