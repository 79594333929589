import { LoginContext } from '@/Models/Esa/EsaLoginContext';

/**
 * ESA のログイン情報 から統合が推奨される法人かどうか判定する
 * @param loginContext ESA ログイン情報
 * @returns 統合推奨法人かどうか
 */
export const enableIntegrateDomain = (loginContext: LoginContext): boolean => {
  return loginContext.users.length > 1 && loginContext.users.some(user => user.enableIntegrationFeature);
};

/**
 * ESA のログイン情報 から統合が可能なアカウントかどうか判定する
 * @param loginContext ESA ログイン情報
 * @returns 統合可能アカウントかどうか
 */
export const enableIntegrateAccount = (loginContext: LoginContext): boolean => {
  const admins = loginContext.users.filter(user => user.role.isAdmin);
  return admins.length > 1 && admins.some(admin => admin.enableIntegrationFeature);
};
