import { createViewportHeightHandler } from '@app/utils';
import { UncaughtErrorBoundary } from '@lib/components';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import './index.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import SwiperCore, { Virtual } from 'swiper';

import 'swiper/swiper-bundle.css';

import App from './App';
import { reportError } from './ErrorLogger';
import { ServiceConfigure } from './Services/Configure';
import { theme } from './Theme';
import * as serviceWorker from './serviceWorker';

import Auth0Provider from '@/Auth0Provider';

import '@e-seikatsu/design-system/dist/tokens/index.css';

window.addEventListener('resize', createViewportHeightHandler());

// ライブラリのセットアップ
SwiperCore.use([Virtual]);

// Sentryのセットアップ
Sentry.init({
  release: ServiceConfigure.appVersion,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0.01,
  beforeSend(event) {
    // Modify or drop the event here
    if (event.message?.includes('未定義のパスがあります')) {
      return null;
    }
    return event;
  },
});
Sentry.setTag('host', window.location.hostname);

// createRootにおけるnon-null-assertionは公式が推奨する記法のためESLintを無効化する
// 参考：https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <BrowserRouter>
      <Auth0Provider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <UncaughtErrorBoundary reportError={reportError}>
              <App />
            </UncaughtErrorBoundary>
          </ThemeProvider>
        </StyledEngineProvider>
      </Auth0Provider>
    </BrowserRouter>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
