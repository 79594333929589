export const mapSearchMaxItems = 300;
export const latLngRangeSessionStorageKeys = {
  minLat: 'minLat',
  maxLat: 'maxLat',
  minLng: 'minLng',
  maxLng: 'maxLng',
};

export const zoomExceedThreshold = 14;

export const searchResultHeaderHeight = 65;

export const searchResultHeaderSplitHeight = 96;

export const searchResultHeaderSplitDownBreakPoint = 800;

export const searchResultHeaderZIndex = 5;

export const searchResultFooterZIndex = 5;

export const searchResultExceedThreshold = 5000;

export const messengerWindowWidth = 375;

export const messengerWindowHeight = 800;

export const analyticsPlaceNameBusinessPartnerManagement = '取引先管理';

export const developerEmailDomain = '@e-seikatsu.co.jp';
