import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { isNotNullOrEmptyString } from '@app/utils';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Cancel } from 'mdi-material-ui';
import { useIsSmallDevice } from '../Styles';
export const ErrorMessage = props => {
    var _a;
    const isSmall = (_a = props.small) !== null && _a !== void 0 ? _a : false;
    const isSmallDevice = useIsSmallDevice();
    const headerVariant = isSmall ? 'h5' : isSmallDevice ? 'h4' : 'h3';
    return (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "column", alignItems: "center" }, { children: [_jsx(Cancel, { sx: {
                    fontSize: isSmall ? '4rem' : '10rem',
                    color: grey[400],
                } }), _jsx(Box, Object.assign({ pt: isSmall ? 3 : 5 }, { children: _jsx(Typography, Object.assign({ variant: headerVariant, component: "h1", sx: {
                        color: grey[800],
                        textAlign: 'center',
                    } }, { children: _jsx("b", { children: props.header }) })) })), isNotNullOrEmptyString(props.mainMessage) && (_jsx(Box, Object.assign({ pt: isSmall ? 3 : 5 }, { children: _jsx(Typography, Object.assign({ variant: "body1", sx: {
                        color: grey[600],
                        textAlign: 'center',
                    } }, { children: props.mainMessage })) }))), isNotNullOrEmptyString(props.supportMessage) && (_jsx(Box, Object.assign({ pt: isSmall ? 1 : 2 }, { children: _jsx(Typography, Object.assign({ variant: "body1", sx: {
                        color: grey[600],
                        textAlign: 'center',
                    } }, { children: props.supportMessage })) })))] })));
};
