import { HttpError } from '@app/errors';

import { DeleteProps, GetProps, IEsaApiClient, PostProps, PutProps } from './IEsaApiClient';

const MARK_BASE_URL = 'https://a.com';
class EsaApiClient implements IEsaApiClient {
  constructor(private readonly baseUrl: string, private readonly getAccessToken?: () => Promise<string | null>) {}

  async composeHeaders(init: RequestInit): Promise<void> {
    if (init?.method === undefined) {
      init.method = 'GET';
    }

    if (!this.getAccessToken) {
      return;
    }

    const token = await this.getAccessToken();
    init.headers = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${token}`,
      ...init?.headers,
    };
  }

  async request(path: string, init: RequestInit = {}): Promise<Response | undefined> {
    const url = new URL(path, MARK_BASE_URL).href.replace(MARK_BASE_URL, this.baseUrl);

    await this.composeHeaders(init);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    if ((init.headers as { Authorization: string })?.Authorization === 'Bearer null') {
      return;
    }
    const response = await fetch(url, init).catch(e => {
      throw new HttpError(e.response.status, e.response.statusText);
    });

    if (!response.ok) {
      const body = await response.json().catch(e => {
        throw new HttpError(e.response.status, body);
      });
    }

    return response;
  }

  async requestJsonResponse<T>(path: string, init?: RequestInit): Promise<T> {
    const response = await this.request(path, init);
    return response?.json().catch(e => {
      throw new HttpError(e.response.status, e.response.statusText);
    });
  }

  get<T>({ path }: GetProps): Promise<T> {
    return this.requestJsonResponse(path, { method: 'GET' });
  }

  post<T>({ path, body }: PostProps): Promise<T> {
    return this.requestJsonResponse(path, {
      method: 'POST',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: { 'Content-Type': 'application/json' },
      body,
    });
  }

  put<T>({ path, body }: PutProps): Promise<T> {
    return this.requestJsonResponse(path, {
      method: 'PUT',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: { 'Content-Type': 'application/json' },
      body,
    });
  }

  delete<T>({ path }: DeleteProps): Promise<T> {
    return this.requestJsonResponse(path, {
      method: 'DELETE',
    });
  }
}

export default EsaApiClient;
