import { isNullOrEmptyString, isNotNullOrEmptyString } from '@app/utils';

import {
  ErrorModelsState,
  LoadedModelsState,
  LoadingModelsState,
  UseEsaModelsProps,
  useEsaModels,
} from './useEsaModels';

import { EsaOrganization } from '@/Models/Esa/EsaOrganization';
import { ServiceConfigure } from '@/Services/Configure';
import { useEsaApiClient } from '@/Services/EsaApiService';

type Props = {
  domainUid: string;
} & UseEsaModelsProps;

export type OrganizationMap = Map<string | null, EsaOrganization>;
export type OrganizationOption = Readonly<Pick<EsaOrganization, 'organizationUid' | 'name'>>;
export type OrganizationOptions = ReadonlyArray<OrganizationOption>;

export type GetOrganizationName = (uid: string | null) => string;

export type DomainOrganizationsState =
  | (LoadingModelsState<EsaOrganization> & {
      organizations: null;
      organizationUids: null;
      organizationMap: null;
      organizationOptions: null;
      getOrganizationName: null;
    })
  | (ErrorModelsState<EsaOrganization> & {
      organizations: null;
      organizationUids: null;
      organizationMap: null;
      organizationOptions: null;
      getOrganizationName: null;
    })
  | (LoadedModelsState<EsaOrganization> & {
      organizations: EsaOrganization[];
      organizationUids: ReadonlyArray<EsaOrganization['organizationUid']>;
      organizationMap: OrganizationMap;
      organizationOptions: OrganizationOptions;
      getOrganizationName: GetOrganizationName;
    });

export const useEsaDomainOrganizations = (props: Props): DomainOrganizationsState => {
  const { domainUid } = props;
  const state = useEsaModels<EsaOrganization>({
    itemsPerPage: 500,
    api: {
      audience: ServiceConfigure.auth0Audience,
      baseUrl: ServiceConfigure.esAccountApi,
      path: isNotNullOrEmptyString(domainUid) ? `/domains/${domainUid}/organizations` : null,
    },
  });

  if (state.isLoading) {
    return {
      ...state,
      organizations: state.models,
      organizationUids: null,
      organizationMap: null,
      organizationOptions: null,
      getOrganizationName: null,
    };
  }

  if (state.error) {
    return {
      ...state,
      organizations: state.models,
      organizationUids: null,
      organizationMap: null,
      organizationOptions: null,
      getOrganizationName: null,
    };
  }

  const organizationMap: OrganizationMap = new Map(
    state.models.map(organization => [organization.organizationUid, organization])
  );

  const organizationOptions: OrganizationOptions = state.models.map(({ name, organizationUid }) => ({
    name,
    organizationUid,
  }));

  const organizationUids = state.models.map(({ organizationUid }) => organizationUid);

  const getOrganizationName: GetOrganizationName = uid => {
    if (!uid) {
      return '';
    }
    return organizationMap.get(uid)?.name ?? '';
  };

  return {
    ...state,
    organizations: state.models,
    organizationUids,
    organizationMap,
    organizationOptions,
    getOrganizationName,
  };
};

export const useUpdateEsaOrganization = (): {
  updateEsaOrganization: (organizationUid: string, organization: Partial<EsaOrganization>) => Promise<void>;
} => {
  const { apiClient } = useEsaApiClient({
    audience: ServiceConfigure.auth0Audience,
    baseUrl: ServiceConfigure.esAccountApi,
  });
  const updateEsaOrganization = async (organizationUid: string, organization: Partial<EsaOrganization>) => {
    if (isNullOrEmptyString(organizationUid)) {
      return;
    }
    await apiClient?.put<EsaOrganization>({
      path: `/organizations/${organizationUid}`,
      body: JSON.stringify(organization),
    });
  };
  return { updateEsaOrganization };
};
