import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Badge, Button, Tooltip } from '@mui/material';
import { AccountPlus, MessageTextOutline } from 'mdi-material-ui';
import { FC, memo, useCallback, useMemo } from 'react';

import { restrictionInternalAccountTooltipText } from '@/Consts/Messages';
import { messengerWindowWidth, messengerWindowHeight } from '@/Consts/Values';
import { useIsValidTakkenLicenseNumber } from '@/Hooks/ValidationHooks';
import { PartnerStatusCollection, PartnerStatusCollectionType } from '@/Models/Esa/Partner';
import { ServiceConfigure } from '@/Services/Configure';

type PartnerStatusButtonContainerProps = {
  partnerStatus?: PartnerStatusCollectionType;
  channelUid?: string;
  isRead?: boolean | null;
  openApplicantsSendDialog: () => void;
  openApplicantsSentDialog: () => void;
  openApplicantsReceivedDialog: () => void;
};

export const messengerWindowFeatures = `width=${messengerWindowWidth},height=${messengerWindowHeight},noopener,noreferrer`;

const PartnerStatusButtonContainer: FC<PartnerStatusButtonContainerProps> = memo(
  ({
    partnerStatus,
    channelUid,
    isRead,
    openApplicantsSendDialog,
    openApplicantsSentDialog,
    openApplicantsReceivedDialog,
  }) => {
    const onOpenMessenger = useCallback((): void => {
      if (!channelUid) {
        window.open(ServiceConfigure.messengerUrl, '_blank', messengerWindowFeatures);
        return;
      }
      window.open(`${ServiceConfigure.messengerUrl}/channels/${channelUid}/threads`, '_blank', messengerWindowFeatures);
    }, [channelUid]);
    const isValidTakkenLicenseNumber = useIsValidTakkenLicenseNumber();

    const partnerStatusButton = useMemo(() => {
      switch (partnerStatus) {
        case PartnerStatusCollection.partner:
          return {
            label: 'メッセージ',
            variant: 'outlined',
            icon: <MessageTextOutline fontSize="small" sx={{ marginRight: '4px' }} />,
            onClick: onOpenMessenger,
          };
        case PartnerStatusCollection.sentStandby:
          return {
            label: '申請を確認する',
            variant: 'outlined',
            // TODO: 申請確認画面作成時に差し替え
            onClick: openApplicantsSentDialog,
          };
        case PartnerStatusCollection.receivedStandby:
          return {
            label: '申請を確認する',
            variant: 'outlined',
            // TODO: 申請確認画面作成時に差し替え
            onClick: openApplicantsReceivedDialog,
          };
        case PartnerStatusCollection.notPartner:
          return {
            label: '取引先申請',
            variant: 'contained',
            icon: <AccountPlus fontSize="small" sx={{ marginRight: '4px' }} />,
            disabled: !isValidTakkenLicenseNumber,
            tooltip: isValidTakkenLicenseNumber ? '' : restrictionInternalAccountTooltipText,
            onClick: openApplicantsSendDialog,
          };
        default:
          return {
            label: 'メッセージ',
            variant: 'outlined',
            icon: <MessageTextOutline fontSize="small" sx={{ marginRight: '4px' }} />,
            onClick: onOpenMessenger,
          };
      }
    }, [
      isValidTakkenLicenseNumber,
      onOpenMessenger,
      openApplicantsReceivedDialog,
      openApplicantsSendDialog,
      openApplicantsSentDialog,
      partnerStatus,
    ]);

    return <PartnerStatusButtonPresenter partnerStatusButton={partnerStatusButton} isRead={isRead} />;
  }
);

export { PartnerStatusButtonContainer as PartnerStatusButton };

type PartnerStatusButtonPresenterProps = {
  partnerStatusButton: {
    label: string;
    variant: string;
    icon?: EmotionJSX.Element;
    disabled?: boolean;
    tooltip?: string;
    onClick: () => void;
  };
  isRead?: boolean | null;
};

export const PartnerStatusButtonPresenter: FC<PartnerStatusButtonPresenterProps> = ({
  partnerStatusButton,
  isRead,
}) => {
  return (
    <Badge
      badgeContent={isRead === false ? ' ' : 0}
      variant="dot"
      overlap="circular"
      css={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '& .MuiBadge-badge': {
          backgroundColor: '#F44336',
          position: 'relative',
          right: '10px',
        },
      }}
    >
      <Tooltip title={partnerStatusButton.tooltip}>
        <span>
          <Button
            variant={partnerStatusButton.variant as 'text' | 'contained' | 'outlined'}
            size="small"
            onClick={partnerStatusButton.onClick}
            disabled={partnerStatusButton.disabled}
          >
            {partnerStatusButton?.icon}
            {partnerStatusButton?.label}
          </Button>
        </span>
      </Tooltip>
    </Badge>
  );
};
