import { useAuth0 } from '@auth0/auth0-react';
import { EbMessage } from '@lib/components';
import { Box, Button } from '@mui/material';
import { OpenInNew, AccountQuestion } from 'mdi-material-ui';
import { useCallback, FC } from 'react';

import { ServiceConfigure } from '@/Services/Configure';

export const InvalidUserGuidePage: FC = () => {
  const { logout } = useAuth0();
  const onLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: ServiceConfigure.auth0RedirectUrl } });
  }, [logout]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      textAlign="center"
      p={2}
    >
      <EbMessage
        iconComponent={AccountQuestion}
        mainMessage="「会社・組織」情報が未登録です"
        secondMessage={[
          'いい生活Squareをご利用いただくには、会社・組織情報の登録が必要です。',
          '以下「アカウント管理」画面から入力いただき、ログアウト＆再ログインしてご利用ください。',
        ]}
      />
      <Box pt={4} display="flex" columnGap={2}>
        <Button
          variant="contained"
          color="primary"
          endIcon={<OpenInNew />}
          href={ServiceConfigure.esAccountUrl ?? ''}
          target="_blank"
          rel="noopener"
        >
          アカウント管理
        </Button>
        {/*ボタン間に余白を入れるためにspanを挿入*/}
        <span>
          <Button variant="outlined" color="primary" onClick={onLogout}>
            ログアウト
          </Button>
        </span>
      </Box>
    </Box>
  );
};
