import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { useMemo, Fragment } from 'react';
import { Loading } from '../Message';
import { textSxProps } from '../Styles';
export const AnnouncementContents = ({ isLoading, newsData }) => {
    return (_jsx(Box, Object.assign({ display: "flex", flexDirection: "column", width: "100%", height: "100%", overflow: "hidden auto" }, { children: isLoading ? (_jsx(Loading, {})) : newsData === undefined ? (_jsx(Typography, { children: "\u304A\u77E5\u3089\u305B\u306E\u8AAD\u307F\u8FBC\u307F\u306B\u5931\u6557\u3057\u307E\u3057\u305F" })) : (newsData.map(news => _jsx(NewsComponent, { newsData: news }, news.title))) })));
};
const NewsComponent = ({ newsData }) => {
    const documentElement = useMemo(() => _jsx(DocumentRenderer, { ast: newsData.document }), [newsData.document]);
    return (_jsxs(Box, Object.assign({ width: "100%", pb: 2 }, { children: [_jsx(NewsHeading, { title: newsData.title }), _jsx(Box, Object.assign({ p: 2, width: "100%" }, { children: documentElement }))] })));
};
const NewsHeading = ({ title }) => {
    return (_jsx(Box, Object.assign({ sx: Object.assign(Object.assign(Object.assign({}, textSxProps.lg), textSxProps.bold), { borderLeftColor: theme => theme.palette.primary.main, borderLeftStyle: 'solid', borderLeftWidth: '8px', backgroundColor: theme => theme.palette.grey[100], paddingLeft: 2, paddingRight: 2, paddingTop: 1, paddingBottom: 1 }) }, { children: title })));
};
const DocumentRenderer = ({ ast }) => {
    return (_jsx(Box, Object.assign({ width: "100%", sx: {
            '& p': {
                marginBottom: 1,
            },
            '& li > p': {
                margin: '0',
            },
            '& ol': {
                paddingInlineStart: '1.5rem',
            },
            '& ul': {
                paddingInlineStart: '1.5rem',
            },
        } }, { children: _jsx(DocumentAstRenderer, { ast: ast }) })));
};
const DocumentAstRenderer = ({ ast }) => {
    return (_jsx(Fragment, { children: ast.map((a, i) => (_jsx(SingleDocumentAstRenderer, { ast: a }, i))) }));
};
const SingleDocumentAstRenderer = ({ ast }) => {
    switch (ast.type) {
        case 'text':
            return (_jsx(Fragment, { children: ast.content
                    .split('\n')
                    .map((line, index) => [line, _jsx("br", {}, `${line}-${index}-br`)])
                    .flat()
                    .slice(0, -1) }));
        case 'link':
            return (_jsx("a", Object.assign({ href: ast.url, target: "_blank", rel: "noopener noreferrer" }, { children: ast.content })));
        case 'p':
            return (_jsx(Typography, { children: _jsx(DocumentAstRenderer, { ast: ast.children }) }));
        case 'em':
            return (_jsx(Typography, Object.assign({ sx: Object.assign(Object.assign({}, textSxProps.bold), textSxProps.attentionText), component: "span" }, { children: _jsx(DocumentAstRenderer, { ast: ast.children }) })));
        case 'h1':
            return (_jsx(Typography, Object.assign({ variant: "h1", sx: Object.assign(Object.assign({}, textSxProps.bold), textSxProps.lg) }, { children: _jsx(DocumentAstRenderer, { ast: ast.children }) })));
        case 'h2':
            return (_jsx(Typography, Object.assign({ variant: "h2", sx: Object.assign(Object.assign({}, textSxProps.bold), textSxProps.md) }, { children: _jsx(DocumentAstRenderer, { ast: ast.children }) })));
        case 'ul':
            return (_jsx("ul", { children: _jsx(DocumentAstRenderer, { ast: ast.children }) }));
        case 'ol':
            return (_jsx("ol", { children: _jsx(DocumentAstRenderer, { ast: ast.children }) }));
        case 'li':
            return (_jsx("li", { children: _jsx(DocumentAstRenderer, { ast: ast.children }) }));
        default:
            return null;
    }
};
