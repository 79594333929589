import { useIsExtraSmallDevice } from '@lib/components';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { FC, memo } from 'react';

type PartnerApplicantsStepperContainerProps = {
  activeStepNumber: number;
};

const PartnerApplicantsStepperContainer: FC<PartnerApplicantsStepperContainerProps> = memo(({ activeStepNumber }) => {
  const isExtraSmallDevice = useIsExtraSmallDevice();
  return (
    <PartnerApplicantsStepperPresenter isExtraSmallDevice={isExtraSmallDevice} activeStepNumber={activeStepNumber} />
  );
});

export { PartnerApplicantsStepperContainer as PartnerApplicantsStepper };

type PartnerApplicantsStepperPresenterProps = {
  isExtraSmallDevice: boolean;
  activeStepNumber: number;
};

const steps = [
  {
    label: '取引先申請',
    description: 'ご利用規約を十分にご理解の上、取引先申請を送付してください。',
  },
  {
    label: '承認審査',
    description:
      '貴社情報を元に承認審査を行います。諸事情により、却下させていただく場合もございます。あらかじめご了承ください。',
  },
  {
    label: '利用/メッセージ',
    description: '画面上部のメッセージまたは取引先画面のメッセージからご利用ください',
  },
];

export const PartnerApplicantsStepperPresenter: FC<PartnerApplicantsStepperPresenterProps> = memo(
  ({ isExtraSmallDevice, activeStepNumber }) => {
    return isExtraSmallDevice ? (
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStepNumber} alternativeLabel>
          {steps.map(step => (
            <Step key={step.label}>
              <StepLabel>
                <Typography fontWeight="bold">{step.label}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    ) : (
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStepNumber} alternativeLabel>
          {steps.map(step => (
            <Step key={step.label}>
              <StepLabel>
                <Typography fontWeight="bold">{step.label}</Typography>
              </StepLabel>
              <Typography>{step.description}</Typography>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }
);
