import { ErrorAnnounce } from '@e-seikatsu/design-system';
import { Button, Container } from '@mui/material';
import { FC } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export const GeneralNotFoundPageContainer: FC = () => {
  const navigate = useNavigate();
  return <GeneralNotFoundPagePresenter navigate={navigate} />;
};

export { GeneralNotFoundPageContainer as GeneralNotFoundPage };

type GeneralNotFoundPagePresenterProps = {
  navigate: NavigateFunction;
};

export const GeneralNotFoundPagePresenter: FC<GeneralNotFoundPagePresenterProps> = ({ navigate }) => {
  return (
    <Container
      sx={{
        paddingTop: '10vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      <ErrorAnnounce
        title={
          <>
            お探しのページは
            <wbr />
            見つかりませんでした
          </>
        }
        comment={
          <>
            お探しのページは一時的にアクセスができないか、
            <wbr />
            移動または削除された可能性があります。
            <br />
            URLが正しく入力されているかご確認ください。
            <br />
            またはトップページから再度ページを検索、 ご参照ください。
          </>
        }
        note="エラーコード：404"
      />
      <Button variant="contained" onClick={() => navigate('/')}>
        トップページへ
      </Button>
    </Container>
  );
};
