import {developerEmailDomain} from '@/Consts/Values';

/**
 * 開発者（ログインアカウントのメールアドレスが`*@e-seikatsu.co.jp`）でログインしているどうかを判定する
 * @param email ログインしているアカウントのメールアドレス
 * @returns 開発者かどうか
 */

export const isEseikatsuDeveloper = (email: string | null): boolean => {
  if (email == null) {
    return false;
  }
  return email.includes(developerEmailDomain);
};
