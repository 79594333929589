import { makeCodeAssertionFunc } from '@app/utils';

export const ChushajoConditions = {
  chushajoExist: 'chushajoExist',
  churinjoExist: 'churinjoExist',
  bikeStorageExist: 'bikeStorageExist',
} as const;
export type ChushajoConditions = typeof ChushajoConditions[keyof typeof ChushajoConditions];
export const ChushajoConditionsTextMap: Record<ChushajoConditions, string> = {
  chushajoExist: '駐車場あり',
  churinjoExist: '駐輪場あり',
  bikeStorageExist: 'バイク置場あり',
};
export const asChushajoCondition = makeCodeAssertionFunc(Object.values(ChushajoConditions));

export const FloorConditions = {
  first: 'floorFirst',
  secondOrAbove: 'floorSecondOrAbove',
  top: 'floorTop',
} as const;
export type FloorConditions = typeof FloorConditions[keyof typeof FloorConditions];
export const FloorConditionsLabelMap: { [_ in FloorConditions]: string } = {
  floorFirst: '1階',
  floorSecondOrAbove: '2階以上',
  floorTop: '最上階',
};
export const asFloorCondition = makeCodeAssertionFunc(Object.values(FloorConditions));

export const NyukyoConditions = {
  petMayBeOK: 'petMayBeOK',
  musicalInstMayBeOK: 'musicalInstMayBeOK',
  officeUsageMayBeOK: 'officeUsageMayBeOK',
  womanOnly: 'womanOnly',
  twoPersonMayBeOK: 'twoPersonMayBeOK',
  foreignerMayBeOK: 'foreignerMayBeOK',
  studentOnly: 'studentOnly',
  roomShareMayBeOK: 'roomShareMayBeOK',
  seniorCitizensMayBeOK: 'seniorCitizensMayBeOK',
  guarantorUnnecessary: 'guarantorUnnecessary',
} as const;
export type NyukyoConditions = typeof NyukyoConditions[keyof typeof NyukyoConditions];
export const NyukyoConditionsLabelMap: Record<NyukyoConditions, string> = {
  petMayBeOK: 'ペット相談可',
  musicalInstMayBeOK: '楽器相談可',
  officeUsageMayBeOK: '事務所相談可',
  womanOnly: '女性限定',
  twoPersonMayBeOK: '二人入居相談可',
  foreignerMayBeOK: '外国人相談可',
  studentOnly: '学生限定',
  roomShareMayBeOK: 'ルームシェア相談可',
  seniorCitizensMayBeOK: '高齢者相談可',
  guarantorUnnecessary: '保証人不要',
};
export const asNyukyoCondition = makeCodeAssertionFunc(Object.values(NyukyoConditions));

export const KitchenConditions = {
  gasBurnerCanBePlaced: 'gasBurnerCanBePlaced',
  gasBurnerIsPlaced: 'gasBurnerIsPlaced',
  twoBurner: 'twoBurner',
  moreThanThreeBurner: 'moreThanThreeBurner',
  ihCooker: 'ihCooker',
  systemKitchen: 'systemKitchen',
  counterKitchen: 'counterKitchen',
} as const;
export type KitchenConditions = typeof KitchenConditions[keyof typeof KitchenConditions];
export const KitchenConditionsLabelMap: Record<KitchenConditions, string> = {
  gasBurnerCanBePlaced: 'ガスコンロ設置可',
  gasBurnerIsPlaced: 'ガスコンロ設置済み',
  twoBurner: 'コンロ2口',
  moreThanThreeBurner: 'コンロ3口以上',
  ihCooker: 'IHクッキングヒーター',
  systemKitchen: 'システムキッチン',
  counterKitchen: 'カウンターキッチン',
};
export const asKitchenCondition = makeCodeAssertionFunc(Object.values(KitchenConditions));

export const SanitaryEquipConditions = {
  separatedLavatory: 'separatedLavatory',
  bathroomDryer: 'bathroomDryer',
  washingToiletSeat: 'washingToiletSeat',
  bathReheat: 'bathReheat',
  separatedBathAndToilet: 'separatedBathAndToilet',
  inroomWashingMachinePlace: 'inroomWashingMachinePlace',
} as const;
export type SanitaryEquipConditions = typeof SanitaryEquipConditions[keyof typeof SanitaryEquipConditions];
export const SanitaryEquipConditionsLabelMap: Record<SanitaryEquipConditions, string> = {
  separatedLavatory: '洗面所独立',
  bathroomDryer: '浴室乾燥機',
  washingToiletSeat: '温水洗浄便座',
  bathReheat: '追い焚き',
  separatedBathAndToilet: 'バス・トイレ別',
  inroomWashingMachinePlace: '室内洗濯機置場',
};
export const asSanitaryEquipCondition = makeCodeAssertionFunc(Object.values(SanitaryEquipConditions));

export const IndoorEquipConditions = {
  airConAvailable: 'airConAvailable',
  floorHeater: 'floorHeater',
  kagu_flag: 'kagu_flag',
  kaden_flag: 'kaden_flag',
} as const;
export type IndoorEquipConditions = typeof IndoorEquipConditions[keyof typeof IndoorEquipConditions];
export const IndoorEquipConditionsLabelMap: Record<IndoorEquipConditions, string> = {
  airConAvailable: 'エアコン付き',
  floorHeater: '床暖房',
  kagu_flag: '家具付き',
  kaden_flag: '家電付き',
};
export const asIndoorEquipCondition = makeCodeAssertionFunc(Object.values(IndoorEquipConditions));

export const RoomStorageConditions = {
  walkinCloset: 'walkinCloset',
  loftAvailable: 'loftAvailable',
  trunkRoom: 'trunkRoom',
} as const;
export type RoomStorageConditions = typeof RoomStorageConditions[keyof typeof RoomStorageConditions];
export const RoomStorageConditionsLabelMap: Record<RoomStorageConditions, string> = {
  walkinCloset: 'ウォークインクローゼット',
  loftAvailable: 'ロフト付き',
  trunkRoom: 'トランクルーム',
};
export const asRoomStorageCondition = makeCodeAssertionFunc(Object.values(RoomStorageConditions));

export const TelecomEquipConditions = {
  bscs: 'bscs',
  cableTv: 'cableTv',
  internet_free_flag: 'internet_free_flag',
} as const;
export type TelecomEquipConditions = typeof TelecomEquipConditions[keyof typeof TelecomEquipConditions];
export const TelecomEquipConditionsLabelMap: Record<TelecomEquipConditions, string> = {
  bscs: 'BS/CS',
  cableTv: 'ケーブルテレビ',
  internet_free_flag: 'インターネット無料',
};
export const asTelecomEquipCondition = makeCodeAssertionFunc(Object.values(TelecomEquipConditions));

export const SecurityConditions = {
  intercomWithMonitor: 'intercomWithMonitor',
  autoLock: 'autoLock',
  deliveryBox: 'deliveryBox',
  securityCamera: 'securityCamera',
  caretakerResident: 'caretakerResident',
  hasSecurity: 'hasSecurity',
} as const;
export type SecurityConditions = typeof SecurityConditions[keyof typeof SecurityConditions];
export const SecurityConditionsLabelMap: Record<SecurityConditions, string> = {
  intercomWithMonitor: 'モニター付きインターホン',
  autoLock: 'オートロック',
  deliveryBox: '宅配ボックス',
  securityCamera: '防犯カメラ',
  caretakerResident: '管理人常駐',
  hasSecurity: 'セキュリティあり',
};
export const asSecurityCondition = makeCodeAssertionFunc(Object.values(SecurityConditions));

export const GasConditions = {
  cityGas: 'cityGas',
  propane: 'propane',
} as const;
export type GasConditions = typeof GasConditions[keyof typeof GasConditions];
export const GasConditionsLabelMap: Record<GasConditions, string> = {
  cityGas: '都市ガス',
  propane: 'プロパンガス',
};
export const asGasCondition = makeCodeAssertionFunc(Object.values(GasConditions));

export const BuildingEquipConditions = {
  elevator: 'elevator',
  dumpInArea: 'dumpInArea',
  designers: 'designers',
  condominiumRent: 'condominiumRent',
  verandaBalcony: 'verandaBalcony',
  roofBalcony: 'roofBalcony',
  privateGarden: 'privateGarden',
  barrierFree: 'barrierFree',
  flooring: 'flooring',
  cornerRoom: 'cornerRoom',
  renovatedBuilding: 'renovatedBuilding',
  maisonette: 'maisonette',
  insulationFacility: 'insulationFacility',
  allElectricHome: 'allElectricHome',
} as const;
export type BuildingEquipConditions = typeof BuildingEquipConditions[keyof typeof BuildingEquipConditions];
export const BuildingEquipConditionsLabelMap: Record<BuildingEquipConditions, string> = {
  elevator: 'エレベーター',
  dumpInArea: '敷地内ゴミ置き場',
  designers: 'デザイナーズ',
  condominiumRent: '分譲賃貸',
  verandaBalcony: 'ベランダ・バルコニー',
  roofBalcony: 'ルーフバルコニー',
  privateGarden: '専用庭',
  barrierFree: 'バリアフリー',
  flooring: 'フローリング',
  cornerRoom: '角部屋',
  renovatedBuilding: 'リノベーション物件',
  maisonette: 'メゾネット',
  insulationFacility: '防音設備',
  allElectricHome: 'オール電化',
};
export const asBuildingEquipCondition = makeCodeAssertionFunc(Object.values(BuildingEquipConditions));

export type KodawariConditions =
  | ChushajoConditions
  | FloorConditions
  | NyukyoConditions
  | KitchenConditions
  | SanitaryEquipConditions
  | IndoorEquipConditions
  | RoomStorageConditions
  | TelecomEquipConditions
  | SecurityConditions
  | GasConditions
  | BuildingEquipConditions;

export const KodawariConditionsTextMap = {
  ...ChushajoConditionsTextMap,
  ...FloorConditionsLabelMap,
  ...NyukyoConditionsLabelMap,
  ...KitchenConditionsLabelMap,
  ...SanitaryEquipConditionsLabelMap,
  ...IndoorEquipConditionsLabelMap,
  ...RoomStorageConditionsLabelMap,
  ...TelecomEquipConditionsLabelMap,
  ...SecurityConditionsLabelMap,
  ...GasConditionsLabelMap,
  ...BuildingEquipConditionsLabelMap,
};

export const asKodawariConditions = makeCodeAssertionFunc<KodawariConditions>(
  Object.values({
    ...ChushajoConditions,
    ...FloorConditions,
    ...NyukyoConditions,
    ...KitchenConditions,
    ...SanitaryEquipConditions,
    ...IndoorEquipConditions,
    ...RoomStorageConditions,
    ...TelecomEquipConditions,
    ...SecurityConditions,
    ...GasConditions,
    ...BuildingEquipConditions,
  })
);
