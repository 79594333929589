import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Tooltip, useTheme } from '@mui/material';
/**
 * Menuの展開中も他コンポーネントのクリックやスクロールを阻害しないMenu
 * 参考URL：https://material-ui.com/components/menus/#menulist-composition
 */
export const MenuWithoutBlock = props => {
    const handleClick = (event, onSelected) => {
        event.stopPropagation();
        onSelected();
    };
    const theme = useTheme();
    return (_jsx(Popper, Object.assign({ open: props.isMenuOpen, anchorEl: props.anchorEl, placement: "bottom-end", role: undefined, transition: true, sx: { zIndex: theme.zIndex.drawer + 200 }, "data-testclass": "popperMenu" }, { children: popperProps => (_jsx(Grow, Object.assign({}, popperProps.TransitionProps, { style: { transformOrigin: popperProps.placement === 'bottom' ? 'center top' : 'center bottom' } }, { children: _jsx(Paper, { children: _jsx(ClickAwayListener, Object.assign({ onClickAway: props.setMenuClose }, { children: _jsx(MenuList, Object.assign({ id: "split-button-menu" }, { children: props.menuActions.map((action, index) => (_jsx(Tooltip, Object.assign({ disableFocusListener: true, disableTouchListener: true, title: action.disabled ? action.disabledText : '' }, { children: _jsx("span", { children: _jsx(MenuItem, Object.assign({ onClick: e => {
                                        e.stopPropagation();
                                        if (!action.disabled) {
                                            props.setMenuClose();
                                            handleClick(e, action.onSelected);
                                        }
                                    }, 
                                    // disable 状態の際に onClick イベントが発火しないので、スタイルで disable 状態を表現
                                    sx: action.disabled
                                        ? {
                                            color: theme.palette.grey[400],
                                            cursor: 'default',
                                            '&:hover': {
                                                backgroundColor: 'white',
                                            },
                                        }
                                        : undefined }, { children: action.text })) }) }), index))) })) })) }) }))) })));
};
