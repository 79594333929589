import { css } from '@emotion/react';
import { Loading, useIsExtraSmallDevice, useOnOffState } from '@lib/components';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Close } from 'mdi-material-ui';
import { FC, Fragment, memo, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PartnerApplicantsCancelDialog } from './PartnerApplicantsCancelDialog';
import { PartnerApplicantsSentMessage } from './PartnerApplicantsSentMessage';
import { PartnerApplicantsStepper } from './PartnerApplicantsStepper';

import { DisplayItem, applicantsItemStyle } from '@/Components/Forms/DisplayItem';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { usePartnerApplicant } from '@/Hooks/Esa/usePartnerApplicants';
import { PartnerApplicants, PartnerApplicantsStatusCollection } from '@/Models/Esa/PartnerApplicants';
import { RouteDefinitions } from '@/Pages/RouteDefinitions';
import { theme } from '@/Theme';
import { makeTakkenLicenseNumber } from '@/Utils/DisplayText/Takken';
import { getKameiDantai } from '@/Utils/EsaUtils';
import { getPartnerApplicantDomainOrganizationName } from '@/Utils/PartnerApplicantsUtils';
import { getDenwaNumber, getJusho } from '@/Utils/PartnerUtils';

type PartnerApplicantsSentDialogContainerProps = {
  isOpenViaDialog?: boolean;
  closeViaDialog?: () => void;
  viaPartnerApplicant?: PartnerApplicants;
};

const PartnerApplicantsSentDialogContainer: FC<PartnerApplicantsSentDialogContainerProps> = memo(
  ({ isOpenViaDialog, closeViaDialog, viaPartnerApplicant }) => {
    const isExtraSmallDevice = useIsExtraSmallDevice();
    const user = useCurrentUser();
    const isAdminUser = useMemo(() => {
      return user.role.isAdmin;
    }, [user.role]);

    const [isOpenCancelDialog, { setTrue: openCancelDialog, setFalse: closeCancelDialog }] = useOnOffState(false);

    const { partnerApplicantUid } = useParams();
    const { model: directPartnerApplicant, isLoading } = usePartnerApplicant(partnerApplicantUid ?? '');

    const navigate = useNavigate();
    const [isOpenDirectDialog, setIsOpenDirectDialog] = useState(true);
    const closeDirectDialog = useCallback(() => {
      setIsOpenDirectDialog(false);
      navigate(`${RouteDefinitions.partnerApplicantsSent.path}?states=${directPartnerApplicant?.state}`);
    }, [directPartnerApplicant, navigate]);

    // NOTE: 組織検索経由の場合、ダイアログを閉じたときに元の検索結果に戻れないので、 via の方を使用し、それ以外は direct の方を使用している
    const isOpenDialog = isOpenViaDialog ? isOpenViaDialog : isOpenDirectDialog;
    const closeDialog = closeViaDialog ? closeViaDialog : closeDirectDialog;
    const partnerApplicant = viaPartnerApplicant ? viaPartnerApplicant : directPartnerApplicant;

    const applicantsSource: { label: string; value?: string }[] = useMemo(() => {
      if (!partnerApplicant) {
        return [];
      }
      return [
        ...getPartnerApplicantDomainOrganizationName(
          partnerApplicant.partnerDomain,
          partnerApplicant.partnerOrganization
        ),
        {
          label: '住所',
          value: getJusho(partnerApplicant.partnerOrganization.jusho),
        },
        {
          label: '電話',
          value: getDenwaNumber(partnerApplicant.partnerOrganization.denwaList),
        },
        {
          label: '免許番号',
          value: makeTakkenLicenseNumber(
            partnerApplicant.partnerDomain.takkenLicense?.licensor ?? '',
            partnerApplicant.partnerDomain.takkenLicense?.updateCount ?? '',
            partnerApplicant.partnerDomain.takkenLicense?.number ?? ''
          ),
        },
        {
          label: '加盟団体',
          value: getKameiDantai(partnerApplicant.partnerOrganization.kameiDantaiList),
        },
      ];
    }, [partnerApplicant]);

    const organizationSource: { label: string; value?: string }[] = useMemo(() => {
      if (!partnerApplicant) {
        return [];
      }
      return [
        {
          label: '会社名',
          value: partnerApplicant.domain.name,
        },
        {
          label: '組織名（店舗名）',
          value: partnerApplicant.organization.name,
        },
        {
          label: '申請送付者',
          value: partnerApplicant.userName,
        },
      ];
    }, [partnerApplicant]);

    if (isLoading) {
      return <Loading />;
    }

    return (
      <PartnerApplicantsSentDialogPresenter
        isExtraSmallDevice={isExtraSmallDevice}
        isOpenDialog={isOpenDialog}
        closeDialog={closeDialog}
        isOpenCancelDialog={isOpenCancelDialog}
        openCancelDialog={openCancelDialog}
        closeCancelDialog={closeCancelDialog}
        partnerApplicant={partnerApplicant}
        applicantsSource={applicantsSource}
        organizationSource={organizationSource}
        isAdminUser={isAdminUser}
      />
    );
  }
);

export { PartnerApplicantsSentDialogContainer as PartnerApplicantsSentDialog };

type PartnerApplicantsSentDialogPresenterProps = {
  isExtraSmallDevice: boolean;
  isOpenDialog: boolean;
  closeDialog: () => void;
  isOpenCancelDialog: boolean;
  openCancelDialog: () => void;
  closeCancelDialog: () => void;
  partnerApplicant: PartnerApplicants | null;
  applicantsSource: {
    label: string;
    value?: string;
  }[];
  organizationSource: {
    label: string;
    value?: string | undefined;
  }[];
  isAdminUser: boolean;
};

const dialogTitleStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '50px',
});

const dialogActionStyle = css({
  textAlign: 'right',
});

export const PartnerApplicantsSentDialogPresenter: FC<PartnerApplicantsSentDialogPresenterProps> = memo(
  ({
    isExtraSmallDevice,
    isOpenDialog,
    closeDialog,
    isOpenCancelDialog,
    openCancelDialog,
    closeCancelDialog,
    partnerApplicant,
    applicantsSource,
    organizationSource,
    isAdminUser,
  }) => (
    <Fragment>
      <Dialog open={isOpenDialog} onClose={closeDialog} maxWidth="md" fullWidth fullScreen={isExtraSmallDevice}>
        <DialogTitle css={dialogTitleStyle}>
          <Box display="flex" alignItems="center" fontWeight="bold">
            申請詳細
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <IconButton onClick={closeDialog} size="large">
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {partnerApplicant?.state !== PartnerApplicantsStatusCollection.rejected && (
            <PartnerApplicantsStepper
              activeStepNumber={partnerApplicant?.state === PartnerApplicantsStatusCollection.standby ? 1 : 2}
            />
          )}
          <PartnerApplicantsSentMessage
            modifiedAt={partnerApplicant?.modifiedAt ?? ''}
            partnerApplicantStatus={partnerApplicant?.state}
          />
          <Box fontWeight="bold" fontSize="18px" borderLeft="8px solid #52A0D6" pl={1} mt={2} mb={1}>
            申請先
          </Box>
          <DisplayItem itemList={applicantsSource} />
          <Box fontWeight="bold" fontSize="18px" borderLeft="8px solid #52A0D6" pl={1} mt={2} mb={1}>
            申請元
          </Box>
          <DisplayItem itemList={organizationSource} />
          {partnerApplicant?.state === PartnerApplicantsStatusCollection.rejected && (
            <Grid container>
              <Grid item css={[applicantsItemStyle, { backgroundColor: theme.palette.secondary.light }]} xs={12} md={4}>
                <Typography fontWeight="bold">審査コメント</Typography>
              </Grid>
              <Grid item xs={12} md={8} css={applicantsItemStyle}>
                {partnerApplicant?.rejectionComment}
              </Grid>
            </Grid>
          )}
          <Box fontWeight="bold" fontSize="18px" borderLeft="8px solid #52A0D6" pl={1} mt={2} mb={1}>
            申請コメント
          </Box>
          <TextField
            variant="standard"
            multiline
            minRows={2}
            fullWidth
            value={partnerApplicant?.comment}
            inputProps={{ readOnly: true }}
          />
        </DialogContent>
        <Divider />
        {partnerApplicant?.state === PartnerApplicantsStatusCollection.standby && (
          <DialogActions css={dialogActionStyle}>
            <Tooltip title={!isAdminUser ? '管理者のみ取消できます' : ''} placement="top-start">
              <div>
                <LoadingButton
                  variant="contained"
                  color="error"
                  css={{ marginRight: '8px' }}
                  disabled={!isAdminUser}
                  onClick={openCancelDialog}
                >
                  申請取消
                </LoadingButton>
              </div>
            </Tooltip>
          </DialogActions>
        )}
      </Dialog>
      <PartnerApplicantsCancelDialog
        isOpenCancelDialog={isOpenCancelDialog}
        closeCancelDialog={closeCancelDialog}
        closeDetailDialog={closeDialog}
        partnerApplicantsUid={partnerApplicant?.partnerApplicantUid ?? ''}
      />
    </Fragment>
  )
);
