import { HttpError } from '@app/errors';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorMessage, SumaiLoading } from '@lib/components';
import { Box } from '@mui/material';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useAsync } from 'react-use';

import { SumaiEntryRedirectErrorMessage } from '@/Consts/ErrorMessages';
import { useDependency } from '@/Hooks/DependencyHook';

export const PreviewReservationRedirect: FC = () => {
  const sumaiEntryService = useDependency('sumaiEntryService');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const bukkenGuid = searchParams.get('guid');
  const domainGuid = searchParams.get('domainGuid');
  const { getAccessTokenSilently } = useAuth0();

  const { error } = useAsync(async () => {
    const userDomain = await sumaiEntryService.getUserDomain();
    if (!userDomain) {
      return;
    }
    if (!bukkenGuid || !domainGuid) {
      throw new Error('bukkenGuid or domainGuid is not found');
    }
    const token = await getAccessTokenSilently();
    if (!token) {
      throw new Error('token is not found');
    }
    if (userDomain) {
      const response = await sumaiEntryService.fetchNaikenYoyakuUrl(
        bukkenGuid,
        domainGuid,
        'create/naikenYoyaku',
        token
      );
      window.location.href = response.redirectUrl;
    }
  }, [bukkenGuid, domainGuid, getAccessTokenSilently, sumaiEntryService]);

  if (bukkenGuid === null) {
    return (
      <Box p={2} textAlign="center">
        物件が指定されていないため、内見予約に進めませんでした。
        <br />
        タブを閉じて内見予約をやり直して下さい。
      </Box>
    );
  }

  if (error !== undefined) {
    const errorMessage =
      error instanceof HttpError && error.statusCode === 404
        ? SumaiEntryRedirectErrorMessage.notFound
        : SumaiEntryRedirectErrorMessage.default;
    return (
      <Box height="100%" p={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
        <ErrorMessage header={errorMessage.messageHeader} mainMessage={errorMessage.messageText} />
      </Box>
    );
  }

  return (
    <Box height="100vh">
      <SumaiLoading />
    </Box>
  );
};
