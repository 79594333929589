import { useEffect } from 'react';

import { useCurrentUser } from './Esa/RequireCurrentUser';
import { useEsaDomain } from './Esa/useEsaDomain';
import { useCustomerViewStateHooks } from './customerViewHooks';

import { ServiceConfigure } from '@/Services/Configure';

interface FullstarUserInfo {
  company_name?: string;
  user_name?: string;
  user_email?: string | null;
}

interface Fullstar {
  show: (uuid: string, userId: string, userInfo: FullstarUserInfo) => void;
  clear: () => void;
  tracking: () => void;
  survey: () => void;
  guide: () => void;
  checklist: () => void;
  tooltip: () => void;
}

declare global {
  interface Window {
    fs?: Fullstar;
  }
}

/**
 * ログインしているユーザの情報に基づいてFullstarのパラメータを設定する
 */
export const useFullstar: () => void = () => {
  const fullstarUUID = ServiceConfigure.fullstarUUID;
  const userInfo = useCurrentUser();
  const { domain } = useEsaDomain(userInfo.domainUid);
  const customerViewStateHooks = useCustomerViewStateHooks();
  const isCustomerView = customerViewStateHooks.isCustomerView;
  useEffect(() => {
    const fs = window.fs;
    if (!fs) {
      return;
    }
    if (isCustomerView) {
      // 接客用表示がONの時、Fullstarを非表示
      fs.clear();
      return;
    }
    if (fullstarUUID) {
      fs.clear();
      fs.show(fullstarUUID, userInfo.userUid, {
        company_name: domain?.name,
        user_name: userInfo.name,
        user_email: userInfo.email,
      });
      fs.tracking();
      fs.survey();
      fs.guide();
      fs.checklist();
      fs.tooltip();
    }
  }, [domain?.name, fullstarUUID, isCustomerView, userInfo.email, userInfo.name, userInfo.userUid]);
};
