import { HttpError } from '@app/errors';
import { getViewportHeight } from '@app/utils';
import { EsLoading } from '@lib/components';
import { Box } from '@mui/material';
import { FC, createContext, useContext, useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { KeyedMutator } from 'swr';

import { useEsaModel } from './useEsaModel';

import { emptyLoginContext } from '@/Consts/EsaDummyData';
import { LoginContext } from '@/Models/Esa/EsaLoginContext';
import { ServiceConfigure } from '@/Services/Configure';

const Context = createContext<{
  context: LoginContext | null;
  mutate: KeyedMutator<LoginContext>;
} | null>(null);

export const RequireLoginContext: FC = () => {
  const { isLoading, error, model, mutate } = useEsaModel<LoginContext>({
    api: {
      audience: ServiceConfigure.auth0Audience,
      baseUrl: ServiceConfigure.esAccountApi,
      path: '/login_context',
    },
  });
  const navigate = useNavigate();

  // Auth0 ユーザが esa-api に存在しない場合、一度ログアウトさせる
  useEffect(() => {
    if (error instanceof HttpError) {
      navigate('/login');
    }
  }, [error, navigate]);

  const providerValue = useMemo(
    (): {
      context: LoginContext | null;
      mutate: KeyedMutator<LoginContext>;
    } => ({ context: model, mutate }),
    [model, mutate]
  );

  if (isLoading) {
    return (
      <Box height={getViewportHeight(100)} width="100%" overflow="hidden">
        <EsLoading />
      </Box>
    );
  }

  return (
    <Context.Provider value={providerValue}>
      <Outlet />
    </Context.Provider>
  );
};

export const useLoginContext = (): LoginContext => {
  const loginContext = useContext(Context);

  if (loginContext?.context == null) {
    return emptyLoginContext;
  }

  const users = loginContext.context.users.filter(user => user.ebone == null || user.enableEntryFeature);

  return {
    ...loginContext?.context,
    users,
  };
};
