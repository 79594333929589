import { styled } from '@mui/material';
import { SnackbarContent } from 'notistack';
import { forwardRef, ReactNode } from 'react';

const MySnackbarContent = styled(SnackbarContent)({
  width: '100%',
});
export const SnackMessage = forwardRef<HTMLDivElement, { id: string | number; message: string | ReactNode }>(
  (props, ref) => {
    return <MySnackbarContent ref={ref}>{props.message}</MySnackbarContent>;
  }
);
