import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Button, TextField, IconButton } from '@e-seikatsu/design-system';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
export const TextFormDialog = props => {
    var _a;
    const allowEmptyText = (_a = props.allowEmptyText) !== null && _a !== void 0 ? _a : false;
    const [formText, setFormText] = useState('');
    const [isError, setIsError] = useState(false);
    const handleValidation = (e) => {
        if (!allowEmptyText) {
            setIsError(e.target.value.length === 0);
        }
        setFormText(e.target.value);
    };
    const onClose = () => {
        props.onClose();
        setFormText('');
        setIsError(false);
    };
    useEffect(() => {
        var _a;
        setFormText((_a = props.defaultValue) !== null && _a !== void 0 ? _a : '');
    }, [props.defaultValue, props.isOpen]);
    // 初期状態ではlength === 0かつisError === falseになるためエラーの有無ではなく文字列長での判定が必要
    const canSave = useMemo(() => {
        return allowEmptyText || formText.length > 0;
    }, [allowEmptyText, formText.length]);
    return (_jsxs(Dialog, Object.assign({ open: props.isOpen, onClose: onClose, "aria-labelledby": "form-dialog-title", fullWidth: true, maxWidth: "sm" }, { children: [_jsxs(DialogTitle, Object.assign({ id: "form-dialog-title", display: "flex", sx: { alignItems: 'center', justifyContent: 'space-between' } }, { children: [_jsx(Box, { children: props.title }), _jsx(IconButton, { onClick: onClose, size: "medium", icon: "close", variant: "iconOnly", color: "black" })] })), _jsxs(DialogContent, { children: [_jsx(DialogContentText, Object.assign({ sx: { marginBottom: '8px' } }, { children: props.contentText })), _jsx(TextField, { length: "full", autoFocus: true, onChange: handleValidation, value: formText, label: props.formLabelText, placeholder: props.placeHolderText, required: !allowEmptyText, hasError: isError, helperText: isError ? props.onErrorText : '', onKeyDown: (e) => {
                            e.stopPropagation();
                            if (!e.nativeEvent.isComposing && e.key === 'Enter') {
                                if (!canSave) {
                                    return;
                                }
                                props.onSubmit(formText);
                                onClose();
                            }
                        } })] }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ onClick: onClose, variant: "text" }, { children: "\u30AD\u30E3\u30F3\u30BB\u30EB" })), _jsx(Button, Object.assign({ disabled: !canSave, onClick: () => {
                            props.onSubmit(formText);
                            props.onClose();
                        }, variant: "contained", color: "primary" }, { children: props.submitButtonText }))] })] })));
};
