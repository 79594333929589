import { css } from '@emotion/react';
import { Box, styled, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

import { theme } from '@/Theme';

export const SearchListGridBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: ['144px', '2fr', '1fr', '1fr', '1fr', '1fr', '1fr'].join(' '),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const LoadingWrapperBox = styled(Box)(({ theme }) => ({
  maxHeight: '100%',
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    maxHeight: '16rem',
  },
}));

export const transitMapSearchButtonZIndex = 6;
export const TransitButtonStyle = () =>
  css({
    position: 'fixed',
    right: '1rem',
    zIndex: theme.zIndex.drawer - 1,
    background: theme.palette.background.paper,
    width: '48px',
    height: '48px',
    fontSize: '24px',
  });

type SearchResultSxPropsKeys =
  | 'headerGridRows'
  | 'listGridRows'
  | 'mobileListGrid'
  | 'rowSpan2'
  | 'colSpan3'
  | 'colSpan5'
  | 'flexGrow1'
  | 'spaceX1'
  | 'spaceY1'
  | 'width8'
  | 'maxWidth10'
  | 'maxWidth20'
  | 'border'
  | 'borderTop'
  | 'borderBottom'
  | 'borderYBetween'
  | 'contentBox'
  | 'listItemCard'
  | 'listItemCardSmall'
  | 'chip'
  | 'emptyStateBox'
  | 'emptyStateIcon'
  | 'errorWrapper'
  | 'actionButton'
  | 'actionButtonOutlined'
  | 'actionSquareButton'
  | 'actionButtonStartIcon'
  | 'actionSquareButtonStartIcon'
  | 'buttonListContainer'
  | 'buttonListItem';

export const searchResultSxProps: Record<SearchResultSxPropsKeys, SystemStyleObject<Theme>> = {
  headerGridRows: {
    gridTemplateRows: 'auto',
  },
  listGridRows: {
    gridTemplateRows: '1fr auto',
  },
  mobileListGrid: {
    display: 'grid',
    gridTemplateColumns: ['5fr', '4fr', '4fr'].join(' '),
    rowGap: 1,
    columnGap: 1,
    gridAutoRows: 'auto',
  },
  rowSpan2: {
    gridRow: 'span 2 / span 2',
  },
  colSpan3: {
    gridColumn: 'span 3 / span 3',
  },
  colSpan5: {
    gridColumn: 'span 5 / span 5',
  },
  flexGrow1: {
    flexGrow: 1,
  },
  spaceX1: {
    '&> * + *': {
      marginLeft: 1,
    },
  },
  spaceY1: {
    '&> * + *': {
      marginTop: 1,
    },
  },
  width8: {
    width: '8rem',
  },
  maxWidth10: {
    maxWidth: '10rem',
  },
  maxWidth20: {
    maxWidth: '20rem',
  },
  border: {
    borderColor: grey[300],
    borderStyle: 'solid',
  },
  borderTop: {
    borderWidth: '1px 0 0 0',
  },
  borderBottom: {
    borderBottomWidth: '1px',
  },
  borderYBetween: {
    '&> * + *': {
      borderWidth: '1px 0 0 0',
    },
  },
  contentBox: {
    padding: 1,
    '&> * + *': {
      marginTop: '4px',
    },
    boxSizing: 'border-box',
    overflowX: 'hidden',
    '&> *': {
      overflowWrap: 'anywhere',
    },
  },
  listItemCard: {
    cursor: 'pointer',
    backgroundColor: theme => theme.palette.background.paper,
    borderRadius: '4px',
    transition: 'box-shadow 200ms ease-out',
    '&:hover': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  listItemCardSmall: {
    cursor: 'pointer',
    maxWidth: '100vw',
    '&:hover': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  chip: {
    display: 'inline-block',
    padding: `${0.25} ${0.5}`,
    borderRadius: `2px`,
    marginLeft: 0.5,
    marginTop: 0.5,
  },
  emptyStateBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme => theme.palette.text.disabled,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 2,
    paddingRight: 2,
  },
  emptyStateIcon: {
    fontSize: '72px',
    paddingBottom: 1,
  },
  errorWrapper: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  actionButton: {
    height: '2rem',
    minWidth: '3.75rem',
    whiteSpace: 'nowrap',
  },
  actionButtonOutlined: {
    paddingLeft: 0.5,
    paddingRight: 0.5,
  },
  actionSquareButton: {
    height: '2rem',
    minWidth: '3rem',
    paddingLeft: 0.5,
    paddingRight: 0.5,
  },
  actionButtonStartIcon: {
    marginRight: 0.5,
  },
  actionSquareButtonStartIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
  buttonListContainer: {
    marginTop: -0.5,
    marginLeft: -0.5,
  },
  buttonListItem: {
    marginTop: 0.5,
    marginLeft: 0.5,
  },
} as const;
